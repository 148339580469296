import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

const ExportButton = ({
  perDayExportOnClick,
  overallExportOnClick,
  loading,
  disablePerDay,
}) => (
  <Dropdown>
    <Dropdown.Toggle variant="info" disabled={loading} data-cy="exportListBtn">
      {loading ? 'Loading...' : 'Export Report'}
    </Dropdown.Toggle>
    <Dropdown.Menu data-cy="exportList">
      <Dropdown.Item onClick={perDayExportOnClick} disabled={disablePerDay}>
        Daily
      </Dropdown.Item>
      <Dropdown.Item onClick={overallExportOnClick}>Overall</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
);

ExportButton.propTypes = {
  disablePerDay: PropTypes.bool,
  perDayExportOnClick: PropTypes.func,
  overallExportOnClick: PropTypes.func,
  loading: PropTypes.bool,
};

ExportButton.defaultProps = {
  disablePerDay: false,
  perDayExportOnClick: null,
  overallExportOnClick: null,
  loading: false,
};

export default ExportButton;
