import styled from 'styled-components';

const CreateButtonContainer = styled.button`
  background-color: #00d1b2;
  border-color: transparent;
  color: #fff;
  border-width: 1px;
  cursor: pointer;
  justify-content: space-around;
  padding-bottom: calc(0.375em - 1px);
  padding-left: 0.75em;
  padding-right: 0.75em;
  padding-top: calc(0.375em - 1px);
  text-align: center;
  white-space: nowrap;
  border-radius: 4px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  width: 100px;

  &:focus {
    outline: none;
  }
`;

export default CreateButtonContainer;
