import React from 'react';
import PropTypes from 'prop-types';
import ReminderText from './ReminderText';
import FlexBox from '../../../../../core/components/FlexBox';

const Reminder = ({ verbiage, testId }) => (
  <FlexBox
    justifyContent="flex-start"
    width="65%"
    margin="20px auto"
    data-cy={testId}
  >
    {verbiage.map((v, i) => (
      <ReminderText key={`reminder${i}`}>{v}</ReminderText>
    ))}
  </FlexBox>
);

Reminder.propTypes = {
  verbiage: PropTypes.arrayOf(PropTypes.string),
  testId: PropTypes.string,
};

Reminder.defaultProps = {
  verbiage: [],
  testId: '',
};

export default Reminder;
