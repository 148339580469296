import React from 'react';
import PropTypes from 'prop-types';
import ContactInputContainer from './ContactInputContainer';
import ContactTextField from './ContactTextField';
import Text from '../Text';
import FlexBox from '../FlexBox';
import Star from '../Star';

const ContactTextInput = ({
  label,
  onChange,
  value,
  disabled,
  compulsory,
  inputTestId,
}) => (
  <ContactInputContainer>
    <FlexBox width="20%" justifyContent="flex-start">
      <Text fontSize="1.25">
        {compulsory ? <Star /> : null}
        {label}
      </Text>
    </FlexBox>
    <ContactTextField
      disabled={disabled}
      type="text"
      onChange={onChange}
      value={value}
      data-cy={inputTestId}
    />
  </ContactInputContainer>
);

ContactTextInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  compulsory: PropTypes.bool,
  inputTestId: PropTypes.string,
};

ContactTextInput.defaultProps = {
  label: '',
  value: '',
  onChange: null,
  disabled: false,
  compulsory: false,
  inputTestId: '',
};

export default ContactTextInput;
