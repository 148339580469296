import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Switch from 'react-switch';
import { Formik } from 'formik';
import * as Yup from 'yup';
import BigNumber from 'bignumber.js';
import _, { isEmpty } from 'lodash';
import AdFormField from './AdFormField';
import AdFormButton from './AdFormButton';
import AdFormLabel from './AdFormLabel';
import AdFormLabelWithSwitch from './AdFormLabelWithSwitch';
import AdFormSelect from './AdFormSelect';
import AdFormDropdown from './AdFormDropdown';
import FormContainer from '../../../../../core/components/Form/FormContainer';
import ErrorMessage from '../../../../../core/components/Form/ErrorMessage';
import SuccessMessage from '../../../../../core/components/Form/SuccessMessage';
import FlexBox from '../../../../../core/components/FlexBox';
import Text from '../../../../../core/components/Text';

const TYPE_OPTIONS = ['App', 'Web Browser'];

const capPeriodToMinute = (impCapPeriod) => {
  if (impCapPeriod === '') {
    return '';
  }
  const numberPart = impCapPeriod.slice(0, -1);
  if (impCapPeriod[impCapPeriod.length - 1] === 'h') {
    return '' + parseInt(numberPart) * 60;
  }
  return numberPart;
};

const isMissingValueAtRequiredField = (formData) => {
  const optionalFields = ['targetOSVersionGroup'];
  const filteredFields = _.omit(formData, optionalFields);
  return Object.entries(filteredFields).some(([fieldName, value]) => {
    if (fieldName === 'targetOSVersions' && isEmpty(value)) {
      if (formData.type === 'Web Browser') {
        return false;
      }
      return true;
    }
    
    if (value === false) {
      return false
    } else {
      return !value;
    }
  });
};

const AdForm = ({
  advertisers,
  error,
  onSubmit,
  countries,
  devices,
  targetGroups,
  osVersionsOptions,
  onCancel,
  success,
  editFormDetails,
  editMode,
  onDelete,
  submitting,
  showAdvertisersDropdown,
}) => {
  const advertiserOptions = advertisers.map(({ name }) => name);
  const INCLUDE_OPTIONS = ['Include', 'Exclude'];
  const TARGET_OS_VERSION_OPTIONS = ['2.5', '3.0'];
  const CERT_STATE_REQUIREMENT_OPTIONS = [true, false];
  const SUPPORTED_FORMATS = ['image/png'];
  const SUPPORTED_HEIGHT = [264, 54, 36];
  const SUPPORTED_WIDTH = [240, 216];
  const [selectedDevices, setSelectedDevices] = useState(
    editFormDetails['comrefs'] ? editFormDetails['comrefs'].map(({ id }) => id) : []
  );
  const [selectedCountries, setSelectedCountries] = useState(
    editFormDetails['countries']
      ? editFormDetails['countries'].map(({ id }) => id)
      : []
  );
  const [selectedTargetGroups, setSelectedTargetGroups] = useState(
    editFormDetails['targetGroups'] ? editFormDetails['targetGroups'] : []
  );
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadError, setUploadError] = useState(false);
  const [image, setImage] = useState(null);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(advertisers[0]);
  const validationSchema1 = Yup.object().shape({
    name: Yup.string()
      .max(50, '*Ad Name must be less than 50 characters')
      .required('*Ad Name is required'),
    creative: Yup.string().required('Creative is required'),
    cpm: Yup.number()
      .min(0.05, '*CPM must be equal or above 0.05')
      .test(
        'cpm-decimal-points',
        'CPM must be up to 2 decimal places',
        (value) =>
          parseFloat(
            BigNumber(value)
              .times(100)
              .toNumber()
          ) ===
          parseInt(
            BigNumber(value)
              .times(100)
              .toNumber()
          )
      )
      .required('*CPM is required'),
    clickURL: Yup.string().required('ClickURL is required!'),
    dailyBudget: Yup.number()
      .min(1, '*Daily Budget must be at least $1')
      .test(
        'daily-budget-decimal-points',
        'Daily Budget must be up to 2 decimal places',
        (value) =>
          parseFloat(
            BigNumber(value)
              .times(100)
              .toNumber()
          ) ===
          parseInt(
            BigNumber(value)
              .times(100)
              .toNumber()
          )
      )
      .required('*Daily Budget is required'),
    countryOption: Yup.string().oneOf(
      INCLUDE_OPTIONS,
      '*Country Option must be Include or Exclude'
    ),
    countries: Yup.array(),
    comrefOption: Yup.string().oneOf(
      INCLUDE_OPTIONS,
      '*Device Option must be Include or Exclude'
    ),
    comrefs: Yup.array(),
    targetGroupOption: Yup.string().oneOf(
      INCLUDE_OPTIONS,
      '*Target Group Option must be Include or Exclude'
    ),
    targetGroups: Yup.array(),
    type: Yup.string().oneOf(TYPE_OPTIONS, '*Ad type must be App or Web Browser'),
    certStateRequirement: Yup.bool().oneOf(CERT_STATE_REQUIREMENT_OPTIONS, '*CertState Requirement must be true or false'),
    impQuota: Yup.number()
      .min(-1, 'Quota must be an integer >= -1')
      .integer('Quota must be an integer')
      .test('non-zero', 'Quota cannot be zero', (num) => num !== 0),
    impCapPeriodMinute: Yup.number()
      .moreThan(0, 'Period must be greater than zero')
      .integer('Period must be an integer'),
  });
  const validationSchema2 = Yup.object().shape({
    name: Yup.string()
      .max(50, '*Ad Name must be less than 50 characters')
      .required('*Ad Name is required'),
    creative: Yup.string().required('Creative is required'),
    cpm: Yup.number()
      .min(0.05, '*CPM must be equal or above 0.05')
      .test(
        'cpm-decimal-points',
        'CPM must be up to 2 decimal places',
        (value) =>
          parseFloat(
            BigNumber(value)
              .times(100)
              .toNumber()
          ) ===
          parseInt(
            BigNumber(value)
              .times(100)
              .toNumber()
          )
      )
      .required('*CPM is required'),
    clickURL: Yup.string().required('ClickURL is required!'),
    dailyBudget: Yup.number()
      .min(1, '*Daily Budget must be at least $1')
      .test(
        'daily-budget-decimal-points',
        'Daily Budget must be up to 2 decimal places',
        (value) =>
          parseFloat(
            BigNumber(value)
              .times(100)
              .toNumber()
          ) ===
          parseInt(
            BigNumber(value)
              .times(100)
              .toNumber()
          )
      )
      .required('*Daily Budget is required'),
    countryOption: Yup.string().oneOf(
      INCLUDE_OPTIONS,
      '*Country Option must be Include or Exclude'
    ),
    countries: Yup.array(),
    comrefOption: Yup.string().oneOf(
      INCLUDE_OPTIONS,
      '*Device Option must be Include or Exclude'
    ),
    comrefs: Yup.array(),
    targetGroupOption: Yup.string().oneOf(
      INCLUDE_OPTIONS,
      '*Target Group Option must be Include or Exclude'
    ),
    targetGroups: Yup.array(),
    targetOSVersionGroup: Yup.string().oneOf(
      TARGET_OS_VERSION_OPTIONS,
      '*Target OS Version Option must be 2.5 or 3.0'
    ),
    targetOSVersions: Yup.array().when(['type'], {
      is: (type) => {
        return type === 'App';
      },
      then: Yup.array().required(
        'Target OS Versions are required. Please select at least one Target OS Version'
      ),
    }),
    type: Yup.string().oneOf(TYPE_OPTIONS, '*Ad type must be App or Web Browser'),
    certStateRequirement: Yup.bool().oneOf(CERT_STATE_REQUIREMENT_OPTIONS, '*CertState Requirement must be true or false'),
    impQuota: Yup.number()
      .min(-1, 'Quota must be an integer >= -1')
      .integer('Quota must be an integer')
      .test('non-zero', 'Quota cannot be zero', (num) => num !== 0),
    impCapPeriodMinute: Yup.number()
      .moreThan(0, 'Period must be greater than zero')
      .integer('Period must be an integer'),
  });

  return (
    <Formik
      initialValues={{
        name: editFormDetails['name'] || '',
        impQuota: editFormDetails['impQuota'] || '',
        impCapPeriodMinute: capPeriodToMinute(editFormDetails['impCapPeriod'] || ''),
        creative: editMode ? editFormDetails['name'] : '',
        cpm: parseFloat(editFormDetails['cpm']) || 0.05,
        clickURL: editFormDetails['clickURL'] || '',
        dailyBudget: editFormDetails['dailySpend'] || 1,
        countryOption: 'Include',
        countries: editFormDetails['countries'] || [],
        comrefOption: 'Include',
        comrefs: editFormDetails['comrefs'] || [],
        targetGroupOption: 'Include',
        targetGroups: editFormDetails['targetGroups'] || [],
        targetOSVersionGroup: editFormDetails['targetOSVersionGroup'],
        targetOSVersions: editFormDetails['targetOSVersions'] || [],
        type: editFormDetails['target'] || 'App',
        certStateRequirement: editFormDetails['certStateRequirement'] || false,
        width: editFormDetails['width'] || 0,
        height: editFormDetails['height'] || 0,
      }}
      validationSchema={
        editFormDetails['target'] === 'App' ? validationSchema2 : validationSchema1
      }
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        if (showAdvertisersDropdown) {
          onSubmit(selectedAdvertiser['id'], values, uploadedFile);
        } else {
          onSubmit(editFormDetails.userID, values, uploadedFile);
        }

        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        handleReset,
        setSubmitting,
      }) => {
        return (
          <FormContainer width="100%">
            <Form onSubmit={handleSubmit}>
              {showAdvertisersDropdown ? (
                <Form.Group controlId="formName">
                  <AdFormLabel>Advertiser</AdFormLabel>
                  <AdFormDropdown
                    options={advertiserOptions}
                    dropdownID="ad_form_advertisers_dropdown"
                    title={selectedAdvertiser['name']}
                    onSelect={(i) => setSelectedAdvertiser(advertisers[i])}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              ) : null}
              <Form.Group controlId="formName">
                <AdFormLabel>Name</AdFormLabel>
                <AdFormField
                  type="text"
                  name="name"
                  placeholder="Enter Ad Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  isInvalid={touched.name && errors.name}
                  data-cy="adName"
                />
                <Form.Control.Feedback type="invalid" data-cy="adName-error">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              {(image || editFormDetails.content) && (
                <Form.Group>
                  <AdFormLabel>
                    {editMode ? 'Existing Creative' : 'Uploaded Creative'}
                  </AdFormLabel>
                  <img
                    src={image || editFormDetails.content}
                    alt={values.name}
                    data-cy="exCreative"
                  />
                </Form.Group>
              )}
              <Form.Group controlId="formFile">
                <AdFormLabel>Creative</AdFormLabel>
                <Form.File
                  id="creative"
                  name="creative"
                  label={values.creative || 'Creative Files Upload'}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      const img = new Image();
                      img.src = window.URL.createObjectURL(file);
                      img.onload = () => {
                        const h = img.naturalHeight;
                        const w = img.naturalWidth;
                        if (
                          SUPPORTED_HEIGHT.includes(h) &&
                          SUPPORTED_WIDTH.includes(w) &&
                          file.name
                        ) {
                          setFieldValue('width', w);
                          setFieldValue('height', h);
                          setFieldValue('creative', file.name);
                          setUploadedFile(file);
                          setImage(img.src);
                          setUploadError(false);
                        } else {
                          setUploadError(true);
                        }
                      };
                    }
                  }}
                  onBlur={handleBlur}
                  custom
                  accept={SUPPORTED_FORMATS.join(' ,')}
                  isInvalid={touched.creative && errors.creative}
                  data-cy="creativeUpload"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.creative}
                </Form.Control.Feedback>
                {uploadError ? (
                  <Text fontColor="red" textAlign="left">
                    Creative size not allowed. Please upload image of the following
                    sizes: 216 x 36, 216 x 54, 240 x 264
                  </Text>
                ) : null}
              </Form.Group>
              <Form.Group controlId="formCPM">
                <AdFormLabel>CPM</AdFormLabel>
                <AdFormField
                  type="number"
                  name="cpm"
                  placeholder="Enter CPM"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cpm}
                  isInvalid={touched.cpm && errors.cpm}
                  data-cy="CPM"
                />
                {editMode ? <p>*Change will be effective on the next day</p> : null}
                <Form.Control.Feedback type="invalid">
                  {errors.cpm}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formClickURL">
                <AdFormLabel>Click URL</AdFormLabel>
                <AdFormField
                  type="text"
                  name="clickURL"
                  placeholder="Enter Click URL"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.clickURL}
                  isInvalid={touched.clickURL && errors.clickURL}
                  data-cy="clickURL"
                />
                <Form.Control.Feedback type="invalid" data-cy="clickURL-error">
                  {errors.clickURL}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formDailyBudget">
                <AdFormLabel>Daily Budget</AdFormLabel>
                <AdFormField
                  type="number"
                  name="dailyBudget"
                  placeholder="Enter Daily Budget"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dailyBudget}
                  isInvalid={touched.dailyBudget && errors.dailyBudget}
                  data-cy="dBudget"
                />
                {editMode ? <p>*Change will be effective on the next day</p> : null}
                <Form.Control.Feedback type="invalid" data-cy="dBudget-error">
                  {errors.dailyBudget}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formCertStateRequirement">
                <FlexBox>
                  <AdFormLabelWithSwitch>Require Every Device's Root Certificate Issue To Be Fixed Already? &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {values.certStateRequirement != null ? (
                    <Switch
                      checked={values.certStateRequirement === true}
                      onChange={() => setFieldValue('certStateRequirement', !(values.certStateRequirement))}
                      name="certStateRequirement"
                      onColor="#00d1b2"
                      onHandleColor="#00d1b2"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      className="react-switch"
                      id="material-switch"
                      data-cy="certStateRequirement"
                    />) : null}
                  </AdFormLabelWithSwitch>
                  </FlexBox>
                <Form.Control.Feedback type="invalid" data-cy="certStateRequirement-error">
                  {errors.certStateRequirement}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formCountryOption">
                <AdFormLabel>Country</AdFormLabel>
                {INCLUDE_OPTIONS.map((o) => (
                  <AdFormButton
                    key={`country${o}`}
                    mode={values.countryOption === o ? 'active' : ''}
                    onClick={() => setFieldValue('countryOption', o)}
                    data-cy={`country${o.slice(0, 2)}Btn`}
                  >
                    {o}
                  </AdFormButton>
                ))}
                <FlexBox justifyContent="flex-start">
                  {values.countries.map(({ id, country }) => (
                    <AdFormButton
                      key={`d${country}`}
                      onClick={() => {
                        setSelectedCountries(
                          selectedCountries.filter((s) => s !== id)
                        );
                        setFieldValue(
                          'countries',
                          values.countries.filter((d) => d.id !== id)
                        );
                      }}
                    >
                      {`${country}  `} &#10006;
                    </AdFormButton>
                  ))}
                </FlexBox>
                <AdFormSelect multiple size="5">
                  {countries
                    .filter(({ id }) => selectedCountries.indexOf(id) < 0)
                    .map(({ country, id }) => (
                      <option
                        value={id}
                        key={country}
                        onClick={() => {
                          setFieldValue('countries', [
                            ...values.countries,
                            { id, country },
                          ]);
                          setSelectedCountries([...selectedCountries, id]);
                        }}
                      >
                        {country}
                      </option>
                    ))}
                </AdFormSelect>
              </Form.Group>
              <Form.Group controlId="formDeviceOption">
                <AdFormLabel>Device</AdFormLabel>
                {INCLUDE_OPTIONS.map((o) => (
                  <AdFormButton
                    key={`device${o}`}
                    mode={values.comrefOption === o ? 'active' : ''}
                    onClick={() => setFieldValue('comrefOption', o)}
                    data-cy={`dev${o.slice(0, 2)}Btn`}
                  >
                    {o}
                  </AdFormButton>
                ))}
                <FlexBox justifyContent="flex-start">
                  {values.comrefs.map(({ id, name }) => (
                    <AdFormButton
                      key={`d${name}`}
                      onClick={() => {
                        setSelectedDevices(selectedDevices.filter((s) => s !== id));
                        setFieldValue(
                          'comrefs',
                          values.comrefs.filter((d) => d.id !== id)
                        );
                      }}
                    >
                      {`${name}  `} &#10006;
                    </AdFormButton>
                  ))}
                </FlexBox>
                <AdFormSelect multiple size="5">
                  {devices
                    .filter(({ id }) => selectedDevices.indexOf(id) < 0)
                    .map(({ id, name }) => (
                      <option
                        value={id}
                        key={name}
                        onClick={() => {
                          setFieldValue('comrefs', [
                            ...values.comrefs,
                            { id, name },
                          ]);
                          setSelectedDevices([...selectedDevices, id]);
                        }}
                      >
                        {name}
                      </option>
                    ))}
                </AdFormSelect>
              </Form.Group>
              <Form.Group controlId="formTargetGroupOption">
                <AdFormLabel>Target Group</AdFormLabel>
                <FlexBox justifyContent="flex-start">
                  {values.targetGroups.map((name) => (
                    <AdFormButton
                      key={`d${name}`}
                      onClick={() => {
                        setSelectedTargetGroups(
                          selectedTargetGroups.filter((s) => s !== name)
                        );
                        setFieldValue(
                          'targetGroups',
                          values.targetGroups.filter((s) => s !== name)
                        );
                      }}
                    >
                      {`${name}  `} &#10006;
                    </AdFormButton>
                  ))}
                </FlexBox>
                <AdFormSelect multiple size="5">
                  {targetGroups
                    .filter((name) => selectedTargetGroups.indexOf(name) < 0)
                    .map((name) => (
                      <option
                        value={name}
                        key={name}
                        onClick={() => {
                          setFieldValue('targetGroups', [
                            ...values.targetGroups,
                            name,
                          ]);
                          setSelectedTargetGroups([...selectedTargetGroups, name]);
                        }}
                      >
                        {name}
                      </option>
                    ))}
                </AdFormSelect>
              </Form.Group>
              <Form.Group controlId="formImpQuota">
                <AdFormLabel>
                  Per User Impression Cap Quota (-1 to disable)
                </AdFormLabel>
                <AdFormField
                  type="number"
                  name="impQuota"
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.impQuota}
                  isInvalid={touched.impQuota && errors.impQuota}
                  data-cy="impQuota"
                />
                {editMode ? <p>*Change will be effective on the next hour</p> : null}
                <Form.Control.Feedback type="invalid" data-cy="impQuota-error">
                  {errors.impQuota}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formCapPeriodMinute">
                <AdFormLabel>Per User Impression Cap Period (in minute)</AdFormLabel>
                <AdFormField
                  type="number"
                  name="impCapPeriodMinute"
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.impCapPeriodMinute}
                  isInvalid={touched.impCapPeriodMinute && errors.impCapPeriodMinute}
                  data-cy="impCapPeriodMinute"
                />
                {editMode ? <p>*Change will be effective on the next hour</p> : null}
                <Form.Control.Feedback
                  type="invalid"
                  data-cy="impCapPeriodMinute-error"
                >
                  {errors.impCapPeriodMinute}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formDeviceOption">
                {TYPE_OPTIONS.map((o) => (
                  <AdFormButton
                    key={`type${o}`}
                    mode={values.type === o ? 'active' : ''}
                    onClick={() => {
                      if (o === 'Web Browser' && !editMode) {
                        setFieldValue('targetOSVersions', []);
                      }

                      setFieldValue('type', o);
                    }}
                    data-cy={`${o.toLowerCase().split(' ')[0]}Btn`}
                  >
                    {o}
                  </AdFormButton>
                ))}
              </Form.Group>
              {values.type === 'App' && (
                <Form.Group controlId="formTargetOSVersionOption">
                  <AdFormLabel>Target OS Versions</AdFormLabel>
                  {TARGET_OS_VERSION_OPTIONS.map((o) => (
                    <AdFormButton
                      key={`targetOSVersion${o}`}
                      mode={values.targetOSVersionGroup === o ? 'active' : ''}
                      onClick={() => {
                        setFieldValue(
                          'targetOSVersions',
                          values.targetOSVersions.filter(
                            (s) => s.osVersionGroup === o
                          )
                        );
                        setFieldValue('targetOSVersionGroup', o);
                      }}
                      data-cy={`osVersion${o.slice(0, 2)}Btn`}
                      disabled={
                        editMode === true && editFormDetails['target'] === 'App' ? o !== values.targetOSVersionGroup : false
                      }
                    >
                      {o}
                    </AdFormButton>
                  ))}
                  <div>
                    {
                      <AdFormButton
                        onClick={() => {
                          values.targetOSVersionGroup === '2.5' &&
                            setFieldValue(
                              'targetOSVersions',
                              osVersionsOptions.groupByOS['2.5']
                            );
                          values.targetOSVersionGroup === '3.0' &&
                            setFieldValue(
                              'targetOSVersions',
                              osVersionsOptions.groupByOS['3.0']
                            );
                        }}
                      >
                        Select All
                      </AdFormButton>
                    }
                    <AdFormButton
                      onClick={() => {
                        setFieldValue('targetOSVersions', []);
                      }}
                    >
                      Clear All
                    </AdFormButton>
                  </div>

                  <FlexBox justifyContent="flex-start">
                    {values.targetOSVersions.map((id) => {
                      const { osVersion } = osVersionsOptions.options[id];

                      return (
                        <AdFormButton
                          key={`d${osVersion}`}
                          onClick={() => {
                            setFieldValue(
                              'targetOSVersions',
                              values.targetOSVersions.filter(
                                (selectedId) => selectedId !== id
                              )
                            );
                          }}
                        >
                          {`${osVersion} `} &#10006;
                        </AdFormButton>
                      );
                    })}
                  </FlexBox>
                  <AdFormSelect multiple size="5">
                    {osVersionsOptions.groupByOS &&
                    osVersionsOptions.groupByOS[values.targetOSVersionGroup]
                      ? osVersionsOptions.groupByOS[values.targetOSVersionGroup]
                          .filter((id) => {
                            return values.targetOSVersions.indexOf(id) < 0;
                          })
                          .map((id) => {
                            const { osVersion } = osVersionsOptions.options[id];
                            return (
                              <option
                                value={id}
                                key={osVersion}
                                onClick={() => {
                                  const newTargetOSVersions = [
                                    ...values.targetOSVersions,
                                    id,
                                  ].sort((a, b) => Number(a) - Number(b));

                                  setFieldValue(
                                    'targetOSVersions',
                                    newTargetOSVersions
                                  );
                                }}
                              >
                                {osVersion}
                              </option>
                            );
                          })
                      : []}
                  </AdFormSelect>
                </Form.Group>
              )}

              <FlexBox>
                <AdFormButton
                  mode={
                    isSubmitting ||
                    isMissingValueAtRequiredField(values) ||
                    !_.isEmpty(errors) ||
                    submitting
                      ? null
                      : 'active'
                  }
                  type="submit"
                  disabled={
                    isSubmitting ||
                    isMissingValueAtRequiredField(values) ||
                    !_.isEmpty(errors) ||
                    submitting
                  }
                  onClick={handleSubmit}
                  data-cy="submitBtn"
                >
                  {editMode ? 'Save' : 'Submit'}
                </AdFormButton>
                <AdFormButton
                  disabled={isSubmitting || submitting}
                  onClick={() => {
                    setSubmitting();
                    handleReset();
                    onCancel();
                  }}
                  data-cy="cancelBtn"
                >
                  Cancel
                </AdFormButton>
                {editMode ? (
                  <AdFormButton
                    mode="danger"
                    disabled={isSubmitting || submitting}
                    onClick={() => {
                      setSubmitting();
                      onDelete(editFormDetails.userID, editFormDetails.id);
                    }}
                    data-cy="delBtn"
                  >
                    Delete
                  </AdFormButton>
                ) : null}
              </FlexBox>
              {error && <ErrorMessage>{error}</ErrorMessage>}
              {success && (
                <SuccessMessage margin="5px auto" fontColor="red">
                  {success}
                </SuccessMessage>
              )}
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

AdForm.propTypes = {
  advertisers: PropTypes.array,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  success: PropTypes.string,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      country: PropTypes.string,
      region: PropTypes.string,
      subregion: PropTypes.string,
      displayname: PropTypes.string,
      code: PropTypes.string,
    })
  ),
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      brand: PropTypes.string,
    })
  ),
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  editMode: PropTypes.bool,
  editFormDetails: PropTypes.shape(),
  submitting: PropTypes.bool,
  showAdvertisersDropdown: PropTypes.bool,
  osVersionsOptions: PropTypes.shape({
    options: PropTypes.object,
    groupByOS: PropTypes.shape({
      '2.5': PropTypes.array,
      '3.0': PropTypes.array,
    }),
  }),
  targetGroups: PropTypes.string,
};

AdForm.defaultProps = {
  advertisers: [],
  onSubmit: null,
  error: '',
  success: '',
  countries: [],
  devices: [],
  editMode: false,
  onCancel: null,
  onDelete: null,
  editFormDetails: {},
  submitting: false,
  showAdvertisersDropdown: false,
};

export default AdForm;
