import styled from 'styled-components';
import Text from '../../../../../core/components/Text';

const SummaryTitle = styled(Text)`
  color: #4a4a4a;
  font-size: 11px;
  letter-spacing: 1px;
  margin-bottom: 5px;
  text-transform: uppercase;
`;

export default SummaryTitle;
