import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import AdsDashboardPage from './adsDashboard/AdsDashboardPage';
import AdsCampaignPage from './adsCampaign/AdsCampaignPage';
import SponsoredSearchPage from './adsCampaign/SponsoredSearchPage';
import TopUpPage from './topUp/TopUpPage';
import AdFormPage from './adForm/AdFormPage';
import SponsoredSearchFormPage from './adForm/SponsoredSearchFormPage';
import AccountManagementPage from './accountManagement/AccountManagementPage';
import PageContainer from '../../core/components/PageContainer';
import Spinner from '../../core/components/Spinner';
import { getAdvertisers } from '../../redux/advertisers/advertiserSlice';

export const AdminPage = ({
  getAdvertisersAction,
  match,
  adminToken,
  advertisers,
}) => {
  useEffect(() => {
    getAdvertisersAction(adminToken);
    return () => false;
  }, [getAdvertisersAction, adminToken]);

  return (
    <PageContainer>
      {advertisers.length ? (
        <Switch>
          <Route
            exact
            path={`${match.url}/ads_campaigns`}
            component={AdsCampaignPage}
          />
          <Route
            exact
            path={`${match.url}/sponsored_search`}
            component={SponsoredSearchPage}
          />
          <Route
            exact
            path={`${match.url}/ads_dashboard`}
            component={AdsDashboardPage}
          />
          <Route exact path={`${match.url}/top_up`} component={TopUpPage} />
          <Route exact path={`${match.url}/ad_create`} component={AdFormPage} />
          <Route exact path={`${match.url}/ad_edit`} component={AdFormPage} />
          <Route exact path={`${match.url}/sponsored_create`} component={SponsoredSearchFormPage} />
          <Route exact path={`${match.url}/sponsored_edit`} component={SponsoredSearchFormPage} />
          <Route
            exact
            path={`${match.url}/advertisers_account_management`}
            component={AccountManagementPage}
          />
          <Redirect from="*" to={`${match.url}/ads_campaigns`} />
        </Switch>
      ) : (
        <Spinner />
      )}
    </PageContainer>
  );
};

AdminPage.propTypes = {
  getAdvertisersAction: PropTypes.func,
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    params: PropTypes.shape({}),
    isExact: PropTypes.bool,
  }),
  adminToken: PropTypes.string,
  advertisers: PropTypes.array,
};

AdminPage.defaultProps = {
  getAdvertisersAction: null,
  match: {
    path: '/',
    url: '/',
    params: {},
    isExact: false,
  },
  adminToken: '',
  advertisers: [],
};

const mapStateToProps = (state) => ({
  adminToken: state.auth.token,
  advertisers: state.advertiser.advertisers,
});

const mapDispatchToProps = (dispatch) => ({
  getAdvertisersAction: (token) => dispatch(getAdvertisers(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
