import React from 'react';
import PropTypes from 'prop-types';
import ContactInputContainer from './ContactInputContainer';
import Dropdown from '../Dropdown/Dropdown';
import Text from '../Text';
import FlexBox from '../FlexBox';
import Star from '../Star';

const ContactTitleDropDown = ({
  options,
  title,
  dropdownID,
  onSelect,
  disabled,
  dropdownTestId,
}) => (
  <ContactInputContainer>
    <FlexBox width="20%" justifyContent="flex-start">
      <Text fontSize="1.25">
        <Star />
        Title
      </Text>
    </FlexBox>
    <Dropdown
      options={options}
      title={title}
      dropdownID={dropdownID}
      onSelect={onSelect}
      disabled={disabled}
      buttonTestId={dropdownTestId}
    />
  </ContactInputContainer>
);

ContactTitleDropDown.propTypes = {
  dropdownID: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  dropdownTestId: PropTypes.string,
};

ContactTitleDropDown.defaultProps = {
  dropdownID: '',
  options: [],
  title: '',
  onSelect: null,
  disabled: null,
  dropdownTestId: '',
};

export default ContactTitleDropDown;
