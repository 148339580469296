import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Formik,useField, useFormikContext } from 'formik';
import * as Yup from 'yup';
import DatePicker from "react-datepicker";
import BigNumber from 'bignumber.js';
import _ from 'lodash';
import AdFormField from './AdFormField';
import AdFormButton from './AdFormButton';
import AdFormLabel from './AdFormLabel';
import AdFormSelect from './AdFormSelect';
import AdFormDropdown from './AdFormDropdown';
import FormContainer from '../../../../../core/components/Form/FormContainer';
import ErrorMessage from '../../../../../core/components/Form/ErrorMessage';
import SuccessMessage from '../../../../../core/components/Form/SuccessMessage';
import FlexBox from '../../../../../core/components/FlexBox';
import moment from 'moment';

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && moment(field.value, "MM-DD-YYYY").toDate()) || null}  
      onChange={val => {
        setFieldValue(field.name, val);
      }}
    />
  );
};
const SponsoredForm = ({
  advertisers,
  error,
  onSubmit,
  countries,
  devices,
  osVersions,
  onCancel,
  success,
  editFormDetails,
  editMode,
  onDelete,
  submitting,
  showAdvertisersDropdown,
}) => {
  const advertiserOptions = advertisers.map(({ name }) => name);
  const INCLUDE_OPTIONS = ['Include', 'Exclude'];
  const SPONSORED_TYPES = ['sponsored', 'YMAL'];
  const TARGET_OS_VERSION_OPTIONS = ['2.5', '3.0'];
  // const osVersionsFor2Dot5 = osVersions.filter(o => o.osVersionGroup='2.5'); 
  const osVersionsFor2Dot5 = osVersions.slice(0,14);
  const osVersionsFor3Dot0 = osVersions.slice(14);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(advertisers[0]);
  const [selectedOSVersions, setSelectedOSVersions] = useState(
    editFormDetails['targetOSVersions'] ? editFormDetails['targetOSVersions'].map(({id}) => id) : [],
  )
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(50, '*Ad Name must be less than 50 characters')
      .required('*Ad Name is required'),
    advertiserID: Yup.number(),
    cpi: Yup.number()
      .min(0.01, '*CPI must be equal or above 0.01')
      .test(
        'cpi-decimal-points',
        'CPI must be up to 2 decimal places',
        (value) => parseFloat(BigNumber(value).times(100).toNumber()) === parseInt(BigNumber(value).times(100).toNumber()),
      )
      .required('*CPI is required'),
    bundleID: Yup.string().required('Bundle ID is required!').matches(/^[a-z]+\.[a-z]/, "Must be in correct form"),
    type: Yup.string().required('Sponsored Ad type is required!'),
    dailyBudget: Yup.number()
      .min(1, '*Daily Budget must be at least $1')
      .test(
        'daily-budget-decimal-points',
        'Daily Budget must be up to 2 decimal places',
        (value) => parseFloat(BigNumber(value).times(100).toNumber()) === parseInt(BigNumber(value).times(100).toNumber()),
      )
      .required('*Daily Budget is required'),
    countryAction: Yup.bool().required('*Country Option must be Include or Exclude'),
    countries: Yup.array(),
    deviceAction: Yup.bool().required('*Device Option must be Include or Exclude'),
    devices: Yup.array(),
    impQuota: Yup.number()
      .min(-1, "Quota must be an integer >= -1")
      .integer("Quota must be an integer")
      .test('non-zero', "Quota cannot be zero", (num) => num !== 0),
    impCapPeriodMinute: Yup.number()
      .moreThan(0, "Period must be greater than zero")
      .integer("Period must be an integer"),
    // give one hour buffer when user start loading this page, otherwise the Submit button will be disable if the user re-choose the startDate to be today
    startDate: Yup.date().required('Start Date is required!').min(new Date(Date.now() - 3600000), "Start Date cannot be earlier than today"),
    // the endDate is at least next day of the startDate
    endDate: Yup.date().required('End Date is required!').when(
      "startDate",
      (eventStartDate, schema) => eventStartDate && schema.min(new Date(Date.parse(eventStartDate) + 86400000))),
  });
  const validationSchema2 = Yup.object().shape({
    name: Yup.string()
      .max(50, '*Ad Name must be less than 50 characters')
      .required('*Ad Name is required'),
    advertiserID: Yup.number(),
    cpi: Yup.number()
      .min(0.01, '*CPI must be equal or above 0.01')
      .test(
        'cpi-decimal-points',
        'CPI must be up to 2 decimal places',
        (value) => parseFloat(BigNumber(value).times(100).toNumber()) === parseInt(BigNumber(value).times(100).toNumber()),
      )
      .required('*CPI is required'),
    bundleID: Yup.string().required('Bundle ID is required!').matches(/^[a-z]+\.[a-z]/, "Must be in correct form"),
    type: Yup.string().required('Sponsored Ad type is required!'),
    dailyBudget: Yup.number()
      .min(1, '*Daily Budget must be at least $1')
      .test(
        'daily-budget-decimal-points',
        'Daily Budget must be up to 2 decimal places',
        (value) => parseFloat(BigNumber(value).times(100).toNumber()) === parseInt(BigNumber(value).times(100).toNumber()),
      )
      .required('*Daily Budget is required'),
    countryAction: Yup.bool().required('*Country Option must be Include or Exclude'),
    countries: Yup.array(),
    deviceAction: Yup.bool().required('*Device Option must be Include or Exclude'),
    devices: Yup.array(),
    targetOSVersionOption: Yup.string().oneOf(
      TARGET_OS_VERSION_OPTIONS,
      '*Target OS Version Option must be 2.5 or 3.0'
    ),
    targetOSVersions: Yup.array().required('Target OS Versions are required. Please select at least one Target OS Version'),
    impQuota: Yup.number()
      .min(-1, "Quota must be an integer >= -1")
      .integer("Quota must be an integer")
      .test('non-zero', "Quota cannot be zero", (num) => num !== 0),
    impCapPeriodMinute: Yup.number()
      .moreThan(0, "Period must be greater than zero")
      .integer("Period must be an integer"),
    startDate: Yup.date().required('Start Date is required!'),
    // the endDate is at least next day of the startDate
    endDate: Yup.date().required('End Date is required!').when(
      "startDate",
      (eventStartDate, schema) => eventStartDate && schema.min(new Date(Date.parse(eventStartDate) + 86400000))),
  });
  return (
    <Formik
      initialValues={{
        name: editFormDetails['name'] || '',
        advertiserID: editFormDetails['advertiserID'] || (advertisers.length?advertisers[0].id:null),
        cpi: parseFloat(editFormDetails['cpi']) || 0.01,
        bundleID: editFormDetails['bundleID'] || '',
        dailyBudget: parseFloat(editFormDetails['dailySpend']) || 1,
        countryAction: editFormDetails['countryAction'] || (editFormDetails['countryAction'] === undefined && true),
        countries: editFormDetails['countries'] || [],
        deviceAction: editFormDetails['deviceAction'] || (editFormDetails['deviceAction'] === undefined && true),
        devices: editFormDetails['devices'] || [],
        targetOSVersionOption: editFormDetails['targetOSVersionGroup'],
        targetOSVersions: editFormDetails['targetOSVersions'] || [],
        type: editFormDetails['type'],
        startDate: editFormDetails['startDate'] || new Date(),
        endDate: editFormDetails['endDate'] || moment().add(1, 'days').toDate(),
      }}
      validationSchema={
        editMode && Date.parse(editFormDetails['startDate']) < Date.parse(new Date()) ? validationSchema2 : validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSubmit(values);
        setSubmitting(false);
      }}
    > 
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        handleReset,
        setSubmitting,
      }) => (
        <FormContainer width="100%">
          <Form onSubmit={handleSubmit}>
            {showAdvertisersDropdown ? (
              <Form.Group controlId="formName">
                <AdFormLabel>Advertiser</AdFormLabel>
                <AdFormDropdown
                  options={advertiserOptions}
                  dropdownID="ad_form_advertisers_dropdown"
                  title={selectedAdvertiser['name']}
                  onSelect={(i) => {
                    setSelectedAdvertiser(advertisers[i]);
                    setFieldValue('advertiserID', advertisers[i].id);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
            ) : null}
            <Form.Group controlId="formName">
              <AdFormLabel>Name</AdFormLabel>
              <AdFormField
                type="text"
                name="name"
                placeholder="Enter Ad Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                isInvalid={touched.name && errors.name}
                data-cy="adName"
              />
              <Form.Control.Feedback type="invalid" datat-cy="adName-error">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBundleID">
              <AdFormLabel>Bundle ID</AdFormLabel>
              <AdFormField
                type="text"
                name="bundleID"
                placeholder="Enter Bundle ID"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bundleID}
                isInvalid={touched.bundleID && errors.bundleID}
                data-cy="bundleID"
              />
              <Form.Control.Feedback type="invalid">
                {errors.bundleID}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formTargetOSVersionOption">
              <AdFormLabel>Target OS Versions</AdFormLabel>
              {TARGET_OS_VERSION_OPTIONS.map((o) => (
                editMode === true ? 
                <AdFormButton
                  key={`targetOSVersion${o}`}
                  mode={values.targetOSVersionOption=== o ? 'active' : ''}
                  onClick={() => {
                    values.targetOSVersions = values.targetOSVersions.filter(tov => tov.osVersionGroup === o);
                    setSelectedOSVersions(
                      selectedOSVersions.filter(s => s.osVersionGroup === o)
                    );
                    setFieldValue('targetOSVersionOption', o)}}
                  data-cy={`osVersion${o.slice(0,2)}Btn`}
                  disabled
                  >
                    {o}
                  </AdFormButton>
                  :
                  <AdFormButton
                  key={`targetOSVersion${o}`}
                  mode={values.targetOSVersionOption=== o ? 'active' : ''}
                  onClick={() => {
                    values.targetOSVersions = values.targetOSVersions.filter(tov => tov.osVersionGroup === o);
                    setSelectedOSVersions(
                      selectedOSVersions.filter(s => s.osVersionGroup === o)
                    );
                    setFieldValue('targetOSVersionOption', o)}}
                  data-cy={`osVersion${o.slice(0,2)}Btn`}
                  >
                    {o}
                  </AdFormButton>
              ))}
              <div>
                {
                  <AdFormButton
                  onClick={
                    () => {
                    (values.targetOSVersionOption === '2.5' && setSelectedOSVersions(osVersionsFor2Dot5.map(({id}) => id)));
                    (values.targetOSVersionOption === '2.5' && setFieldValue('targetOSVersions', osVersionsFor2Dot5));
                    (values.targetOSVersionOption === '3.0' && setSelectedOSVersions(osVersionsFor3Dot0.map(({id}) => id)));
                    (values.targetOSVersionOption === '3.0' && setFieldValue('targetOSVersions', osVersionsFor3Dot0));
                  }}
                >
                  Select All
                </AdFormButton>
                }
                <AdFormButton
                  onClick={() => {
                    setSelectedOSVersions([]);
                    setFieldValue('targetOSVersions', []);
                  }}>
                  Clear All
                </AdFormButton>
              </div>
              
              <FlexBox justifyContent="flex-start">
                {values.targetOSVersions.map(({id, osVersion}) => (
                  <AdFormButton
                    key={`d${osVersion}`}
                    onClick={() => {
                      setSelectedOSVersions(
                        selectedOSVersions.filter((s) => s !== id),
                      );
                      setFieldValue(
                        'targetOSVersions',
                        values.targetOSVersions.filter((d) => d.id !== id),
                      );
                    }}
                    >
                      {`${osVersion} `} &#10006;
                    </AdFormButton>
                ))}
              </FlexBox>
              <AdFormSelect multiple size="5">
                {(values.targetOSVersionOption === '2.5' && 
                osVersionsFor2Dot5.filter(({id}) => selectedOSVersions.indexOf(id) < 0)
                .map(({osVersion, id}) => (
                  <option
                    value={id}
                    key={osVersion}
                    onClick={() => {
                      setFieldValue('targetOSVersions', [
                        ...values.targetOSVersions,
                        {id, osVersion},
                      ]);
                      setSelectedOSVersions([...selectedOSVersions, id]);
                    }}>
                      {osVersion}
                    </option>
                )))
                ||
                (values.targetOSVersionOption === '3.0' && 
                osVersionsFor3Dot0.filter(({id}) => selectedOSVersions.indexOf(id) < 0)
                .map(({osVersion, id}) => (
                  <option
                    value={id}
                    key={osVersion}
                    onClick={() => {
                      setFieldValue('targetOSVersions', [
                        ...values.targetOSVersions,
                        {id, osVersion},
                      ]);
                      setSelectedOSVersions([...selectedOSVersions, id]);
                    }}>
                      {osVersion}
                    </option>
                )))
                }
              </AdFormSelect>
            </Form.Group>
            <Form.Group controlId="formType">
              <AdFormLabel>Type of Sponsored Ad</AdFormLabel>
              {SPONSORED_TYPES.map((o, i) => {
              return (
                <AdFormButton
                  key={`type${o}`}
                  mode={values.type === o ? 'active' : ''}
                  onClick={() => {
                    setFieldValue('type', o);
                }}
                  data-cy={`type${o.slice(0, 2)}Btn`}
                >
                  {o === "sponsored"? "Sponsored Search": "Sponsored YMAL: (You May Also Like)"}
                </AdFormButton>
              )
              })}
              <Form.Control.Feedback type="invalid">
                  {errors.type}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formCPI">
              <AdFormLabel>CPI</AdFormLabel>
              <AdFormField
                type="number"
                name="cpi"
                placeholder="Enter CPI bid"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.cpi}
                isInvalid={touched.cpi && errors.cpi}
                data-cy="CPI"
              />
              {/* {editMode ? <p>*Change will be effective on the next day</p> : null} */}
              <Form.Control.Feedback type="invalid">
                {errors.cpi}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formStartDate">
              <AdFormLabel>Start Date</AdFormLabel>
              {(editMode && Date.parse(editFormDetails['startDate']) > Date.parse(new Date()) &&
                <DatePickerField 
                  wrapperClassName="datePicker"
                  type="date"
                  name="startDate"
                  placeholder="Enter Start Date"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.startDate}
                  isInvalid={touched.startDate && errors.startDate}
                  data-cy="startDate"/>)
                ||
                (editMode && Date.parse(editFormDetails['startDate']) < Date.parse(new Date()) &&
                  <DatePickerField 
                    wrapperClassName="datePicker"
                    type="date"
                    name="startDate"
                    placeholder="Enter Start Date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.startDate}
                    isInvalid={touched.startDate && errors.startDate}
                    data-cy="startDate"
                    disabled/>)
                ||
                (!editMode &&
                  <DatePickerField 
                    wrapperClassName="datePicker"
                    type="date"
                    name="startDate"
                    placeholder="Enter Start Date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.startDate}
                    isInvalid={touched.startDate && errors.startDate}
                    data-cy="startDate"/>)
                }
              <Form.Control.Feedback type="invalid" data-cy="startDate-error">
                {errors.startDate}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formEndDate">
              <AdFormLabel>End Date</AdFormLabel>
              <DatePickerField 
              wrapperClassName="datePicker"
                type="date"
                name="endDate"
                placeholder="Enter End Date"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.endDate}
                isInvalid={touched.endDate && errors.endDate}
                data-cy="endDate"/>
              <Form.Control.Feedback type="invalid" data-cy="endDate-error">
                {errors.endDate}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formDailyBudget">
              <AdFormLabel>Daily Budget</AdFormLabel>
              <AdFormField
                type="number"
                name="dailyBudget"
                placeholder="Enter Daily Budget"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.dailyBudget}
                isInvalid={touched.dailyBudget && errors.dailyBudget}
                data-cy="dBudget"
              />
              {/* {editMode ? <p>*Change will be effective on the next day</p> : null} */}
              <Form.Control.Feedback type="invalid" data-cy="dBudget-error">
                {errors.dailyBudget}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formCountryOption">
              <AdFormLabel>Country</AdFormLabel>
              {INCLUDE_OPTIONS.map((o, i) => {
               let v = !!(1 - i);
              return (
                <AdFormButton
                  key={`country${o}`}
                  mode={values.countryAction === v ? 'active' : ''}
                  onClick={() => setFieldValue('countryAction', v)}
                  data-cy={`country${o.slice(0, 2)}Btn`}
                >
                  {o}
                </AdFormButton>
              )
              })}
              <FlexBox justifyContent="flex-start">
                {values.countries.map((country) => 
                {
                  return (
                  <AdFormButton
                    key={`d${country}`}
                    onClick={() => {
                      setFieldValue(
                        'countries',
                        values.countries.filter((d) => d !== country),
                      );
                    }}
                  >
                    {`${country} `} &#10006;
                  </AdFormButton>);
                })}
              </FlexBox>
              <AdFormSelect multiple size="5">
                {countries
                  .filter(({ country }) => values.countries.indexOf(country) < 0)
                  .map(({ country, id }) => (
                    <option
                      value={id}
                      key={country}
                      onClick={() => {
                        setFieldValue('countries', [
                          ...values.countries,
                          country,
                        ]);
                      }}
                    >
                      {country}
                    </option>
                  ))}
              </AdFormSelect>
            </Form.Group>
            <Form.Group controlId="formDeviceOption">
              <AdFormLabel>Device</AdFormLabel>
              {INCLUDE_OPTIONS.map((o, i) => {
                let v = !!(1 - i);
                return (<AdFormButton
                  key={`device${o}`}
                  mode={values.deviceAction === v ? 'active' : ''}
                  onClick={() => setFieldValue('deviceAction', v)}
                  data-cy={`dev${o.slice(0, 2)}Btn`}
                >
                  {o}
                </AdFormButton>)
                })}
              <FlexBox justifyContent="flex-start">
                {values.devices.map((name) => {
                  return (<AdFormButton
                    key={`d${name}`}
                    onClick={() => {
                      setFieldValue(
                        'devices',
                        values.devices.filter((d) => d !== name),
                      );
                    }}
                  >
                    {`${name} `} &#10006;
                  </AdFormButton>)
                })}
              </FlexBox>
              <AdFormSelect multiple size="5">
                {devices
                  .filter(({ name }) => values.devices.indexOf(name) < 0)
                  .map(({ id, name }) => (
                    <option
                      value={id}
                      key={name}
                      onClick={() => {
                        setFieldValue('devices', [...values.devices, name]);
                      }}
                    >
                      {name}
                    </option>
                  ))}
              </AdFormSelect>
            </Form.Group>
            <FlexBox>
              <AdFormButton
                mode={
                  isSubmitting ||
                  !_.isEmpty(errors) ||
                  submitting
                    ? null
                    : 'active'
                }
                type="submit"
                disabled={
                  isSubmitting ||
                  !_.isEmpty(errors) ||
                  submitting
                }
                onClick={handleSubmit}
                data-cy="submitBtn"
              >
                {editMode ? 'Save' : 'Submit'}
              </AdFormButton>
              <AdFormButton
                disabled={isSubmitting || submitting}
                onClick={() => {
                  setSubmitting();
                  handleReset();
                  onCancel();
                }}
                data-cy="cancelBtn"
              >
                Cancel
              </AdFormButton>
              {editMode ? (
                <AdFormButton
                  mode="danger"
                  disabled={isSubmitting || submitting}
                  onClick={() => {
                    setSubmitting();
                    onDelete(editFormDetails.id);
                  }}
                  data-cy="delBtn"
                >
                  Delete
                </AdFormButton>
              ) : null}
            </FlexBox>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {success && (
              <SuccessMessage margin="5px auto" fontColor="red">
                {success}
              </SuccessMessage>
            )}
          </Form>
        </FormContainer>
      )}
    </Formik>
  );
};

SponsoredForm.propTypes = {
  advertisers: PropTypes.array,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  success: PropTypes.string,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      country: PropTypes.string,
      region: PropTypes.string,
      subregion: PropTypes.string,
      displayname: PropTypes.string,
      code: PropTypes.string,
    }),
  ),
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      brand: PropTypes.string,
    }),
  ),
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  editMode: PropTypes.bool,
  editFormDetails: PropTypes.shape(),
  submitting: PropTypes.bool,
  showAdvertisersDropdown: PropTypes.bool,
};

SponsoredForm.defaultProps = {
  advertisers: [],
  onSubmit: null,
  error: '',
  success: '',
  countries: [],
  devices: [],
  editMode: false,
  onCancel: null,
  onDelete: null,
  editFormDetails: {},
  submitting: false,
  showAdvertisersDropdown: false,
};

export default SponsoredForm;
