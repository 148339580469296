import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import Reminder from './components/Reminder/Reminder';
import PaymentDetails from './components/PaymentDetails/PaymentDetails';
import PaymentSelect from './components/PaymentSelect/PaymentSelect';
import Pagination from './components/Pagniation/Pagination';
import PaypalLoading from './components/PaypalLoading/PaypalLoading';
import FormButton from '../../../core/components/Form/FormButton';
import FlexBox from '../../../core/components/FlexBox';
import PageTitle from '../../../core/components/PageTitle';
import { PAYMENT_REMINDERS } from '../../../core/utility/paymentReminder';
import {
  getRegistrationStatus,
  paymentCompleted,
} from '../../../redux/auth/authSlice';
import { PaymentMethod } from '../../../core/utility/paymentMethod';

class PaymentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validAmount: false,
      inputAmount: 0,
      spending: 0,
      serviceFee: 0,
      touched: false,
      paymentMethodIdx: null,
      step: 1,
      backDisabled: true,
      nextDisabled: true,
      loading: false,
    };
  }

  componentDidMount = () => {
    const paypalSDK = this.addPaypalSDK();
    document.body.appendChild(paypalSDK);
  };

  addPaypalSDK = () => {
    const script = document.createElement('script');
    script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&components=buttons`;
    script.async = true;
    return script;
  };

  addPaypalButton = () => {
    const { inputAmount } = this.state;
    const { advertiserToken, advertiserID } = this.props;
    const script = document.createElement('script');
    script.innerHTML = `
      var button = paypal.Buttons({
        fundingSource: paypal.FUNDING.PAYPAL,
        createOrder: (data, actions) => {
          return actions.order.create({
            commit: true,
            purchase_units: [{
              amount: {
                value: '${inputAmount}'
              }
            }]
          });
        },
        onApprove: function(data, actions) {
          document.getElementById("paypal-button-container").style.display = 'none';
          document.getElementById("paypal-loading").style.display = 'block';
          document.getElementById("payment-back-button").setAttribute("disabled", "true");
          return actions.order.capture().then(function(details) {
            const formData = new FormData();
            formData.append('userId', '${advertiserID}');
            formData.append('currency', 'USD');
            formData.append('amount', '${inputAmount}');
            formData.append('paymentMtd', '${PaymentMethod.PYPL}');
            formData.append('trxTime', details['create_time']);
            formData.append('ppPayerEmail', details['payer']['email_address']);
            formData.append('ppPayerId', details['payer']['payer_id']);
            formData.append('ppTrxId', details['id']);
            formData.append('ppDetail', JSON.stringify(details))
            fetch('${process.env.REACT_APP_DSP_API}/dev/adv-company-payment', {
              method: 'post',
              headers: {
                'Authorization': '${`Basic ${advertiserToken}`}',
              },
              body: formData,
            })
            .then((response) => response.json())
            .then((result) => {
              window.location = '/advertiser/registration-complete-paypal';
            })
            .catch((e) => {
              document.getElementById("paypal-button-container").style.display = 'block';
              document.getElementById("paypal-loading").style.display = 'none';
            })
          });
        },
      });
      button.render('#paypal-button-container');
    `;
    script.async = true;
    return script;
  };

  paymentButtonOnSubmitHandler = () => {
    this.setState({ loading: true });
    const { inputAmount } = this.state;
    const {
      advertiserToken,
      advertiserID,
      getRegistrationStatusAction,
      paymentCompletedAction,
    } = this.props;
    const formData = new FormData();
    formData.append('userId', advertiserID);
    formData.append('currency', 'USD');
    formData.append('amount', inputAmount);
    formData.append('paymentMtd', PaymentMethod.BANKWIRE);
    formData.append('trxTime', new Date().toISOString().slice(0, -1));
    formData.append('ppDetail', JSON.stringify({}));
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_DSP_API}/dev/adv-company-payment`,
      data: formData,
      headers: {
        Authorization: `Basic ${advertiserToken}`,
      },
    })
      .then((res) => {
        const { error } = res['data'];
        if (error) {
          throw new Error(error);
        } else {
          this.setState({ loading: false }, () => {
            paymentCompletedAction(PaymentMethod.BANKWIRE);
            getRegistrationStatusAction(advertiserToken);
          });
        }
      })
      .catch((err) => {
        const error = String(err).split(': ')[1];
        this.setState({ error, loading: false });
      });
  };

  inputAmountOnChangeHandler = (e) => {
    const { touched } = this.state;
    if (!touched) this.setInputTouched();
    const inputAmount = Number(e.target.value) || 0;
    const serviceFee = Number((inputAmount * 0.1).toFixed(2));
    const spending = Number((inputAmount - serviceFee).toFixed(2));
    const validAmount = inputAmount >= 550;
    const nextDisabled = !validAmount;
    this.setState({
      inputAmount,
      serviceFee,
      spending,
      validAmount,
      nextDisabled,
    });
  };

  paymentSelectOnChangeHandler = (idx) => {
    this.setState({ paymentMethodIdx: idx, nextDisabled: false });
  };

  setInputTouched = () => {
    this.setState({ touched: true });
  };

  nextOnClick = () => {
    const { step, paymentMethodIdx, validAmount } = this.state;
    if (paymentMethodIdx === 0 && step === 2) {
      const paypalButton = this.addPaypalButton();
      document.body.appendChild(paypalButton);
    }
    const nextDisabled =
      paymentMethodIdx !== null && validAmount && step < 2 ? false : true;
    this.setState({ step: step + 1, nextDisabled, backDisabled: false });
  };

  backOnClick = () => {
    const { step, paymentMethodIdx } = this.state;
    if (step === 3 && paymentMethodIdx === 0) {
      document.getElementById('paypal-button-container').innerHTML = null;
    }
    const backDisabled = step === 2;
    this.setState({ step: step - 1, nextDisabled: false, backDisabled });
  };

  render() {
    const {
      touched,
      validAmount,
      spending,
      inputAmount,
      serviceFee,
      paymentMethodIdx,
      step,
      backDisabled,
      nextDisabled,
      loading,
    } = this.state;

    return (
      <FlexBox width="80%">
        <PageTitle data-cy="title">Advertiser Payment</PageTitle>
        {step === 1 || step === 3 ? (
          <PaymentDetails
            inputAmount={inputAmount}
            serviceFee={serviceFee}
            spending={spending}
            onChange={this.inputAmountOnChangeHandler}
            touched={touched}
            validAmount={validAmount}
            disabled={step === 3}
          />
        ) : null}
        {step === 2 || step === 3 ? (
          <React.Fragment>
            <PaymentSelect
              step={step}
              selectedPayment={paymentMethodIdx}
              paymentOnSelect={this.paymentSelectOnChangeHandler}
              disabled={step === 3}
            />
            <Reminder verbiage={PAYMENT_REMINDERS} testId="topUpContent" />
          </React.Fragment>
        ) : null}
        <FlexBox width="60%" margin="20px auto">
          {step !== 3 || !paymentMethodIdx ? null : (
            <FormButton
              disabled={loading}
              onClick={this.paymentButtonOnSubmitHandler}
              data-cy="bwSubmitBtn"
            >
              Submit Payment Request
            </FormButton>
          )}
          <div id="paypal-button-container" data-cy="ppBtn" />
          <div id="paypal-loading" style={{ display: 'none' }}>
            <PaypalLoading />
          </div>
        </FlexBox>

        <Pagination
          backDisabled={backDisabled || loading}
          nextDisabled={nextDisabled || loading}
          nextOnClick={this.nextOnClick}
          backOnClick={this.backOnClick}
          firstStep={step === 1}
          lastStep={step === 3}
        />
      </FlexBox>
    );
  }
}

PaymentPage.propTypes = {
  advertiserToken: PropTypes.string,
  advertiserID: PropTypes.string,
  getRegistrationStatusAction: PropTypes.func,
  paymentCompletedAction: PropTypes.func,
};

PaymentPage.defaultProps = {
  advertiserToken: '',
  advertiserID: '',
  getRegistrationStatusAction: null,
};

const mapStateToProps = (state) => ({
  advertiserToken: state.auth.token,
  advertiserID: state.auth.id,
  logoutAction: null,
  paymentCompletedAction: null,
});

const mapDispatchToProps = (dispatch) => ({
  getRegistrationStatusAction: (token) => dispatch(getRegistrationStatus(token)),
  paymentCompletedAction: (paymentMethod) =>
    dispatch(paymentCompleted(paymentMethod)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);
