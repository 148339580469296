import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ConfirmButton from './ConfirmButton';
import ContactTitleDropDown from '../../../../../core/components/Form/ContactTitleDropDown';
import ContactNumberInput from '../../../../../core/components/Form/ContactNumberInput';
import ContactTextInput from '../../../../../core/components/Form/ContactTextInput';
import ErrorMessage from '../../../../../core/components/Form/ErrorMessage';
import SuccessMessage from '../../../../../core/components/Form/SuccessMessage';
import FlexBox from '../../../../../core/components/FlexBox';
import Text from '../../../../../core/components/Text';
import Star from '../../../../../core/components/Star';
import { ContactPersonTitle } from '../../../../../core/utility/contactPersonType';

const AdvertiserEditModal = ({
  selectedAdvertiser,
  show,
  handleClose,
  onSave,
  updateSuccess,
  updateError,
  updating,
}) => {
  const { name } = selectedAdvertiser;
  const validationSchema = Yup.object().shape({
    englishName: Yup.string()
      .max(30, '*English Name must be less than 100 characters')
      .required('*English Name is required'),
    chineseName: Yup.string().max(
      20,
      '*Chinese Name must be less than 20 characters'
    ),
    registeredAddress: Yup.string()
      .max(200, '*Addres must be less than 200 characters')
      .required('*Registered Address is required'),
    correspondenceAddress: Yup.string().max(
      200,
      '*Addres must be less than 200 characters'
    ),
    brNumber: Yup.string(),
    deliveryTerms: Yup.string(),
    taxCode: Yup.string(),
    country: Yup.string().required('*Country is required'),
    bcTitle: Yup.string()
      .oneOf(ContactPersonTitle, '*Title must be Mr, Ms or Mrs')
      .required('*Title is required'),
    bcName: Yup.string().required('*Name is required'),
    bcCountryCode: Yup.string()
      .matches(/^(0|[1-9][0-9]*)$/gi, '*Country Code must be number')
      .required('*Country Code is required'),
    bcAreaCode: Yup.string().matches(
      /^(0|[1-9][0-9]*)$/gi,
      '*Area Code must be number'
    ),
    bcNumber: Yup.string()
      .matches(/^(0|[1-9][0-9]*)$/gi, '*Telephone number must be number')
      .required('*Telephone Number is required'),
    bcEmail: Yup.string()
      .email('*Email must be a valid email address')
      .required('*Email is required'),
    fcTitle: Yup.string()
      .oneOf(ContactPersonTitle, '*Title must be Mr, Ms or Mrs')
      .required('*Title is required'),
    fcName: Yup.string().required('*Name is required'),
    fcCountryCode: Yup.string()
      .matches(/^(0|[1-9][0-9]*)$/gi, '*Country Code must be number')
      .required('*Country Code is required'),
    fcAreaCode: Yup.string().matches(
      /^(0|[1-9][0-9]*)$/gi,
      '*Area Code must be number'
    ),
    fcNumber: Yup.string()
      .matches(/^(0|[1-9][0-9]*)$/gi, '*Telephone number must be number')
      .required('*Telephone Number is required'),
    fcEmail: Yup.string()
      .email('*Email must be a valid email address')
      .required('*Email is required'),
  });

  return (
    <Formik
      initialValues={{
        englishName: '' || selectedAdvertiser['companyEnglishName'],
        chineseName: '' || selectedAdvertiser['companyChineseName'],
        registeredAddress: '' || selectedAdvertiser['companyRegistrationAddress'],
        correspondenceAddress:
          '' || selectedAdvertiser['companyCorrespondenceAddress'],
        brNumber: '' || selectedAdvertiser['companyBusinessRegistrationNumber'],
        deliveryTerms: '' || selectedAdvertiser['companyDeliveryTerm'],
        taxCode: '' || selectedAdvertiser['companyTaxCode'],
        country: '' || selectedAdvertiser['companyCountry'],
        bcTitle: selectedAdvertiser['bcTitle'] || ContactPersonTitle[0],
        bcName: '' || selectedAdvertiser['bcName'],
        bcCountryCode: '' || selectedAdvertiser['bcCountryCode'],
        bcAreaCode: '' || selectedAdvertiser['bcAreaCode'],
        bcNumber: '' || selectedAdvertiser['bcNumber'],
        bcEmail: '' || selectedAdvertiser['bcEmail'],
        fcTitle: selectedAdvertiser['fcTitle'] || ContactPersonTitle[0],
        fcName: '' || selectedAdvertiser['fcName'],
        fcCountryCode: '' || selectedAdvertiser['fcCountryCode'],
        fcAreaCode: '' || selectedAdvertiser['fcAreaCode'],
        fcNumber: '' || selectedAdvertiser['fcNumber'],
        fcEmail: '' || selectedAdvertiser['fcEmail'],
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const { compId } = selectedAdvertiser;
        setSubmitting(true);
        onSave(values, !!compId.length);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        dirty,
      }) => (
        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title data-cy="advTitle">{name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formEngName">
                <Form.Label>
                  <Star />
                  Company Name (In English)
                </Form.Label>
                <Form.Control
                  type="text"
                  name="englishName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.englishName}
                  isInvalid={touched.englishName && errors.englishName}
                  data-cy="compNameEn"
                />
                <Form.Control.Feedback type="invalid" data-cy="compNameEn-error">
                  {errors.englishName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formChName">
                <Form.Label>Company Name (In Chinese)</Form.Label>
                <Form.Control
                  type="text"
                  name="chineseName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.chineseName}
                  isInvalid={touched.chineseName && errors.chineseName}
                  data-cy="compNameZh"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.chineseName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formRAddress">
                <Form.Label>
                  <Star />
                  Registered Address
                </Form.Label>
                <Form.Control
                  type="text"
                  name="registeredAddress"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.registeredAddress}
                  isInvalid={touched.registeredAddress && errors.registeredAddress}
                  data-cy="regAdd"
                />
                <Form.Control.Feedback type="invalid" data-cy="regAdd-error">
                  {errors.registeredAddress}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formBRNumber">
                <Form.Label>Correspondence Address</Form.Label>
                <Form.Control
                  type="text"
                  name="correspondenceAddress"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.correspondenceAddress}
                  isInvalid={
                    touched.correspondenceAddress && errors.correspondenceAddress
                  }
                  data-cy="corAdd"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.correspondenceAddress}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formBRNumber">
                <Form.Label>Business Registration Number</Form.Label>
                <Form.Control
                  type="text"
                  name="brNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.brNumber}
                  isInvalid={touched.brNumber && errors.brNumber}
                  data-cy="bizRegNum"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.brNumber}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formDeliveryTerms">
                <Form.Label>Delivery Terms</Form.Label>
                <Form.Control
                  type="text"
                  name="deliveryTerms"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.deliveryTerms}
                  isInvalid={touched.deliveryTerms && errors.deliveryTerms}
                  data-cy="deliTerms"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.deliveryTerms}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formTaxCode">
                <Form.Label>Tax Code / Tax Registration Number</Form.Label>
                <Form.Control
                  type="text"
                  name="taxCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.taxCode}
                  isInvalid={touched.taxCode && errors.taxCode}
                  data-cy="tax"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.taxCode}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formCountry">
                <Form.Label>
                  <Star />
                  Country
                </Form.Label>
                <Form.Control
                  type="text"
                  name="country"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.country}
                  isInvalid={touched.country && errors.country}
                  data-cy="country"
                />
                <Form.Control.Feedback type="invalid" data-cy="country-error">
                  {errors.country}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formBC">
                <Form.Label>Business Contact Person</Form.Label>
                <ContactTitleDropDown
                  options={ContactPersonTitle}
                  title={values.bcTitle}
                  dropdownID="bcTitleDropdown"
                  onSelect={(id) => {
                    setFieldValue('bcTitle', ContactPersonTitle[id]);
                  }}
                  dropdownTestId="bizTitle"
                />
                <ContactTextInput
                  compulsory
                  label="Name"
                  onChange={(e) => {
                    setFieldValue('bcName', e.target.value);
                  }}
                  value={values.bcName}
                  inputTestId="bizName"
                />
                <ContactNumberInput
                  countryCode={values.bcCountryCode}
                  areaCode={values.bcAreaCode}
                  number={values.bcNumber}
                  countryCodeOnChange={(e) => {
                    setFieldValue('bcCountryCode', e.target.value);
                  }}
                  areaCodeOnChange={(e) => {
                    setFieldValue('bcAreaCode', e.target.value);
                  }}
                  numberOnChange={(e) => {
                    setFieldValue('bcNumber', e.target.value);
                  }}
                  countryCodeTestId="bcnCCode"
                  areaCodeTestId="bcnACode"
                  telTestId="bcnTel"
                />
                <ContactTextInput
                  compulsory
                  label="Email"
                  onChange={(e) => {
                    setFieldValue('bcEmail', e.target.value);
                  }}
                  value={values.bcEmail}
                  inputTestId="bizEmail"
                />
                <ErrorMessage data-cy="biz-error">
                  {errors.bcName ||
                    errors.bcCountryCode ||
                    errors.bcAreaCode ||
                    errors.bcNumber ||
                    errors.bcEmail}
                </ErrorMessage>
              </Form.Group>
              <Form.Group controlId="formFC">
                <Form.Label>Financial Team Contact Person</Form.Label>
                <ContactTitleDropDown
                  options={ContactPersonTitle}
                  title={values.fcTitle}
                  dropdownID="fcTitleDropdown"
                  onSelect={(id) => {
                    setFieldValue('fcTitle', ContactPersonTitle[id]);
                  }}
                  disabled={values.same === 2}
                  dropdownTestId="finTitle"
                />
                <ContactTextInput
                  compulsory
                  label="Name"
                  onChange={(e) => {
                    setFieldValue('fcName', e.target.value);
                  }}
                  value={values.fcName}
                  disabled={values.same === 2}
                  inputTestId="finName"
                />
                <ContactNumberInput
                  countryCode={values.fcCountryCode}
                  areaCode={values.fcAreaCode}
                  number={values.fcNumber}
                  countryCodeOnChange={(e) => {
                    setFieldValue('fcCountryCode', e.target.value);
                  }}
                  areaCodeOnChange={(e) => {
                    setFieldValue('fcAreaCode', e.target.value);
                  }}
                  numberOnChange={(e) => {
                    setFieldValue('fcNumber', e.target.value);
                  }}
                  disabled={values.same === 2}
                  countryCodeTestId="fcnCCode"
                  areaCodeTestId="fcnACode"
                  telTestId="fcnTel"
                />
                <ContactTextInput
                  compulsory
                  label="Email"
                  onChange={(e) => {
                    setFieldValue('fcEmail', e.target.value);
                  }}
                  value={values.fcEmail}
                  disabled={values.same === 2}
                  inputTestId="finEmail"
                />
                <ErrorMessage data-cy="fin-error">
                  {errors.fcName ||
                    errors.fcCountryCode ||
                    errors.fcAreaCode ||
                    errors.fcNumber ||
                    errors.fcEmail}
                </ErrorMessage>
              </Form.Group>
              <FlexBox margin="10px auto">
                <Text>
                  <Star />
                  Compulsory Field
                </Text>
              </FlexBox>
              {updateError ? (
                <ErrorMessage>Update Failed. Please try again!</ErrorMessage>
              ) : null}
              {updateSuccess ? (
                <SuccessMessage margin="5px auto" fontColor="red">
                  Update Succeeded
                </SuccessMessage>
              ) : null}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleClose}
              disabled={isSubmitting || updating}
              data-cy="closeBtn"
            >
              Close
            </Button>
            <ConfirmButton
              variant="primary"
              onClick={handleSubmit}
              disabled={isSubmitting || !_.isEmpty(errors) || !dirty || updating}
              data-cy="confirmBtn"
            >
              Confirm
            </ConfirmButton>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
};

AdvertiserEditModal.propTypes = {
  handleClose: PropTypes.func,
  onSave: PropTypes.func,
  selectedAdvertiser: PropTypes.shape(),
  show: PropTypes.bool,
  updateSuccess: PropTypes.bool,
  updateError: PropTypes.bool,
  updating: PropTypes.bool,
};

AdvertiserEditModal.defaultProps = {
  handleClose: null,
  onSave: null,
  selectedAdvertiser: {},
  show: false,
  updateSuccess: false,
  updateError: false,
  updating: false,
};

export default AdvertiserEditModal;
