import styled from 'styled-components';
import FlexBox from '../../../../../core/components/FlexBox';

const SummaryBoxContainer = styled(FlexBox)`
  width: 20%;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
  cursor: pointer;

  @media (max-width: 1200px) {
    width: 80%;
    margin-top: 15px;
  }
`;

export default SummaryBoxContainer;
