import styled from 'styled-components';
import Text from '../../../../../core/components/Text';

const SummaryValue = styled(Text)`
  color: #363636;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125;
`;

export default SummaryValue;
