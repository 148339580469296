import styled from 'styled-components';
import PropTypes from 'prop-types';

const FlexBox = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  flex-wrap: ${({ flexWrap }) => flexWrap || 'wrap'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  margin: ${({ margin }) => margin || 0};
  padding: ${({ padding }) => padding || 0};
  position: ${({ position }) => position || 'relative'};
`;

FlexBox.propTypes = {
  alignItems: PropTypes.string,
  height: PropTypes.string,
  justifyContent: PropTypes.string,
  flexDirection: PropTypes.string,
  flexWrap: PropTypes.string,
  margin: PropTypes.string,
  width: PropTypes.string,
  position: PropTypes.string,
};

FlexBox.defaultProps = {
  alignItems: '',
  height: '',
  justifyContent: '',
  flexDirection: '',
  flexWrap: '',
  margin: '',
  width: '',
  position: '',
};

export default FlexBox;
