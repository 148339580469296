import styled from 'styled-components';
import FlexBox from '../../../../../core/components/FlexBox';

const VerbiageBox = styled(FlexBox)`
  width: 60%;
  justify-content: space-between;
  margin-top: 10px;
`;

export default VerbiageBox;
