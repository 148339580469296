import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { updateObject } from '../../core/utility/updateObject';

export const initialState = {
  selectedAdvertiser: null,
  advertisers: [],
  error: null,
  loading: false,
};

const advertiserSlice = createSlice({
  name: 'advertiser',
  initialState,
  reducers: {
    resetAdvertisers: {
      reducer: (state) => {
        return updateObject(state, {
          advertisers: [],
          token: null,
          error: null,
          loading: true,
        });
      },
    },
    getAdvertisersSuccess: {
      reducer: (state, action) => {
        const { advertisers } = action['payload'];
        return updateObject(state, { advertisers });
      },
      prepare: (advertisers) => {
        return { payload: { advertisers } };
      },
    },
    getAdvertisersError: {
      reducer: (state, action) => {
        const { error } = action['payload'];
        return updateObject(state, { error });
      },
      prepare: (error) => ({ payload: { error } }),
    },
    setSelectedAdvertiser: {
      reducer: (state, action) => {
        const { selectedAdvertiser } = action['payload'];
        return updateObject(state, { selectedAdvertiser });
      },
      prepare: (advertiser) => {
        return { payload: { selectedAdvertiser: { ...advertiser } } };
      },
    },
  },
});

export const {
  resetAdvertisers,
  getAdvertisersSuccess,
  getAdvertisersError,
  setSelectedAdvertiser,
} = advertiserSlice.actions;

export default advertiserSlice.reducer;

export const getAdvertisers = (adminToken) => async (dispatch) => {
  dispatch(resetAdvertisers());
  try {
    const advertisersResult = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_DSP_API}/admin/user`,
      headers: {
        Authorization: `Basic ${adminToken}`,
      },
    });
    const { data } = advertisersResult['data'];
    if (data) {
      dispatch(setSelectedAdvertiser(data[0]));
      dispatch(getAdvertisersSuccess(data));
    } else {
      const { error } = advertisersResult['data'];
      throw new Error(error);
    }
  } catch (err) {
    const errorMessage = String(err).split(': ')[1];
    dispatch(getAdvertisersError(errorMessage));
  }
};
