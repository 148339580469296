import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import TncPage from './tnc/TnCPage';
import PaymentPage from './payment/PaymentPage';
import AdvertiserInfoPage from './advertiserInfo/AdvertiserInfoPage';
import CompletionPage from './completion/CompletionPage';
import PaypalCompletionPage from './completion/PaypalCompletionPage';
import PageContainer from '../../core/components/PageContainer';
import { getRegistrationStatus } from '../../redux/auth/authSlice';
import { PaymentMethod } from '../../core/utility/paymentMethod';

export const AdvertiserPage = ({
  advertiserToken,
  getRegistrationStatusAction,
  match,
  infoCompleted,
  isPaymentMade,
  paymentMethod,
  statusRetrieved,
  tncCompleted,
}) => {
  useEffect(() => {
    getRegistrationStatusAction(advertiserToken);
    return () => false;
  }, [getRegistrationStatusAction, advertiserToken]);
  const redirectRoute = !tncCompleted
    ? `${match.url}/terms_of_usage`
    : !infoCompleted
    ? `${match.url}/profile`
    : !isPaymentMade
    ? `${match.url}/payment`
    : paymentMethod === PaymentMethod.BANKWIRE
    ? `${match.url}/registration-complete`
    : `${match.url}/registration-complete-paypal`;

  return (
    <PageContainer>
      {statusRetrieved ? (
        <Switch>
          {tncCompleted ? null : (
            <Route exact path={`${match.url}/terms_of_usage`} component={TncPage} />
          )}
          {infoCompleted ? null : (
            <Route
              exact
              path={`${match.url}/profile`}
              component={AdvertiserInfoPage}
            />
          )}
          {!tncCompleted || !infoCompleted || paymentMethod ? null : (
            <Route exact path={`${match.url}/payment`} component={PaymentPage} />
          )}
          <Route
            exact
            path={`${match.url}/registration-complete`}
            component={CompletionPage}
          />
          <Route
            exact
            path={`${match.url}/registration-complete-paypal`}
            component={PaypalCompletionPage}
          />
          <Redirect from="*" to={redirectRoute} />
        </Switch>
      ) : null}
    </PageContainer>
  );
};

AdvertiserPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    params: PropTypes.shape({}),
    isExact: PropTypes.bool,
  }),
  tncCompleted: PropTypes.bool,
  infoCompleted: PropTypes.bool,
  statusRetrieved: PropTypes.bool,
  getRegistrationStatusAction: PropTypes.func,
  advertiserToken: PropTypes.string,
  paymentMethod: PropTypes.string,
  isPaymentMade: PropTypes.bool,
};

AdvertiserPage.defaultProps = {
  match: {},
  tncCompleted: false,
  infoCompleted: false,
  statusRetrieved: false,
  getRegistrationStatusAction: null,
  advertiserToken: '',
  paymentMethod: null,
  isPaymentMade: false,
};

const mapStateToProps = (state) => ({
  advertiserToken: state.auth.token,
  statusRetrieved: state.auth.registrationStatusRetrieved,
  tncCompleted: state.auth.tncCompleted,
  infoCompleted: state.auth.infoCompleted,
  paymentMethod: state.auth.paymentMethod,
  isPaymentMade: state.auth.isPaymentMade,
});

const mapDispatchToProps = (dispatch) => ({
  getRegistrationStatusAction: (token) => dispatch(getRegistrationStatus(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvertiserPage);
