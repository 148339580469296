import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import TncContainer from './components/TncContainer';
import FormButton from '../../../core/components/Form/FormButton';
import ErrorMessage from '../../../core/components/Form/ErrorMessage';
import FlexBox from '../../../core/components/FlexBox';
import PageTitle from '../../../core/components/PageTitle';
import Text from '../../../core/components/Text';
import { getRegistrationStatus } from '../../../redux/auth/authSlice';

export class TnCPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      tncURL: null,
      tncID: null,
      acknowledgeLoading: false,
      error: null,
    };
  }

  componentDidMount = () => {
    this.getLatestTNC();
  };

  getLatestTNC = () => {
    const { advertiserToken } = this.props;
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_DSP_API}/dev/agreement/DSP_TNC?version=latest`,
      headers: {
        Authorization: `Basic ${advertiserToken}`,
      },
    })
      .then((res) => {
        const { data, error } = res['data'];
        if (error) {
          throw new Error(error);
        } else {
          const { id, content } = data;
          this.setState({ tncURL: content, tncID: id });
        }
      })
      .catch((err) => {
        const error = String(err).split(': ')[1];
        this.setState({ error });
      });
  };

  postTncSignedRequest = () => {
    const { tncID } = this.state;
    const { advertiserToken, getRegistrationStatusAction } = this.props;
    const formData = new FormData();
    formData.append('agreementId', tncID);
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_DSP_API}/dev/agreement/ack`,
      data: formData,
      headers: {
        Authorization: `Basic ${advertiserToken}`,
      },
    })
      .then((res) => {
        const { error } = res['data'];
        if (error) {
          throw new Error(error);
        } else {
          this.setState({ acknowledgeLoading: false }, () => {
            getRegistrationStatusAction(advertiserToken);
          });
        }
      })
      .catch((err) => {
        const error = String(err).split(': ')[1];
        this.setState({ error, acknowledgeLoading: false });
      });
  };

  checkboxOnChangeHandler = () => {
    const { checked } = this.state;
    this.setState({ checked: !checked });
  };

  submitOnClickHandler = () => {
    this.setState({ acknowledgeLoading: true }, () => {
      this.postTncSignedRequest();
    });
  };

  render() {
    const { checked, error, acknowledgeLoading } = this.state;
    const { tncURL, tncID } = this.state;
    return (
      <React.Fragment>
        {tncURL && tncID && (
          <React.Fragment>
            <FlexBox margin="0px auto 10px auto">
              <PageTitle>Terms of Usage</PageTitle>
            </FlexBox>
            <TncContainer src={tncURL} data-cy="termsIframe" />
            <FlexBox margin="10px auto">
              <Form.Check
                onChange={this.checkboxOnChangeHandler}
                data-cy="agreeCheck"
              />
              <Text>
                I agree to{' '}
                <span style={{ color: '#007bff', textDecoration: 'underline' }}>
                  terms of usage
                </span>
              </Text>
            </FlexBox>
            <FlexBox width="30%">
              <FormButton
                disabled={!checked || acknowledgeLoading}
                onClick={this.submitOnClickHandler}
                data-cy="submitBtn"
              >
                Submit
              </FormButton>
            </FlexBox>
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

TnCPage.propTypes = {
  advertiserToken: PropTypes.string,
  getRegistrationStatusAction: PropTypes.func,
};

TnCPage.defaultProps = {
  advertiserToken: '',
  getRegistrationStatusAction: null,
};

const mapStateToProps = (state) => ({
  advertiserToken: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  getRegistrationStatusAction: (token) => dispatch(getRegistrationStatus(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TnCPage);
