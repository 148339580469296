import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import RadioBox from './RadioBox';
import RadioImage from './RadioImage.js';
import FlexBox from '../../../../../core/components/FlexBox';
import Text from '../../../../../core/components/Text';

const PaymentSelect = ({ selectedPayment, paymentOnSelect, disabled, step }) => (
  <FlexBox margin="20px auto">
    <FlexBox>
      <Text fontSize="1.25">
        {step === 2 ? 'Please choose a payment method' : 'Payment Method'}
      </Text>
    </FlexBox>
    <RadioBox>
      <Form.Check
        type="radio"
        checked={selectedPayment === 0}
        onChange={() => paymentOnSelect(0)}
        label="Paypal"
        disabled={disabled}
        data-cy="ppCheck"
      />
      <RadioImage src={window.location.origin + '/Paypal.png'} />
    </RadioBox>
    <RadioBox>
      <Form.Check
        type="radio"
        checked={selectedPayment === 1}
        onChange={() => paymentOnSelect(1)}
        label="Bank Wire"
        disabled={disabled}
        data-cy="bwCheck"
      />
      <RadioImage src={window.location.origin + '/BankWire.png'} />
    </RadioBox>
  </FlexBox>
);

PaymentSelect.propTypes = {
  selectedPayment: PropTypes.number,
  step: PropTypes.number,
  paymentOnSelect: PropTypes.func,
  disabled: PropTypes.bool,
};

PaymentSelect.defaultProps = {
  selectedPayment: null,
  step: 2,
  paymentOnSelect: null,
  disabled: false,
};

export default PaymentSelect;
