import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ContactTextField = styled(Form.Control)`
  width: ${({ width }) => width || '50%'};
  margin-left: 10px;
`;

ContactTextField.propTypes = {
  width: PropTypes.string,
};

ContactTextField.defaultProps = {
  width: '',
};

export default ContactTextField;
