import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { isEmpty, forEach } from 'lodash';

import HeaderContainer from './component/Header/HeaderContainer';
import AdForm from './component/AdForm/AdForm';
import FlexBox from '../../../core/components/FlexBox';
import Text from '../../../core/components/Text';
import Spinner from '../../../core/components/Spinner';

export class AdFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      advertiserOptions: [],
      loading: false,
      countriesOptions: [],
      devicesOptions: [],
      targetGroupsOptions: [],
      osVersionsOptions: {},
      error: '',
      success: '',
      editMode: false,
      editFormDetails: {},
      submitting: false,
      showAdvertisersDropdown: false,
    };
  }

  componentDidMount = () => {
    const { advertisers, location } = this.props;
    const { state, pathname } = location;
    const editMode = pathname.indexOf('/admin/ad_edit') >= 0;
    if (!state) {
      this.redirectOnEmptyEditForm();
    } else {
      this.setState({ loading: true }, async () => {
        const {
          countriesOptions,
          devicesOptions,
          targetGroupsOptions,
          osVersionsOptions,
        } = await this.getFormOptions();
        const haveAd = !!state && !!state['ad'];
        const editFormDetails = haveAd
          ? this.processEditFormDetails(state['ad'], editMode)
          : {};
        const transformedOSVersionsOptions =
          this.OSVersionsOptionsTransformer(osVersionsOptions) || {};
        const showAdvertisersDropdown =
          !haveAd && state['onAllUserMode'] ? true : false;
        const advertiserOptions = advertisers.map(({ name, id }) => ({ name, id }));
        this.setState({
          loading: false,
          advertiserOptions,
          countriesOptions,
          devicesOptions,
          targetGroupsOptions,
          osVersionsOptions: transformedOSVersionsOptions,
          editMode,
          editFormDetails,
          showAdvertisersDropdown,
        });
      });
    }
  };

  redirectOnEmptyEditForm = () => {
    const { history } = this.props;
    history.replace({
      pathname: '/admin/ads_manager',
    });
  };

  getFormOptions = () => {
    return axios
      .all([
        this.getDevices(),
        this.getCountries(),
        this.getTargetGroups(),
        this.getOSVersions(),
      ])
      .then(
        axios.spread((dRes, cRes, gRes, osRes) => {
          const devicesData = dRes['data'];
          const countriesData = cRes['data'];
          const targetGroupsData = gRes['data'];
          const osVersionsData = osRes['data'];
          if (
            devicesData['error'] ||
            countriesData['error'] ||
            targetGroupsData['error'] ||
            osVersionsData['error']
          ) {
            throw new Error('Network Error. Please Refresh');
          } else {
            return {
              countriesOptions: [...countriesData['data']],
              devicesOptions: [...devicesData['data']],
              targetGroupsOptions: [...targetGroupsData['data']],
              osVersionsOptions: [...osVersionsData['data']],
            };
          }
        })
      )
      .catch(() => {
        return {
          countriesOptions: [],
          devicesOptions: [],
          targetGroupsOptions: [],
          osVersionsOptions: [],
        };
      });
  };

  getDevices() {
    return axios({
      url: `${process.env.REACT_APP_DSP_API}/dev/comref`,
      method: 'GET',
    });
  }

  getCountries() {
    return axios({
      url: `${process.env.REACT_APP_DSP_API}/dev/country`,
      method: 'GET',
    });
  }

  getTargetGroups() {
    return axios({
      url: `${process.env.REACT_APP_DSP_API}/dev/targetGroup`,
      method: 'GET',
    });
  }

  getOSVersions() {
    return axios({
      url: `${process.env.REACT_APP_DSP_API}/dev/os_version`,
      method: 'GET',
    });
  }

  prepareFormData(data) {
    const formData = new FormData();

    forEach(data, (value, key) => {
      formData.append(key, value);
    });

    return formData;
  }

  sendCreateAdRequest = (
    userID,
    name,
    cpm,
    clickURL,
    dailyBudget,
    countriesIDs,
    comrefsIDs,
    targetGroupsNames,
    targetOSVersions,
    type,
    certStateRequirement,
    width,
    height,
    file,
    impQuota,
    impCapPeriodMinute
  ) => {
    this.setState({ submitting: true });
    const { history, selectedAdvertiser, adminToken } = this.props;
    const { id } = selectedAdvertiser;
    const advertiserID = userID || id;

    const formDataForCreate = this.prepareFormData({
      countries: countriesIDs,
      comrefs: comrefsIDs,
      targetGroups: targetGroupsNames,
      targetOSVersionIDs: targetOSVersions,
      name,
      clickURL,
      cpm,
      file,
      target: type.toLowerCase().replace(/\s/g, '-'),
      certStateRequirement,
      dailySpend: dailyBudget,
      description: `${name} description`,
      content: '<html><head></head><body>body</body></html>',
      width,
      height,
      color: '#ffffff',
      status: 2,
      impQuota,
      impCapPeriod: `${impCapPeriodMinute}m`,
    });

    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_DSP_API}/admin/ad/${advertiserID}`,
      data: formDataForCreate,
      headers: {
        Authorization: `Basic ${adminToken}`,
      },
    })
      .then((res) => {
        const { error } = res['data'];
        if (error) {
          throw new Error(error);
        } else {
          this.setState({
            success: `${name} Ad is created successfully.`,
          });
          setTimeout(() => {
            this.setState({ submitting: false }, () => {
              history.goBack();
            });
          }, 500);
        }
      })
      .catch(() => {
        this.setState({ error: 'Create Ad Error', submitting: false });
      });

  };

  sendEditAdRequest = (
    userID,
    name,
    cpm,
    clickURL,
    dailyBudget,
    countriesIDs,
    comrefsIDs,
    targetGroupsNames,
    targetOSVersions,
    type,
    certStateRequirement,
    width,
    height,
    file,
    adID,
    impQuota,
    impCapPeriodMinute
  ) => {
    this.setState({ submitting: true });
    const { editFormDetails } = this.state;
    const { history, adminToken } = this.props;
    const { status } = editFormDetails;

    const formDataForUpdate = this.prepareFormData({
      countries: countriesIDs,
      comrefs: comrefsIDs,
      targetGroups: targetGroupsNames,
      targetOSVersionIDs: targetOSVersions,
      name,
      clickURL,
      cpm,
      file,
      target: type.toLowerCase().replace(/\s/g, '-'),
      certStateRequirement,
      dailySpend: dailyBudget,
      description: `${name} description`,
      content: '<html><head></head><body>body</body></html>',
      width,
      height,
      color: '#ffffff',
      status: status,
      impQuota,
      impCapPeriod: `${impCapPeriodMinute}m`,
    });

    axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_DSP_API}/admin/ad/${userID}/${adID}`,
      data: formDataForUpdate,
      headers: {
        Authorization: `Basic ${adminToken}`,
      },
    })
      .then((res) => {
        const { error } = res['data'];
        if (error) {
          throw new Error(error);
        } else {
          this.setState({
            success: `${name} Ad is edited successfully.`,
          });
          setTimeout(() => {
            this.setState({ submitting: false }, () => {
              history.goBack();
            });
          }, 500);
        }
      })
      .catch(() => {
        this.setState({ error: 'Edit Ad Error', submitting: false });
      });

  };

  sendDeleteAdRequest = (userID, adID) => {
    this.setState({ submitting: true });
    const { history, adminToken } = this.props;
    axios({
      method: 'DELETE',
      url: `${process.env.REACT_APP_DSP_API}/admin/ad/${userID}/${adID}`,
      headers: {
        Authorization: `Basic ${adminToken}`,
      },
    })
      .then((res) => {
        const { error } = res['data'];
        if (error) {
          throw new Error(error);
        } else {
          this.setState({
            success: 'Ad is deleted successfully.',
          });
          setTimeout(() => {
            this.setState({ submitting: false }, () => {
              history.goBack();
            });
          }, 500);
        }
      })
      .catch(() => {
        this.setState({ error: 'Delete Ad Error', submitting: false });
      });
  };

  submitButtonOnClickHandler = (userID, values, file) => {
    this.setState({ error: '' }, () => {
      const { editMode } = this.state;
      const {
        name,
        cpm,
        clickURL,
        dailyBudget,
        countryOption,
        countries,
        comrefOption,
        comrefs,
        targetGroupOption,
        targetGroups,
        targetOSVersions,
        type,
        certStateRequirement,
        width,
        height,
        impQuota,
        impCapPeriodMinute,
      } = values;
      const countriesIDs = this.processCountriesOptions(countries, countryOption);
      const comrefsIDs = this.processComrefsOptions(comrefs, comrefOption);
      const targetGroupsNames = this.processTargetGroupsOptions(
        targetGroups,
        targetGroupOption
      );

      if (editMode) {
        const { editFormDetails } = this.state;
        const { id } = editFormDetails;
        this.sendEditAdRequest(
          userID,
          name,
          cpm,
          clickURL,
          dailyBudget,
          countriesIDs,
          comrefsIDs,
          targetGroupsNames,
          targetOSVersions,
          type,
          certStateRequirement,
          width,
          height,
          file,
          id,
          impQuota,
          impCapPeriodMinute
        );
      } else {
        this.sendCreateAdRequest(
          userID,
          name,
          cpm,
          clickURL,
          dailyBudget,
          countriesIDs,
          comrefsIDs,
          targetGroupsNames,
          targetOSVersions,
          type,
          certStateRequirement,
          width,
          height,
          file,
          impQuota,
          impCapPeriodMinute
        );
      }
    });
  };

  cancelAdOnClickHandler = () => {
    const { history } = this.props;
    history.goBack();
  };

  deleteAdOnClickHandler = (userID, adID) => {
    this.sendDeleteAdRequest(userID, adID);
  };

  processCountriesOptions = (selected, option) => {
    const { countriesOptions } = this.state;
    const countriesIDs = selected.map((c) => c.id);
    let countries;
    if (option === 'Include') {
      countries = countriesIDs;
    } else {
      countries = countriesOptions
        .filter(({ id }) => !countriesIDs.includes(id))
        .map(({ id }) => id);
    }
    return countries;
  };

  processComrefsOptions = (selected, option) => {
    const { devicesOptions } = this.state;
    const devicesIDs = selected.map((d) => d.id);
    let devices;
    if (option === 'Include') {
      devices = devicesIDs;
    } else {
      devices = devicesOptions
        .filter(({ id }) => !devicesIDs.includes(id))
        .map(({ id }) => id);
    }
    return devices;
  };

  processTargetGroupsOptions = (selected, option) => {
    const { targetGroupsOptions } = this.state;
    let targetGroups;
    if (option === 'Include') {
      targetGroups = selected;
    } else {
      targetGroups = targetGroupsOptions.filter((name) => !selected.includes(name));
    }
    return targetGroups;
  };

  OSVersionsOptionsTransformer = (options = []) => {
    const transformedOptions = options.reduce(
      ({ options, groupByOS }, nextOption) => {
        if (nextOption.osVersionGroup.includes('3')) {
          groupByOS['3.0'].push(nextOption.id);
        } else {
          groupByOS['2.5'].push(nextOption.id);
        }

        options[nextOption.id] = nextOption;

        return { options, groupByOS };
      },
      { options: {}, groupByOS: { '2.5': [], '3.0': [] } }
    );

    return transformedOptions;
  };

  processEditFormDetails = (formDetails, isEdit) => {
    const { countries, comrefs, targetGroups, target, content } = formDetails;
    const updatedCountries = countries
      ? countries.map(({ id, country }) => ({ id, country }))
      : [];
    const updatedComrefs = comrefs
      ? comrefs.map(({ id, name }) => ({ id, name }))
      : [];
    const updateTargetGroups = targetGroups ? targetGroups : [];
    const updatedTarget = target
      .split('-')
      .map((e) => `${e[0].toUpperCase()}${e.slice(1)}`)
      .join(' ');
    const creative = isEdit ? content : '';

    return {
      ...formDetails,
      content: creative,
      countries: updatedCountries,
      comrefs: updatedComrefs,
      targetGroups: updateTargetGroups,
      target: updatedTarget,
      targetOSVersionGroup: isEmpty(formDetails.targetOSVersions)
        ? '2.5'
        : formDetails.targetOSVersions[0].osVersionGroup,
      targetOSVersions: isEmpty(formDetails.targetOSVersions)
        ? []
        : formDetails.targetOSVersions.map(({ id }) => id),
    };
  };

  render() {
    const {
      advertiserOptions,
      countriesOptions,
      devicesOptions,
      targetGroupsOptions,
      osVersionsOptions,
      loading,
      success,
      error,
      editMode,
      editFormDetails,
      submitting,
      showAdvertisersDropdown,
    } = this.state;
    return (
      <FlexBox padding={window.innerWidth < 750 ? "0 5%" : "0 20%"}>
        {loading ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <HeaderContainer>
              <Text fontSize="1.25" fontColor="#3273dc" data-cy="title">
                {editMode ? 'Edit Ad' : 'Create Ad'}
              </Text>
            </HeaderContainer>
            <AdForm
              advertisers={advertiserOptions}
              countries={countriesOptions}
              devices={devicesOptions}
              targetGroups={targetGroupsOptions}
              osVersionsOptions={osVersionsOptions}
              onSubmit={this.submitButtonOnClickHandler}
              onCancel={this.cancelAdOnClickHandler}
              onDelete={this.deleteAdOnClickHandler}
              success={success}
              error={error}
              editMode={editMode}
              editFormDetails={editFormDetails}
              submitting={submitting}
              showAdvertisersDropdown={showAdvertisersDropdown}
            />
          </React.Fragment>
        )}
      </FlexBox>
    );
  }
}

AdFormPage.propTypes = {
  selectedAdvertiser: PropTypes.shape(),
  location: PropTypes.shape({
    state: PropTypes.shape(),
    pathname: PropTypes.string,
  }),
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    params: PropTypes.shape({}),
    isExact: PropTypes.bool,
  }),
  adminToken: PropTypes.string,
  advertisers: PropTypes.array,
  history: PropTypes.shape({
    goBack: PropTypes.func,
    replace: PropTypes.func,
  }),
};

AdFormPage.defaultProps = {
  selectedAdvertiser: null,
  location: {},
  match: {
    path: '/',
    url: '/',
    params: {},
    isExact: false,
  },
  adminToken: '',
  advertisers: [],
  history: null,
};

const mapStateToProps = (state) => ({
  selectedAdvertiser: state.advertiser.selectedAdvertiser,
  adminToken: state.auth.token,
  advertisers: state.advertiser.advertisers,
});

export default connect(mapStateToProps)(withRouter(AdFormPage));
