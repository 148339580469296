import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import HeaderContainer from './component/Header/HeaderContainer';
import FlexBox from '../../../core/components/FlexBox';
import Text from '../../../core/components/Text';
import Spinner from '../../../core/components/Spinner';
import SponsoredForm from './component/AdForm/SponsoredForm';
import moment from 'moment';

export class SponsoredSearchFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      advertiserOptions: [],
      loading: false,
      countriesOptions: [],
      devicesOptions: [],
      osVersionsOptions: [],
      targetGroupsOptions: [],
      error: '',
      success: '',
      editMode: false,
      editFormDetails: {},
      submitting: false,
      showAdvertisersDropdown: false,
    };
  }

  componentDidMount = () => {
    const { advertisers, location } = this.props;
    const { state, pathname } = location;
    const editMode = pathname.indexOf('/admin/sponsored_edit') >= 0;
    if (!state) {
      this.redirectOnEmptyEditForm();
    } else {
      this.setState({ loading: true }, async () => {
        const { countriesOptions, devicesOptions, osVersionsOptions, targetGroupsOptions } = await this.getFormOptions();
        const haveAd = !!state && !!state['ad'];
        const editFormDetails = haveAd
          ? this.processEditFormDetails(state['ad'], editMode)
          : {};
        const showAdvertisersDropdown =
          !haveAd && state['onAllUserMode'] ? true : false;
        const advertiserOptions = advertisers.map(({ name, id }) => ({ name, id }));
        this.setState({
          loading: false,
          advertiserOptions,
          countriesOptions,
          devicesOptions,
          targetGroupsOptions,
          osVersionsOptions,
          editMode,
          editFormDetails,
          showAdvertisersDropdown,
        });
      });
    }
  };

  redirectOnEmptyEditForm = () => {
    const { history } = this.props;
    history.replace({
      pathname: '/admin/ads_manager',
    });
  };

  getFormOptions = () => {
    return axios
      .all([this.getDevices(), this.getCountries(), this.getOSVersions()])
      .then(
        axios.spread((dRes, cRes, osRes) => {
          const devicesData = dRes['data'];
          const countriesData = cRes['data'];
          const osVersionsData = osRes['data'];
          if (devicesData['error'] || countriesData['error'] || osVersionsData['error']) {
            throw new Error('Network Error. Please Refresh');
          } else {
            return {
              countriesOptions: [...countriesData['data']],
              devicesOptions: [...devicesData['data']],
              osVersionsOptions: [...osVersionsData['data']],
            };
          }
        })
      )
      .catch(() => {
        return { countriesOptions: [], devicesOptions: [], osVersionsOptions: []};
      });
  };

  getDevices() {
    return axios({
      url: `${process.env.REACT_APP_DSP_API}/dev/comref`,
      method: 'GET',
    });
  }

  getCountries() {
    return axios({
      url: `${process.env.REACT_APP_DSP_API}/dev/country`,
      method: 'GET',
    });
  }

  getTargetGroups() {
    return axios({
      url: `${process.env.REACT_APP_DSP_API}/dev/targetGroup`,
      method: 'GET',
    });
  }

  getOSVersions() {
    return axios({
      url: `${process.env.REACT_APP_DSP_API}/dev/os_version`,
      method: 'GET',
    })
  }

  sendCreateAdRequest = (
    advertiserID,
    name,
    cpi,
    type,
    countries,
    countryAction,
    devices,
    deviceAction,
    OSVersionIDs,
    bundleID,
    startDate,
    endDate,
    dailyBudget
  ) => {
    this.setState({ submitting: true });
    const { history, adminToken } = this.props;
    const formData = new FormData();
    formData.append('countries', countries.join(','));
    formData.append('type', type);
    formData.append('countryAction', countryAction);
    formData.append('devices', devices.join(','));
    formData.append('deviceAction', deviceAction);
    formData.append('name', name);
    formData.append('advertiserID', advertiserID);
    formData.append('bundleID', bundleID);
    formData.append('startDate', moment(startDate).toISOString());
    formData.append('endDate', moment(endDate).toISOString());
    formData.append('cpi', cpi);
    formData.append('dailySpend', dailyBudget);
    formData.append('targetOSVersionIDs', OSVersionIDs);

    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_DSP_API}/admin/app-campaign/`,
      data: formData,
      headers: {
        Authorization: `Basic ${adminToken}`,
      },
    })
      .then((res) => {
        const { error } = res['data'];
        if (error) {
          throw new Error(error);
        } else {
          this.setState({
            success: `${name} Campaign is created successfully.`,
          });
          setTimeout(() => {
            this.setState({ submitting: false }, () => {
              history.goBack();
            });
          }, 500);
        }
      })
      .catch((err) => {
        if (err.response.data.error) {
          this.setState({ error: `Create Campaign Error - ${err.response.data.error}`, submitting: false });
        } else {
          this.setState({ error: 'Create Campaign Error', submitting: false });
        }
        
      });
  };

  sendEditAdRequest = (
    id,
    advertiserID,
    name,
    cpi,
    type,
    countries,
    countryAction,
    devices,
    deviceAction,
    OSVersionIDs,
    bundleID,
    startDate,
    endDate,
    dailyBudget,
    updatedAt
  ) => {
    this.setState({ submitting: true });
    const { history, adminToken } = this.props;
    const formData = new FormData()
    formData.append('countries', countries.join(','));
    formData.append('type', type);
    formData.append('countryAction', countryAction);
    formData.append('devices', devices.join(','));
    formData.append('deviceAction', deviceAction);
    formData.append('name', name);
    formData.append('advertiserID', advertiserID);
    formData.append('bundleID', bundleID);
    formData.append('startDate', moment(startDate).toISOString());
    formData.append('endDate', moment(endDate).toISOString());
    formData.append('cpi', cpi);
    formData.append('dailySpend', dailyBudget);
    formData.append('targetOSVersionIDs', OSVersionIDs);
    formData.append('updatedAt', moment(new Date(Date.now())).toISOString());

    axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_DSP_API}/admin/app-campaign/${id}`,
      data: formData,
      headers: {
        Authorization: `Basic ${adminToken}`,
      },
    })
      .then((res) => {
        const { error } = res['data'];
        if (error) {
          throw new Error(error);
        } else {
          this.setState({
            success: `${name} Ad is edited successfully.`,
          });
          setTimeout(() => {
            this.setState({ submitting: false }, () => {
              history.goBack();
            });
          }, 500);
        }
      })
      .catch((err) => {
        if (err.response.data.error) {
          this.setState({ error: `Edit Ad Error - ${err.response.data.error}`, submitting: false });
        } else {
          this.setState({ error: "Edit Ad Error", submitting: false });
        }
      });
  };

  sendDeleteAdRequest = (adID) => {
    this.setState({ submitting: true });
    const { history, adminToken } = this.props;
    axios({
      method: 'DELETE',
      url: `${process.env.REACT_APP_DSP_API}/admin/app-campaign/${adID}`,
      headers: {
        Authorization: `Basic ${adminToken}`,
      },
    })
      .then((res) => {
        const { error } = res['data'];
        if (error) {
          throw new Error(error);
        } else {
          this.setState({
            success: 'Ad is deleted successfully.',
          });
          setTimeout(() => {
            this.setState({ submitting: false }, () => {
              history.goBack();
            });
          }, 500);
        }
      })
      .catch(() => {
        this.setState({ error: 'Delete Ad Error', submitting: false });
      });
  };

  submitButtonOnClickHandler = (values) => {
    this.setState({ error: '' }, () => {
      const {
        advertiserID,
        name,
        cpi,
        type,
        countries,
        countryAction,
        devices,
        deviceAction,
        targetOSVersions,
        targetOSVersionOption,
        bundleID,
        startDate,
        endDate,
        dailyBudget
      } = values;
      const OSVersionIDs = this.processOSVersionsOptions(targetOSVersions, targetOSVersionOption)
      if (this.state.editMode) {
        this.sendEditAdRequest(
          this.state.editFormDetails.id,
          advertiserID,
          name,
          cpi,
          type,
          countries,
          countryAction,
          devices,
          deviceAction,
          OSVersionIDs,
          bundleID,
          startDate,
          endDate,
          dailyBudget,
        )
      } else {
        this.sendCreateAdRequest(
          advertiserID,
          name,
          cpi,
          type,
          countries,
          countryAction,
          devices,
          deviceAction,
          OSVersionIDs,
          bundleID,
          startDate,
          endDate,
          dailyBudget
          );
      }
    });
  };

  cancelAdOnClickHandler = () => {
    const { history } = this.props;
    history.goBack();
  };

  deleteAdOnClickHandler = (adID) => {
    this.sendDeleteAdRequest(adID);
  };

  processOSVersionsOptions = (selected, option) => {
    console.log("selected: ");
    console.log(selected);
    let targetOSVersionIDs;
    if (option === "2.5") {
      targetOSVersionIDs = selected.filter(o => o.id <= 14).map(({id}) => id);
      console.log("targetOSVersionsIDs:  ");
      console.log(targetOSVersionIDs);
    } else if (option === "3.0") {
      targetOSVersionIDs = selected.filter(o => o.id > 14).map(({id}) => id);
      console.log("targetOSVersionsIDs:  ");
      console.log(targetOSVersionIDs);
    }
    return targetOSVersionIDs;
  }

  processCountriesOptions = (selected, option) => {
    const { countriesOptions } = this.state;
    const countriesIDs = selected.map((c) => c.id);
    let countries;
    if (option === 'Include') {
      countries = countriesIDs;
    } else {
      countries = countriesOptions
        .filter(({ id }) => !countriesIDs.includes(id))
        .map(({ id }) => id);
    }
    return countries;
  };

  processEditFormDetails = (formDetails, isEdit) => {
    function pgArrtoJSArr(a) {
      return JSON.parse((a||'[]').replace('{"', '{').replace('{', '["').replace('"}', '}').replace('}', '"]').replaceAll(',', '","').replace('["]', '[]').replace('[""]', '[]'));
    }

    return {
      ...formDetails,
      devices: pgArrtoJSArr(formDetails.devices),
      countries: pgArrtoJSArr(formDetails.countries),
      targetOSVersionGroup: formDetails.targetOSVersions[0].osVersionGroup,
      startDate: moment(formDetails.startDate, "YYYY-MM-DD HH:mm:ss ZZ zz").toDate(),
      endDate: moment(formDetails.endDate, "YYYY-MM-DD HH:mm:ss ZZ zz").toDate()
    };
  };

  render() {
    const {
      advertiserOptions,
      countriesOptions,
      devicesOptions,
      osVersionsOptions,
      loading,
      success,
      error,
      editMode,
      editFormDetails,
      submitting,
      showAdvertisersDropdown,
    } = this.state;
    return (
      <FlexBox padding={window.innerWidth < 750 ? "0 5%" : "0 20%"}>
        {loading ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <HeaderContainer>
              <Text fontSize="1.25" fontColor="#3273dc" data-cy="title">
                {editMode ? 'Edit Spsonsored Search/YMAL' : 'Create Sponsored Search/YMAL'}
              </Text>
            </HeaderContainer>
            <SponsoredForm
              advertisers={advertiserOptions}
              countries={countriesOptions}
              devices={devicesOptions}
              osVersions={osVersionsOptions}
              onSubmit={this.submitButtonOnClickHandler}
              onCancel={this.cancelAdOnClickHandler}
              onDelete={this.deleteAdOnClickHandler}
              success={success}
              error={error}
              editMode={editMode}
              editFormDetails={editFormDetails}
              submitting={submitting}
              showAdvertisersDropdown={showAdvertisersDropdown}
            />
          </React.Fragment>
        )}
      </FlexBox>
    );
  }
}

SponsoredSearchFormPage.propTypes = {
  selectedAdvertiser: PropTypes.shape(),
  location: PropTypes.shape({
    state: PropTypes.shape(),
    pathname: PropTypes.string,
  }),
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    params: PropTypes.shape({}),
    isExact: PropTypes.bool,
  }),
  adminToken: PropTypes.string,
  advertisers: PropTypes.array,
  history: PropTypes.shape({
    goBack: PropTypes.func,
    replace: PropTypes.func,
  }),
};

SponsoredSearchFormPage.defaultProps = {
  selectedAdvertiser: null,
  location: {},
  match: {
    path: '/',
    url: '/',
    params: {},
    isExact: false,
  },
  adminToken: '',
  advertisers: [],
  history: null,
};

const mapStateToProps = (state) => ({
  selectedAdvertiser: state.advertiser.selectedAdvertiser,
  adminToken: state.auth.token,
  advertisers: state.advertiser.advertisers,
});

export default connect(mapStateToProps)(withRouter(SponsoredSearchFormPage));
