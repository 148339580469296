import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';

const AdFormDropdown = ({ options, title, dropdownID, onSelect, disabled }) => (
  <AdFormDropdownContainer onSelect={onSelect}>
    <AdFormDropdownButton
      disabled={disabled}
      variant="light"
      id={dropdownID}
      data-cy="advListBtn"
    >
      {title}
    </AdFormDropdownButton>
    <Dropdown.Menu
      style={{ maxHeight: 400, overflow: 'scroll', width: '100%' }}
      data-cy="advList"
    >
      {options.map((option, i) => (
        <Dropdown.Item eventKey={i} key={i}>
          {option}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </AdFormDropdownContainer>
);

const AdFormDropdownContainer = styled(Dropdown)`
  width: 100%;
`;

const AdFormDropdownButton = styled(Dropdown.Toggle)`
  border: 1px solid #b5b5b5;
  width: 100%;
`;

AdFormDropdown.propTypes = {
  dropdownID: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
};

AdFormDropdown.defaultProps = {
  dropdownID: '',
  options: [],
  title: '',
  onSelect: null,
  disabled: false,
};

export default AdFormDropdown;
