import styled from 'styled-components';
import Text from '../../../../../core/components/Text';

const ReminderText = styled(Text)`
  margin: 3px;
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
`;

export default ReminderText;
