import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const AdFormLabel = styled(Form.Label)`
  font-size: 1.25rem;
  font-weight: 600;
  width: 100%;
`;

export default AdFormLabel;
