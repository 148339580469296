import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import Switch from 'react-switch';
import { FaEdit, FaRegCopy } from 'react-icons/fa';
import AdsTableButton from './AdsTableButton';

const SponsoredTable = ({
  ads,
  adStatusOnChange,
  onCloneButtonClick,
  onEditButtonClick,
}) => (
  <Table>
    <thead>
      <tr>
        <th>Status</th>
        <th>Advertiser</th>
        <th>Ad ID</th>
        <th>uid</th>
        <th>Ad name</th>
        <th>Icon</th>
        <th>Bundle ID</th>
        <th>OS Ver. Group</th>
        {/* <th>Impressions</th>
        <th>Clicks</th>
        <th>CTR</th> */}
        <th>Spent</th>
        <th>Daily Budget</th>
        <th>Conversion</th>
        <th>CPA</th>
      </tr>
    </thead>
    <tbody>
      {ads.map((ad, i, arr) => {
        const {
          id,
          advertiserID,
          name,
          content,
          bundleID,
          icon_url,
          targetOSVersionGroup,
          active,
          dailySpend,
          email,
          installs,
          spent,
        } = ad;
        // const [nC, nI] = [Number(totalClick), Number(totalImpression)];
        // const [nSpend, nInstalls] = [Number(totalCPI), Number(totalInstall)];
        // const displayClicks = nC > nI ? nI : nC;
        // const displayImpressions = nI;
        // const ctr = +((nC / nI) * 100);
        const cpi = installs === 0 ? 0 : +((spent/installs));
        return (
          <tr key={id || 100}>
            <td>
                <Switch
                  checked={active === true}
                  onChange={() => adStatusOnChange(id, !active)}
                  onColor="#00d1b2"
                  onHandleColor="#00d1b2"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  className="react-switch"
                  id="material-switch"
                  data-cy="adSwitch"
                />
            </td>
            <td>{email}</td>
            <td data-cy={i === arr.length - 1 ? 'adid' : null}>
              {id}
            </td>
            <td>{advertiserID}</td>
            <td>
                <React.Fragment>
                  {name}&nbsp;&nbsp;
                  <AdsTableButton
                    onClick={() => onEditButtonClick(id)}
                    data-cy="adEditBtn"
                    margin="0 0 0 2%"
                  >
                    <FaEdit />
                  </AdsTableButton>
                  <AdsTableButton
                    onClick={() => onCloneButtonClick(id)}
                    data-cy="adCloneBtn"
                  >
                    <FaRegCopy />
                  </AdsTableButton>
                </React.Fragment>
            </td>
            <td data-cy={i === arr.length - 1 ? 'oIconURL' : null} style={{padding: 2}}>
              <img src={icon_url} style={{ height: 50, margin: 0 }} alt={name} />
            </td>
            <td data-cy={i === arr.length - 1 ? 'oBundleID' : null}>
              {bundleID}
            </td>
            <td data-cy={i === arr.length -1 ? 'oTargetOSVersionGroup' : null}>
              {targetOSVersionGroup}
            </td>
            {/* <td data-cy={i === arr.length - 1 ? 'oImp' : null}>
              {displayImpressions}
            </td>
            <td data-cy={i === arr.length - 1 ? 'oClk' : null}>{displayClicks}</td>
            <td data-cy={i === arr.length - 1 ? 'oCTR' : null}>
              {ctr > 100 || ctr <= 0 || !isFinite(ctr) ? 0 : ctr.toFixed(2)}%
            </td> */}
            <td data-cy={i === arr.length - 1 ? 'oSpt' : null}>
              ${spent}
            </td>
            <td data-cy={i === arr.length - 1 ? 'oDB' : null}>${+dailySpend}</td>
            <td data-cy={i === arr.length - 1 ? 'oInstalls' : null}>
              {installs}
            </td>
            <td data-cy={i === arr.length - 1 ? 'oCPI' : null}>
              {content !== 'Overall Total Number'
                ? <React.Fragment>${cpi.toFixed(4)}</React.Fragment>
                : <React.Fragment>-</React.Fragment>
              }
            </td>
          </tr>
        );
      })}
    </tbody>
  </Table>
);

SponsoredTable.propTypes = {
  ads: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      id: PropTypes.number,
      advertiserID: PropTypes.number,
      name: PropTypes.string,
      dailySpend: PropTypes.string,
      cpi: PropTypes.string,
    })
  ),
  adStatusOnChange: PropTypes.func,
  onCloneButtonClick: PropTypes.func,
  onEditButtonClick: PropTypes.func,
};

SponsoredTable.defaultProps = {
  ads: [],
  adStatusOnChange: null,
  onCloneButtonClick: () => {},
  onEditButtonClick: () => {},
  onAllUserMode: false,
};

export default SponsoredTable;
