import React from 'react';
import PropTypes from 'prop-types';
import { FaPlus } from 'react-icons/fa';
import CreateButtonContainer from './CreateButtonContainer';
import Text from '../../../../../core/components/Text';

const CreateButton = ({ onClick }) => (
  <CreateButtonContainer onClick={onClick} data-cy="createBtn">
    <FaPlus />
    <Text fontColor="#fff" fontWeight="bold">
      Create
    </Text>
  </CreateButtonContainer>
);

CreateButton.propTypes = {
  onClick: PropTypes.func,
};

CreateButton.defaultProps = {
  onClick: null,
};

export default CreateButton;
