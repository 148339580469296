import React from 'react';
import PropTypes from 'prop-types';
import PaginationButton from './PaginationButton';
import FlexBox from '../../../../../core/components/FlexBox';
import Text from '../../../../../core/components/Text';

const Pagination = ({ currentPage, totalPages, onClick }) => (
  <FlexBox justifyContent="space-between">
    <FlexBox width="auto">
      <PaginationButton onClick={() => onClick(1)} data-cy="firstBtn">
        First
      </PaginationButton>
      <PaginationButton
        onClick={() => (currentPage > 1 ? onClick(currentPage - 1) : null)}
        data-cy="backBtn"
      >
        &lt;
      </PaginationButton>
    </FlexBox>
    <Text data-cy="navPage">{`Page ${currentPage} of ${totalPages}`}</Text>
    <FlexBox width="auto">
      <PaginationButton
        onClick={() => (currentPage < totalPages ? onClick(currentPage + 1) : null)}
        data-cy="nextBtn"
      >
        &gt;
      </PaginationButton>
      <PaginationButton onClick={() => onClick(totalPages)} data-cy="lastBtn">
        Last
      </PaginationButton>
    </FlexBox>
  </FlexBox>
);

Pagination.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  onClick: PropTypes.func,
};

Pagination.defaultProps = {
  currentPage: 0,
  totalPages: 0,
  onClick: null,
};

export default Pagination;
