import React from 'react';
import { Link } from 'react-router-dom';
import Text from '../../../../core/components/Text';
import FlexBox from '../../../../core/components/FlexBox';

const LoginTag = () => (
  <FlexBox margin="10px auto" data-cy="belowFormText">
    <Text fontSize="1">
      Already have an account?&nbsp;
      <Link to="/login" data-cy="loginPageLink">
        Please click here to log in
      </Link>
    </Text>
  </FlexBox>
);

export default LoginTag;
