import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Verbiage from './components/Verbiage';
import RegisterForm from './components/RegisterForm';
import LoginMessage from './components/LoginTag';
import FlexBox from '../../../core/components/FlexBox';
import { signup, reset } from '../../../redux/auth/authSlice';

export const RegisterPage = ({ error, signUpAction, resetAction }) => {
  useEffect(() => {
    return () => {
      resetAction();
    };
  }, [resetAction]);

  const formOnSubmitHandler = (value) => {
    const { email, password } = value;
    signUpAction(email, password);
  };

  return (
    <FlexBox padding="5% 0" flexDirection="column">
      <Verbiage
        title="Welcome!"
        subTitle="You are now using Kai Advertisement service"
        testId="welcomeMsg"
      />
      <Verbiage
        title="Advertiser Dashboard"
        subTitle="Sign up for an account"
        color="#7a7a7a"
        testId="aboveFormText"
      />
      <RegisterForm onSubmit={formOnSubmitHandler} error={error} />
      <LoginMessage />
    </FlexBox>
  );
};

RegisterPage.propTypes = {
  error: PropTypes.string,
  signUpAction: PropTypes.func,
  resetAction: PropTypes.func,
};

RegisterPage.defaultProps = {
  error: '',
  signUpAction: null,
  resetAction: null,
};

const mapStateToProps = (state) => ({
  error: state.auth.error,
});

const mapDispatchToProps = (dispatch) => ({
  signUpAction: (email, password) => dispatch(signup(email, password)),
  resetAction: () => dispatch(reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
