import React from 'react';
import FooterContainer from './FooterContainer';
import FooterLink from './FooterLink';

const Footer = () => (
  <FooterContainer>
    <FooterLink
      href="https://www.kaiostech.com/terms-of-service/"
      target="_blank"
      data-cy="terms"
    >
      Terms
    </FooterLink>
    <FooterLink
      href="https://www.kaiostech.com/privacy-policy/"
      target="_blank"
      data-cy="privacy"
    >
      Privacy
    </FooterLink>
  </FooterContainer>
);

export default Footer;
