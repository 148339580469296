import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';

const DropdownButton = styled(Dropdown.Toggle)`
  border: 1px solid #b5b5b5;
  min-width: 140px;
  max-width: 450px;
  margin-left: 10px;

  @media (max-width: 1200px) {
    width: 300px;
  }

  @media (max-width: 767px) {
    margin-left: 0px;
    margin-top: 2px;
    width: 300px;
  }
`;
export default DropdownButton;
