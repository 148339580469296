import React from 'react';
import styled from 'styled-components';

const Star = () => <S color="red">*</S>;

const S = styled.span`
  color: red;
`;

export default Star;
