import React from 'react';
import PayPalLoadingContainer from './PayPalLoadingContainer';
import Text from '../../../../../core/components/Text';

const PaypalLoading = () => (
  <PayPalLoadingContainer data-cy="ppBtnLoading">
    <Text fontWeight="extra-bold" fontColor="#0D3182">
      Payment Processing...
    </Text>
  </PayPalLoadingContainer>
);

export default PaypalLoading;
