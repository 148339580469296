import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../../core/components/Text';
import FlexBox from '../../../../core/components/FlexBox';

const Verbiage = ({ title, subTitle, color, testId }) => (
  <FlexBox
    flexDirection="column"
    alignItems="center"
    margin="15px auto"
    data-cy={testId}
  >
    <Text fontColor={color || '#000'} fontSize="2" fontWeight="bold">
      {title}
    </Text>
    <Text fontColor={color || '#000'} fontSize="1.25">
      {subTitle}
    </Text>
  </FlexBox>
);

Verbiage.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  testId: PropTypes.string,
};

Verbiage.defaultProps = {
  color: '',
  title: '',
  subTitle: '',
  testId: '',
};

export default Verbiage;
