import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import TopUpForm from './component/TopUpForm';
import Text from '../../../core/components/Text';
import { getAdvertisers } from '../../../redux/advertisers/advertiserSlice';

export const TopUpPage = ({ advertisers, adminToken, getAdvertisersAction }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState();
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(advertisers[0]);
  const topUpFormSubmitHandler = useCallback(
    (values) => {
      const { email, amount } = values;
      setSuccess('');
      setError('');
      const formData = new FormData();
      formData.append('email', email);
      formData.append('availableBudget', amount);
      axios({
        url: `${process.env.REACT_APP_DSP_API}/admin/user`,
        method: 'PUT',
        headers: {
          Authorization: `Basic ${adminToken}`,
        },
        data: formData,
      })
        .then((res) => {
          const { error } = res['data'];
          if (error) {
            throw new Error(error);
          } else {
            const { data } = res['data'];
            const { email, availableBudget } = data;
            setSuccess(`${email} now has $${Number(availableBudget).toFixed(2)}`);
            setTimeout(() => getAdvertisersAction(adminToken), 800);
          }
        })
        .catch((err) => {
          const error = String(err).split(': ')[1];
          setError(error);
        });
    },
    [adminToken, getAdvertisersAction]
  );

  return (
    <React.Fragment>
      <Text fontSize="2" fontColor="#7a7a7a" fontWeight="bold" data-cy="title">
        User account top-up
      </Text>
      <TopUpForm
        onSubmit={topUpFormSubmitHandler}
        advertisers={advertisers}
        error={error}
        success={success}
        selectedAdvertiser={selectedAdvertiser}
        dropdownOnSelect={(advertiser) => setSelectedAdvertiser(advertiser)}
      />
    </React.Fragment>
  );
};

TopUpPage.propTypes = {
  getAdvertisersAction: PropTypes.func,
  advertisers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      availableBudget: PropTypes.string,
      compId: PropTypes.string,
    })
  ),
  adminToken: PropTypes.string,
};

TopUpPage.defaultProps = {
  getAdvertisersAction: null,
  advertisers: [],
  adminToken: '',
};

const mapStateToProps = (state) => ({
  adminToken: state.auth.token,
  advertisers: state.advertiser.advertisers,
});

const mapDispatchToProps = (dispatch) => ({
  getAdvertisersAction: (adminToken) => dispatch(getAdvertisers(adminToken)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopUpPage);
