import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import ContactTitleDropDown from '../../../../../core/components/Form/ContactTitleDropDown';
import ContactTextInput from '../../../../../core/components/Form/ContactTextInput';
import ContactNumberInput from '../../../../../core/components/Form/ContactNumberInput';
import FormButton from '../../../../../core/components/Form/FormButton';
import FormContainer from '../../../../../core/components/Form/FormContainer';
import ErrorMessage from '../../../../../core/components/Form/ErrorMessage';
import SuccessMessage from '../../../../../core/components/Form/SuccessMessage';
import FlexBox from '../../../../../core/components/FlexBox';
import Text from '../../../../../core/components/Text';
import Star from '../../../../../core/components/Star';
import { ContactPersonTitle } from '../../../../../core/utility/contactPersonType';

const ProfileForm = ({ error, onSubmit, success, loading }) => {
  const [bcTouched, setBCTouched] = useState(false);
  const [fcTouched, setFCTouched] = useState(false);
  const validationSchema = Yup.object().shape({
    englishName: Yup.string()
      .max(30, '*English Name must be less than 100 characters')
      .required('*English Name is required'),
    chineseName: Yup.string().max(
      20,
      '*Chinese Name must be less than 20 characters'
    ),
    registeredAddress: Yup.string()
      .max(200, '*Addres must be less than 200 characters')
      .required('*Registered Address is required'),
    correspondenceAddress: Yup.string().max(
      200,
      '*Addres must be less than 200 characters'
    ),
    brNumber: Yup.string(),
    deliveryTerms: Yup.string(),
    taxCode: Yup.string(),
    country: Yup.string().required('*Country is required'),
    bcTitle: Yup.string()
      .oneOf(ContactPersonTitle, '*Title must be Mr, Ms or Mrs')
      .required('*Title is required'),
    bcName: Yup.string().required('*Name is required'),
    bcCountryCode: Yup.string()
      .matches(/^(0|[1-9][0-9]*)$/gi, '*Country Code must be number')
      .required('*Country Code is required'),
    bcAreaCode: Yup.string().matches(
      /^(0|[1-9][0-9]*)$/gi,
      '*Area Code must be number'
    ),
    bcNumber: Yup.string()
      .matches(/^(0|[1-9][0-9]*)$/gi, '*Telephone number must be number')
      .required('*Telephone Number is required'),
    bcEmail: Yup.string()
      .email('*Email must be a valid email address')
      .required('*Email is required'),
    fcTitle: Yup.string()
      .oneOf(ContactPersonTitle, '*Title must be Mr, Ms or Mrs')
      .required('*Title is required'),
    fcName: Yup.string().required('*Name is required'),
    fcCountryCode: Yup.string()
      .matches(/^(0|[1-9][0-9]*)$/gi, '*Country Code must be number')
      .required('*Country Code is required'),
    fcAreaCode: Yup.string().matches(
      /^(0|[1-9][0-9]*)$/gi,
      '*Area Code must be number'
    ),
    fcNumber: Yup.string()
      .matches(/^(0|[1-9][0-9]*)$/gi, '*Telephone number must be number')
      .required('*Telephone Number is required'),
    fcEmail: Yup.string()
      .email('*Email must be a valid email address')
      .required('*Email is required'),
  });

  const touchFC = useCallback(() => !fcTouched && setFCTouched(true), [
    fcTouched,
    setFCTouched,
  ]);

  const touchBC = useCallback(() => !bcTouched && setBCTouched(true), [
    bcTouched,
    setBCTouched,
  ]);

  return (
    <Formik
      initialValues={{
        englishName: '',
        chineseName: '',
        registeredAddress: '',
        correspondenceAddress: '',
        brNumber: '',
        deliveryTerms: '',
        taxCode: '',
        country: '',
        bcTitle: ContactPersonTitle[0],
        bcName: '',
        bcCountryCode: '',
        bcAreaCode: '',
        bcNumber: '',
        fcTitle: ContactPersonTitle[0],
        fcName: '',
        fcCountryCode: '',
        fcAreaCode: '',
        fcNumber: '',
        same: 1,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <FormContainer width="100%">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formEngName">
              <Form.Label>
                <Star />
                Company Name (In English)
              </Form.Label>
              <Form.Control
                type="text"
                name="englishName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.englishName}
                isInvalid={touched.englishName && errors.englishName}
                data-cy="compNameEn"
              />
              <Form.Control.Feedback type="invalid" data-cy="compNameEn-error">
                {errors.englishName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formChName">
              <Form.Label>Company Name (In Chinese)</Form.Label>
              <Form.Control
                type="text"
                name="chineseName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.chineseName}
                isInvalid={touched.chineseName && errors.chineseName}
                data-cy="compNameZh"
              />
              <Form.Control.Feedback type="invalid">
                {errors.chineseName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formRAddress">
              <Form.Label>
                <Star />
                Registered Address
              </Form.Label>
              <Form.Control
                type="text"
                name="registeredAddress"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.registeredAddress}
                isInvalid={touched.registeredAddress && errors.registeredAddress}
                data-cy="regAdd"
              />
              <Form.Control.Feedback type="invalid" data-cy="regAdd-error">
                {errors.registeredAddress}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBRNumber">
              <Form.Label>Correspondence Address</Form.Label>
              <Form.Control
                type="text"
                name="correspondenceAddress"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.correspondenceAddress}
                isInvalid={
                  touched.correspondenceAddress && errors.correspondenceAddress
                }
                data-cy="corAdd"
              />
              <Form.Control.Feedback type="invalid">
                {errors.correspondenceAddress}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBRNumber">
              <Form.Label>Business Registration Number</Form.Label>
              <Form.Control
                type="text"
                name="brNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.brNumber}
                isInvalid={touched.brNumber && errors.brNumber}
                data-cy="bizRegNum"
              />
              <Form.Control.Feedback type="invalid">
                {errors.brNumber}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formDeliveryTerms">
              <Form.Label>Delivery Terms</Form.Label>
              <Form.Control
                type="text"
                name="deliveryTerms"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.deliveryTerms}
                isInvalid={touched.deliveryTerms && errors.deliveryTerms}
                data-cy="deliTerms"
              />
              <Form.Control.Feedback type="invalid">
                {errors.deliveryTerms}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formTaxCode">
              <Form.Label>Tax Code / Tax Registration Number</Form.Label>
              <Form.Control
                type="text"
                name="taxCode"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.taxCode}
                isInvalid={touched.taxCode && errors.taxCode}
                data-cy="tax"
              />
              <Form.Control.Feedback type="invalid">
                {errors.taxCode}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formCountry">
              <Form.Label>
                <Star />
                Country
              </Form.Label>
              <Form.Control
                type="text"
                name="country"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.country}
                isInvalid={touched.country && errors.country}
                data-cy="country"
              />
              <Form.Control.Feedback type="invalid" data-cy="country-error">
                {errors.country}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBC">
              <Form.Label>Business Contact Person</Form.Label>
              <ContactTitleDropDown
                options={ContactPersonTitle}
                title={values.bcTitle}
                dropdownID="bcTitleDropdown"
                onSelect={(id) => {
                  if (values.same === 2) {
                    setFieldValue('fcTitle', ContactPersonTitle[id]);
                  }
                  touchBC();
                  setFieldValue('bcTitle', ContactPersonTitle[id]);
                }}
                dropdownTestId="bizTitle"
              />
              <ContactTextInput
                compulsory
                label="Name"
                onChange={(e) => {
                  if (values.same === 2) {
                    setFieldValue('fcName', e.target.value);
                  }
                  touchBC();
                  setFieldValue('bcName', e.target.value);
                }}
                value={values.bcName}
                inputTestId="bizName"
              />
              <ContactNumberInput
                countryCode={values.bcCountryCode}
                areaCode={values.bcAreaCode}
                number={values.bcNumber}
                countryCodeOnChange={(e) => {
                  if (values.same === 2) {
                    setFieldValue('fcCountryCode', e.target.value);
                  }
                  touchBC();
                  setFieldValue('bcCountryCode', e.target.value);
                }}
                areaCodeOnChange={(e) => {
                  if (values.same === 2) {
                    setFieldValue('fcAreaCode', e.target.value);
                  }
                  touchBC();
                  setFieldValue('bcAreaCode', e.target.value);
                }}
                numberOnChange={(e) => {
                  if (values.same === 2) {
                    setFieldValue('fcNumber', e.target.value);
                  }
                  touchBC();
                  setFieldValue('bcNumber', e.target.value);
                }}
                countryCodeTestId="bcnCCode"
                areaCodeTestId="bcnACode"
                telTestId="bcnTel"
              />
              <ContactTextInput
                compulsory
                label="Email"
                onChange={(e) => {
                  if (values.same === 2) {
                    setFieldValue('fcEmail', e.target.value);
                  }
                  touchBC();
                  setFieldValue('bcEmail', e.target.value);
                }}
                value={values.bcEmail}
                inputTestId="bizEmail"
              />
              <ErrorMessage data-cy="biz-error">
                {bcTouched &&
                  (errors.bcName ||
                    errors.bcCountryCode ||
                    errors.bcAreaCode ||
                    errors.bcNumber ||
                    errors.bcEmail)}
              </ErrorMessage>
            </Form.Group>
            <Form.Group controlId="formFC">
              <Form.Label>Financial Team Contact Person</Form.Label>
              <FlexBox justifyContent="flex-start">
                <Form.Check
                  onChange={() => {
                    const same = values.same === 1 ? 2 : 1;
                    setFieldValue('same', same);
                    setFieldValue(
                      'fcTitle',
                      same === 2 ? values.bcTitle : ContactPersonTitle[0]
                    );
                    setFieldValue('fcName', same === 2 ? values.bcName : '');
                    setFieldValue(
                      'fcCountryCode',
                      same === 2 ? values.bcCountryCode : ''
                    );
                    setFieldValue('fcAreaCode', same === 2 ? values.bcAreaCode : '');
                    setFieldValue('fcNumber', same === 2 ? values.bcNumber : '');
                    setFieldValue('fcEmail', same === 2 ? values.bcEmail : '');
                  }}
                  data-cy="bizFinCheck"
                />
                <Text>
                  Click here if the contact is the same as Business Contact Person
                </Text>
              </FlexBox>
              <ContactTitleDropDown
                options={ContactPersonTitle}
                title={values.fcTitle}
                dropdownID="fcTitleDropdown"
                onSelect={(id) => {
                  touchFC();
                  setFieldValue('fcTitle', ContactPersonTitle[id]);
                }}
                disabled={values.same === 2}
                dropdownTestId="finTitle"
              />
              <ContactTextInput
                compulsory
                label="Name"
                onChange={(e) => {
                  touchFC();
                  setFieldValue('fcName', e.target.value);
                }}
                value={values.fcName}
                disabled={values.same === 2}
                inputTestId="finName"
              />
              <ContactNumberInput
                countryCode={values.fcCountryCode}
                areaCode={values.fcAreaCode}
                number={values.fcNumber}
                countryCodeOnChange={(e) => {
                  touchFC();
                  setFieldValue('fcCountryCode', e.target.value);
                }}
                areaCodeOnChange={(e) => {
                  touchFC();
                  setFieldValue('fcAreaCode', e.target.value);
                }}
                numberOnChange={(e) => {
                  touchFC();
                  setFieldValue('fcNumber', e.target.value);
                }}
                disabled={values.same === 2}
                countryCodeTestId="fcnCCode"
                areaCodeTestId="fcnACode"
                telTestId="fcnTel"
              />
              <ContactTextInput
                compulsory
                label="Email"
                onChange={(e) => {
                  touchFC();
                  setFieldValue('fcEmail', e.target.value);
                }}
                value={values.fcEmail}
                disabled={values.same === 2}
                inputTestId="finEmail"
              />
              <ErrorMessage data-cy="fin-error">
                {fcTouched &&
                  values.same === 1 &&
                  (errors.fcName ||
                    errors.fcCountryCode ||
                    errors.fcAreaCode ||
                    errors.fcNumber ||
                    errors.fcEmail)}
              </ErrorMessage>
            </Form.Group>
            <FlexBox margin="10px auto">
              <Text>
                <Star />
                Compulsory Field
              </Text>
            </FlexBox>
            <FlexBox>
              <FormButton
                type="submit"
                disabled={
                  isSubmitting ||
                  (!_.isEmpty(errors) && values.same === 1) ||
                  (values.same === 2 &&
                    (errors.bcCountryCode ||
                      errors.bcName ||
                      errors.bcNumber ||
                      errors.areaCode ||
                      errors.bcEmail ||
                      errors.englishName ||
                      errors.registeredAddress ||
                      errors.country)) ||
                  !Object.keys(touched).length ||
                  loading
                }
                onClick={handleSubmit}
                data-cy="submitBtn"
              >
                Submit
              </FormButton>
            </FlexBox>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {success && (
              <SuccessMessage margin="5px auto" fontColor="red">
                {success}
              </SuccessMessage>
            )}
          </Form>
        </FormContainer>
      )}
    </Formik>
  );
};

ProfileForm.propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  success: PropTypes.string,
  loading: PropTypes.bool,
};
ProfileForm.defaultProps = {
  onSubmit: null,
  error: '',
  success: '',
  loading: false,
};

export default ProfileForm;
