import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import AdvertisersDropdownContainer from './AdvertisersDropdownContainer';
import AdvertisersDropdownButton from './AdvertisersDropdownButton';

const AdvertisersDropdown = ({ options, title, dropdownID, onSelect, disabled }) => (
  <AdvertisersDropdownContainer onSelect={onSelect}>
    <AdvertisersDropdownButton
      disabled={disabled}
      variant="light"
      id={dropdownID}
      data-cy="advListBtn"
    >
      {title}
    </AdvertisersDropdownButton>
    <Dropdown.Menu style={{ maxHeight: 400, overflow: 'scroll' }} data-cy="advList">
      {options.map((option, i) => (
        <Dropdown.Item eventKey={i} key={i}>
          {option}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </AdvertisersDropdownContainer>
);

AdvertisersDropdown.propTypes = {
  dropdownID: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
};

AdvertisersDropdown.defaultProps = {
  dropdownID: '',
  options: [],
  title: '',
  onSelect: null,
  disabled: false,
};

export default AdvertisersDropdown;
