import styled from 'styled-components';
import PropTypes from 'prop-types';

const WeightProps = ['light', 'normal', 'bold', 'extra-bold'];

const getFontSize = (props) => () => {
  const { fontSize } = props;
  return fontSize ? `${fontSize}rem` : '1rem';
};

const getFontWeight = (props) => () => {
  const { fontWeight } = props;
  switch (fontWeight) {
    case 'light':
      return '200';
    case 'normal':
      return '400';
    case 'bold':
      return '600';
    case 'extra-bold':
      return '800';
    default:
      return '400';
  }
};

const getFontColor = (props) => () => {
  const { fontColor } = props;
  return fontColor || '#000';
};

const getTextAlign = (props) => () => {
  const { textAlign } = props;
  return textAlign || 'center';
};

const setDefaultValue = () => 'margin: 0px;padding: 0px;border:0;';

/**
 * Personal practice - set some fixed value for align the UI of the element across the app
 * Better for project scalability
 * Can be more in-depth with more props settings but lack of time
 * Can fix the fontColor to certain values which align with the company brand book, same as font-family
 */
const Text = styled.p`
  font-size: ${getFontSize};
  font-weight: ${getFontWeight};
  color: ${getFontColor};
  text-align: ${getTextAlign};
  ${setDefaultValue}
`;

Text.propTypes = {
  fontSize: PropTypes.string,
  fontWeight: PropTypes.oneOf(WeightProps),
  fontColor: PropTypes.string,
};

export default Text;
