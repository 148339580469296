import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown as D } from 'react-bootstrap';
import DropdownButton from './DropdownButton';

const DropDown = ({
  options,
  title,
  dropdownID,
  onSelect,
  disabled,
  buttonTestId,
  menuTestId,
}) => (
  <D onSelect={onSelect}>
    <DropdownButton
      disabled={disabled}
      variant="light"
      id={dropdownID}
      data-cy={buttonTestId}
    >
      {title}
    </DropdownButton>
    <D.Menu style={{ maxHeight: 400, overflow: 'scroll' }} data-cy={menuTestId}>
      {options.map((option, i) => (
        <D.Item eventKey={i} key={i}>
          {option}
        </D.Item>
      ))}
    </D.Menu>
  </D>
);

DropDown.propTypes = {
  dropdownID: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  buttonTestId: PropTypes.string,
  menuTestId: PropTypes.string,
};

DropDown.defaultProps = {
  dropdownID: '',
  options: [],
  title: '',
  onSelect: null,
  disabled: false,
  buttonTestId: '',
  menuTestId: '',
};

export default DropDown;
