import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import AdvertisersDropdown from './AdvertisersDropdown';
import FormContainer from '../../../../core/components/Form/FormContainer';
import FormField from '../../../../core/components/Form/FormField';
import FormButton from '../../../../core/components/Form/FormButton';
import ErrorMessage from '../../../../core/components/Form/ErrorMessage';
import SuccessMessage from '../../../../core/components/Form/SuccessMessage';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('*Must be a valid email address')
    .max(100, '*Email must be less than 100 characters')
    .required('*Email is required'),
  amount: Yup.number()
    .min(1)
    .required('*Top up amount is required'),
});

const TopUpForm = ({
  advertisers,
  error,
  onSubmit,
  success,
  selectedAdvertiser,
  dropdownOnSelect,
}) => (
  <Formik
    initialValues={{ email: selectedAdvertiser['name'], amount: '' }}
    validationSchema={validationSchema}
    onSubmit={(values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      onSubmit(values);
      resetForm();
      setSubmitting(false);
    }}
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      setFieldValue,
    }) => (
      <FormContainer width="40%">
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formEmail">
            <Form.Label>Advertiser Account</Form.Label>
            <AdvertisersDropdown
              options={advertisers.map(({ name }) => name)}
              title={selectedAdvertiser.name}
              dropdownID="topUpAdvertiserDropdown"
              onSelect={(i) => {
                const selected = advertisers[i];
                dropdownOnSelect(selected);
                setFieldValue('email', selected['name']);
              }}
            />
          </Form.Group>
          <Form.Group controlId="formAmount">
            <Form.Label>Remaining Balance (USD)</Form.Label>
            <FormField
              type="number"
              name="balance"
              value={selectedAdvertiser.availableBudget}
              disabled
              data-cy="reBal"
            />
          </Form.Group>
          <Form.Group controlId="formAmount">
            <Form.Label>Top-up amount (USD)</Form.Label>
            <FormField
              type="number"
              name="amount"
              placeholder="Top Up Amount"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.amount}
              isInvalid={touched.amount && errors.amount}
              data-cy="topUpAmt"
            />
            <Form.Control.Feedback type="invalid">
              {errors.amount}
            </Form.Control.Feedback>
          </Form.Group>
          <FormButton
            type="submit"
            disabled={
              isSubmitting || !_.every(Object.values(values)) || !_.isEmpty(errors)
            }
            bcolor="#651b93"
            data-cy="confirmBtn"
          >
            Confirm
          </FormButton>
        </Form>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {success && (
          <SuccessMessage margin="5px auto" fontColor="red" data-cy="confirmMsg">
            {success}
          </SuccessMessage>
        )}
      </FormContainer>
    )}
  </Formik>
);

TopUpForm.propTypes = {
  advertisers: PropTypes.array,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  success: PropTypes.string,
  selectedAdvertiser: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    availableBudget: PropTypes.string,
    compId: PropTypes.string,
  }),
  dropdownOnSelect: PropTypes.func,
};

TopUpForm.defaultProps = {
  advertisers: [],
  onSubmit: null,
  error: '',
  success: '',
  selectedAdvertiser: null,
  dropdownOnSelect: null,
};

export default TopUpForm;
