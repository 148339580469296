import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import ProfileForm from './components/ProfileForm/ProfileForm';
import PageTitle from '../../../core/components/PageTitle';
import { getRegistrationStatus } from '../../../redux/auth/authSlice';
import { ContactPersonType } from '../../../core/utility/contactPersonType';

class AdvertiserProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      success: null,
    };
  }

  sendCompanyInfoDataRequest = (obj) => {
    this.setState({ loading: true });
    const {
      name,
      nameChi,
      regAddress,
      correspondenceAddress,
      businessRegNo,
      deliveryTerm,
      taxCode,
      country,
      contacts,
    } = obj;
    const { advertiserToken, getRegistrationStatusAction } = this.props;
    const formData = new FormData();
    formData.append('name', name);
    formData.append('nameChi', nameChi);
    formData.append('regAddress', regAddress);
    formData.append('correspondenceAddress', correspondenceAddress);
    formData.append('businessRegNo', businessRegNo);
    formData.append('deliveryTerm', deliveryTerm);
    formData.append('taxCode', taxCode);
    formData.append('country', country);
    for (let i = 0; i < contacts.length; i++) {
      const {
        name,
        title,
        countryCode,
        areaCode,
        telephoneNo,
        email,
        type,
      } = contacts[i];
      formData.append(`contacts[${i}].name`, name);
      formData.append(`contacts[${i}].type`, type);
      formData.append(`contacts[${i}].title`, title);
      formData.append(`contacts[${i}].countryCode`, countryCode);
      formData.append(`contacts[${i}].areaCode`, areaCode);
      formData.append(`contacts[${i}].telephoneNo`, telephoneNo);
      formData.append(`contacts[${i}].email`, email);
    }
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_DSP_API}/dev/adv-company`,
      data: formData,
      headers: {
        Authorization: `Basic ${advertiserToken}`,
      },
    })
      .then((res) => {
        const { error } = res['data'];
        if (error) {
          throw new Error(error);
        } else {
          this.setState({ success: 'Information is successfully captured' }, () => {
            setTimeout(() => {
              this.setState({ loading: false }, () => {
                getRegistrationStatusAction(advertiserToken);
              });
            }, 800);
          });
        }
      })
      .catch((err) => {
        const error = String(err).split(': ')[1];
        this.setState({ error, loading: false });
      });
  };

  formRequestFormData = (values) => {
    const {
      englishName,
      chineseName,
      registeredAddress,
      correspondenceAddress,
      brNumber,
      deliveryTerms,
      taxCode,
      country,
      bcTitle,
      bcName,
      bcCountryCode,
      bcAreaCode,
      bcNumber,
      fcTitle,
      fcName,
      fcCountryCode,
      fcAreaCode,
      fcNumber,
      bcEmail,
      fcEmail,
    } = values;
    const obj = {
      name: englishName,
      nameChi: chineseName,
      regAddress: registeredAddress,
      correspondenceAddress,
      businessRegNo: brNumber,
      deliveryTerm: deliveryTerms,
      taxCode,
      country,
      contacts: [
        {
          name: bcName,
          title: bcTitle,
          countryCode: bcCountryCode,
          areaCode: bcAreaCode,
          telephoneNo: bcNumber,
          email: bcEmail,
          type: ContactPersonType.BUSS,
        },
        {
          name: fcName,
          title: fcTitle,
          countryCode: fcCountryCode,
          areaCode: fcAreaCode,
          telephoneNo: fcNumber,
          email: fcEmail,
          type: ContactPersonType.FINA,
        },
      ],
    };
    return obj;
  };

  formOnSubmitHandler = (values) => {
    const obj = this.formRequestFormData(values);
    this.sendCompanyInfoDataRequest(obj);
  };

  render() {
    const { error, loading, success } = this.state;
    return (
      <React.Fragment>
        <PageTitle>General Information</PageTitle>
        <ProfileForm
          error={error}
          success={success}
          loading={loading}
          onSubmit={this.formOnSubmitHandler}
        />
      </React.Fragment>
    );
  }
}

AdvertiserProfilePage.propTypes = {
  advertiserToken: PropTypes.string,
  getRegistrationStatusAction: PropTypes.func,
};

AdvertiserProfilePage.defaultProps = {
  advertiserToken: '',
  getRegistrationStatusAction: null,
};

const mapStateToProps = (state) => ({
  advertiserToken: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  getRegistrationStatusAction: (token) => dispatch(getRegistrationStatus(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvertiserProfilePage);
