import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FlexBox from '../../../core/components/FlexBox';
import Text from '../../../core/components/Text';
import FormButton from '../../../core/components/Form/FormButton';
import { logout } from '../../../redux/auth/authSlice';

export const PaypalCompletionPage = ({ logoutAction }) => {
  return (
    <FlexBox flexDirection="column">
      <FlexBox data-cy="completeText">
        <Text fontSize="3" data-cy>
          Your PayPal request has been received.
        </Text>
        <Text fontSize="3">We will contact you later.</Text>
      </FlexBox>
      <FlexBox width="30%" margin="20px auto">
        <FormButton onClick={() => logoutAction()} data-cy="rtnLoginBtn">
          Click to return to login page
        </FormButton>
      </FlexBox>
    </FlexBox>
  );
};

PaypalCompletionPage.propTypes = {
  logoutAction: PropTypes.func,
};

PaypalCompletionPage.defaultProps = {
  logoutAction: null,
};

const mapDispatchToProps = (dispatch) => ({
  logoutAction: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(PaypalCompletionPage);
