import styled from 'styled-components';

const TncContainer = styled.iframe`
  height: 700px;
  width: 816px;

  @media (max-width: 767px) {
    width: 90%;
    height: 500px;
  }
`;

export default TncContainer;
