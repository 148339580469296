import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import TableHead from './TableHead';
import TableData from './TableData';
import TableButton from './TableButton';

const AdvertisersListTable = ({ advertiserAccounts, onClick }) => (
  <Table striped bordered hover size="xs" responsive>
    <thead>
      <tr>
        <TableHead>Advertiser Account</TableHead>
        <TableHead>Company Name</TableHead>
        <TableHead>Company Country</TableHead>
        <TableHead>Business Contact Name</TableHead>
        <TableHead>Business Contact Number</TableHead>
        <TableHead>Business Contact Email</TableHead>
        <TableHead>Remaining Balance</TableHead>
        <TableHead width="150px">Edit Account</TableHead>
      </tr>
    </thead>
    <tbody>
      {advertiserAccounts.map((a, i) => {
        const {
          availableBudget,
          id,
          name,
          companyName,
          companyCountry,
          bcName,
          bcNumber,
          bcEmail,
        } = a;
        return (
          <tr key={i}>
            <TableData>{name}</TableData>
            <TableData>{companyName}</TableData>
            <TableData>{companyCountry}</TableData>
            <TableData>{bcName}</TableData>
            <TableData>{bcNumber}</TableData>
            <TableData>{bcEmail}</TableData>
            <TableData>US${availableBudget}</TableData>
            <TableData style={{ textAlign: 'center' }}>
              <TableButton
                variant="info"
                onClick={() => onClick(id)}
                data-cy="veBtn"
              >
                View and Edit
              </TableButton>
            </TableData>
          </tr>
        );
      })}
    </tbody>
  </Table>
);

AdvertisersListTable.propTypes = {
  advertiserAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      companyName: PropTypes.string,
      companyCountry: PropTypes.string,
      bName: PropTypes.string,
      bNumber: PropTypes.string,
      bEmail: PropTypes.string,
    })
  ),
  onClick: PropTypes.func,
};

AdvertisersListTable.defaultProps = {
  advertiserAccounts: [],
  onClick: null,
};

export default AdvertisersListTable;
