import styled from 'styled-components';

const PaginationButton = styled.button`
  background-color: transparent;
  border-color: #dbdbdb;
  color: #000;
  border-width: 1px;
  cursor: pointer;
  padding: 10px 15px;
  text-align: center;
  border-radius: 4px;
  font-size: 1rem;
  width: auto;
  margin: 10px;

  &:focus {
    outline: none;
  }
`;

export default PaginationButton;
