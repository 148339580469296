import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const getBackgroundColor = (props) => () => {
  const { mode } = props;
  switch (mode) {
    case 'active':
      return '#00d1b2 !important';
    case 'danger':
      return '#ff2b56 !important';
    default:
      return '#fff';
  }
};

const getFontWeight = (props) => () => {
  const { mode } = props;
  switch (mode) {
    case 'active' :
      return 'bold';
    case 'danger':
      return 'bold';
    default:
      return '250';
  }
};

const AdFormButton = styled(Button)`
  margin: 5px 10px 10px;
  background-color: ${getBackgroundColor};
  color: ${({ mode }) => (mode ? '#fff' : '#000')};
  border-color: ${({ mode }) => (mode ? 'transparent !important' : '#b5b5b5')};
  outline: none;
  font-weight: ${getFontWeight};

  &:hover,
  &:focus {
    background-color: ${getBackgroundColor};
    color: ${({ mode }) => (mode ? '#fff' : '#000')};
    border-color: ${({ mode }) => (mode ? 'transparent !important' : '#b5b5b5')};
    box-shadow: none !important;
  }
`;

export default AdFormButton;
