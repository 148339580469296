import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const FormField = styled(Form.Control)`
  background-color: #fff;
  border-color: #dbdbdb;
  color: #363636;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  max-width: 100%;
  width: 100%;
  font-size: 1.5rem;
`;

export default FormField;
