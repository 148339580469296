import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import AdvertisersListTable from './components/Table/AdvertisersListTable';
import AdvertiserEditModal from './components/Modal/AdvertiserEditModal';
import FlexBox from '../../../core/components/FlexBox';
import Text from '../../../core/components/Text';
import Spinner from '../../../core/components/Spinner';
import { ContactPersonType } from '../../../core/utility/contactPersonType';

export class AccountManagementPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      advertisersAccounts: [],
      error: false,
      loading: false,
      modalShow: false,
      selectedAdvertiser: null,
      updateSuccess: false,
      updateError: false,
      updating: false,
    };
  }

  componentDidMount = async () => {
    const advertisersAccounts = await this.getAdvertisersAccountDetails();
    const tableData = await this.formTableData(advertisersAccounts);
    this.setState({ advertisersAccounts, tableData, loading: false });
  };

  getAdvertisersAccountDetails = async () => {
    try {
      this.setState({ loading: true });
      const { adminToken } = this.props;
      const res = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_DSP_API}/admin/user/advertisers_accounts/all`,
        headers: {
          Authorization: `Basic ${adminToken}`,
        },
      });
      const { data, error } = res['data'];
      if (error) {
        throw new Error(error);
      }
      const advertisersAccounts = await this.formContactsData(data);
      return advertisersAccounts;
    } catch (err) {
      const error = String(err).split(': ')[1];
      this.setState({ error, loading: false });
      return [];
    }
  };

  formContactsData = (accounts) => {
    const convertedData = accounts.map((e) => {
      const obj = {
        bcName: '',
        bcTitle: '',
        bcCountryCode: '',
        bcAreaCode: '',
        bcNumber: '',
        bcEmail: '',
        fcName: '',
        fcTitle: '',
        fcCountryCode: '',
        fcAreaCode: '',
        fcNumber: '',
        fcEmail: '',
      };
      const { companyContacts } = e;
      if (!companyContacts) {
        return { ...e, ...obj };
      } else {
        for (let c of companyContacts) {
          const { name, type, title, countryCode, areaCode, telephoneNo, email } = c;
          if (type === ContactPersonType.BUSS) {
            obj['bcName'] = name;
            obj['bcTitle'] = title;
            obj['bcCountryCode'] = countryCode;
            obj['bcAreaCode'] = areaCode;
            obj['bcNumber'] = telephoneNo;
            obj['bcEmail'] = email;
          } else if (type === ContactPersonType.FINA) {
            obj['fcName'] = name;
            obj['fcTitle'] = title;
            obj['fcCountryCode'] = countryCode;
            obj['fcAreaCode'] = areaCode;
            obj['fcNumber'] = telephoneNo;
            obj['fcEmail'] = email;
          }
        }
        delete e.companyContacts;
        return { ...e, ...obj };
      }
    });
    return convertedData;
  };

  formTableData = (advertisers) => {
    const tableData = advertisers.map((e) => {
      const {
        availableBudget,
        id,
        name,
        companyEnglishName,
        companyCountry,
        compId,
        bcName,
        bcCountryCode,
        bcAreaCode,
        bcNumber,
        bcEmail,
      } = e;
      return {
        availableBudget,
        id,
        name,
        companyName: companyEnglishName,
        companyCountry,
        compId,
        bcName,
        bcNumber: bcNumber
          ? `+${bcCountryCode} - ${bcAreaCode || ''}${bcNumber}`
          : '',
        bcEmail,
      };
    });
    return tableData;
  };

  editButtonOnClick = (selectedID) => {
    const { advertisersAccounts } = this.state;
    const selectedAdvertiser = advertisersAccounts.filter(
      ({ id }) => id === selectedID
    )[0];
    this.setState({ selectedAdvertiser, modalShow: true });
  };

  saveChangesOnClick = (values, isEditMode) => {
    if (isEditMode) {
      this.editExistingCompanyInfo(values);
    } else {
      this.createNewCompanyInfo(values);
    }
  };

  editExistingCompanyInfo = (values) => {
    this.setState({ updating: true, updateError: false });
    const { adminToken } = this.props;
    const { selectedAdvertiser } = this.state;
    const { compId } = selectedAdvertiser;
    const reqBody = {
      name: values.englishName,
      nameChi: values.chineseName,
      regAddress: values.registeredAddress,
      correspondenceAddress: values.correspondenceAddress,
      businessRegNo: values.brNumber,
      deliveryTerm: values.deliveryTerms,
      taxCode: values.taxCode,
      country: values.country,
      Contacts: [
        {
          name: values.bcName,
          advcompid: compId,
          type: ContactPersonType.BUSS,
          title: values.bcTitle,
          countryCode: values.bcCountryCode,
          areaCode: values.bcAreaCode,
          telephoneNo: values.bcNumber,
          email: values.bcEmail,
        },
        {
          name: values.fcName,
          advcompid: compId,
          type: ContactPersonType.FINA,
          title: values.fcTitle,
          countryCode: values.fcCountryCode,
          areaCode: values.fcAreaCode,
          telephoneNo: values.fcNumber,
          email: values.fcEmail,
        },
      ],
    };

    axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_DSP_API}/admin/user/advertisers_account/${compId}`,
      headers: {
        Authorization: `Basic ${adminToken}`,
      },
      data: reqBody,
    })
      .then(async (res) => {
        const { error } = res['data'];
        if (error) {
          throw new Error(error);
        }
        this.setState({
          updateSuccess: true,
          advertisersAccounts: [],
        });
        setTimeout(() => {
          const { data } = res['data'];
          const advertisersAccounts = this.formContactsData(data);
          const tableData = this.formTableData(advertisersAccounts);
          this.setState({
            modalShow: false,
            updateSuccess: false,
            advertisersAccounts: [].concat(advertisersAccounts),
            tableData,
            loading: false,
            selectedAdvertiser: null,
            updating: false,
          });
        }, 1000);
      })
      .catch(() => {
        this.setState({ updateError: true, updating: false });
      });
  };

  createNewCompanyInfo = (values) => {
    this.setState({ updating: true, updateError: false });
    const { adminToken } = this.props;
    const { selectedAdvertiser } = this.state;
    const { id } = selectedAdvertiser;
    const reqBody = {
      name: values.englishName,
      nameChi: values.chineseName,
      regAddress: values.registeredAddress,
      correspondenceAddress: values.correspondenceAddress,
      businessRegNo: values.brNumber,
      deliveryTerm: values.deliveryTerms,
      taxCode: values.taxCode,
      country: values.country,
      Contacts: [
        {
          name: values.bcName,
          type: ContactPersonType.BUSS,
          title: values.bcTitle,
          countryCode: values.bcCountryCode,
          areaCode: values.bcAreaCode,
          telephoneNo: values.bcNumber,
          email: values.bcEmail,
        },
        {
          name: values.fcName,
          type: ContactPersonType.FINA,
          title: values.fcTitle,
          countryCode: values.fcCountryCode,
          areaCode: values.fcAreaCode,
          telephoneNo: values.fcNumber,
          email: values.fcEmail,
        },
      ],
    };
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_DSP_API}/admin/user/advertisers_account/${id}`,
      data: reqBody,
      headers: {
        Authorization: `Basic ${adminToken}`,
      },
    })
      .then((res) => {
        const { error } = res['data'];
        if (error) {
          throw new Error(error);
        }
        this.setState({
          updateSuccess: true,
          advertisersAccounts: [],
        });
        setTimeout(() => {
          const { data } = res['data'];
          const advertisersAccounts = this.formContactsData(data);
          const tableData = this.formTableData(advertisersAccounts);
          this.setState({
            modalShow: false,
            updateSuccess: false,
            advertisersAccounts: [].concat(advertisersAccounts),
            tableData,
            loading: false,
            selectedAdvertiser: null,
            updating: false,
          });
        }, 1000);
      })
      .catch(() => {
        this.setState({ updateError: true, updating: false });
      });
  };

  modalOnCloseHandler = () => {
    this.setState({
      modalShow: false,
      selectedAdvertiser: null,
      updateError: false,
    });
  };

  render() {
    const {
      modalShow,
      selectedAdvertiser,
      tableData,
      loading,
      updateSuccess,
      updateError,
      updating,
    } = this.state;
    return (
      <FlexBox>
        <Text fontWeight="bold" fontSize="2.5" data-cy="title">
          Advertisers Account Management
        </Text>
        <FlexBox margin="2% auto">
          {loading ? (
            <Spinner />
          ) : (
            <React.Fragment>
              <AdvertisersListTable
                advertiserAccounts={tableData}
                onClick={this.editButtonOnClick}
              />
              {selectedAdvertiser && (
                <AdvertiserEditModal
                  show={modalShow}
                  handleClose={this.modalOnCloseHandler}
                  selectedAdvertiser={selectedAdvertiser}
                  onSave={this.saveChangesOnClick}
                  updateSuccess={updateSuccess}
                  updateError={updateError}
                  updating={updating}
                />
              )}
            </React.Fragment>
          )}
        </FlexBox>
      </FlexBox>
    );
  }
}

AccountManagementPage.propTypes = {
  adminToken: PropTypes.string,
};

AccountManagementPage.defaultProps = {
  adminToken: '',
};

const mapStateToProps = (state) => ({
  adminToken: state.auth.token,
});

export default connect(mapStateToProps)(AccountManagementPage);
