import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import advertiserSlice from './advertisers/advertiserSlice';
import campaignSlice from './campaign/campaignSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  advertiser: advertiserSlice,
  campaign: campaignSlice,
});

export const store = configureStore({
  reducer: rootReducer,
});
