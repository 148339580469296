import styled from 'styled-components';

const AdsTableButton = styled.button`
  float: right;
  padding: 0;
  border: none;
  margin: ${({ margin }) => margin || 0};
  background-color: #fff;
`;

export default AdsTableButton;
