import styled from 'styled-components';
import FlexBox from '../FlexBox';

const ContactInputContainer = styled(FlexBox)`
  width: 100%;
  justify-content: flex-start;
  margin: 10px 0px 10px 5px;
`;

export default ContactInputContainer;
