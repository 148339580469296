import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import PropTypes from 'prop-types';
import HeaderContainer from './components/Header/HeaderContainer';
import HeaderFiltersContainer from './components/Header/HeaderFiltersContainer';
import SummaryBox from './components/SummaryBox/SummaryBox';
import FlexBox from '../../../core/components/FlexBox';
import Text from '../../../core/components/Text';
import Dropdown from '../../../core/components/Dropdown/Dropdown';
import Spinner from '../../../core/components/Spinner';
import { setSelectedAdvertiser } from '../../../redux/advertisers/advertiserSlice';

export class AdsDashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment()
        .subtract(7, 'days')
        .startOf('day'),
      endDate: moment(),
      error: false,
      graphData: {
        impressions: [],
        clicks: [],
        cpms: [],
        ctrs: [],
      },
      averageCtr: 0,
      totalClicks: 0,
      totalImpressions: 0,
      totalSpend: 0,
      dataToShow: 'impressions',
      dateRange: [],
      graphHeader: 'Impressions',
      advertiserOptions: [],
      loading: false,
    };
  }

  componentDidMount = () => {
    this.formAdvertiserDropdownOptions();
    this.retrieveGraphData();
  };

  componentDidUpdate = (prevProps) => {
    const { selectedAdvertiser } = this.props;
    if (prevProps['selectedAdvertiser']['id'] !== selectedAdvertiser['id']) {
      this.retrieveGraphData();
    }
  };

  formAdvertiserDropdownOptions = () => {
    const { advertisers } = this.props;
    const advertiserOptions = advertisers.map(({ name }) => name);
    this.setState({ advertiserOptions });
  };

  retrieveGraphData = () => {
    this.setState({ loading: true });
    const { startDate, endDate } = this.state;
    const { adminToken, selectedAdvertiser } = this.props;
    const { id } = selectedAdvertiser;
    const fromDate = startDate
      .clone()
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    const toDate = endDate
      .clone()
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_DSP_API}/admin/stat`,
      params: {
        userId: id,
        fromDate: fromDate,
        toDate: toDate,
      },
      headers: {
        Authorization: `Basic ${adminToken}`,
      },
    })
      .then((res) => {
        const { error } = res['data'];
        if (error) {
          throw new Error(error);
        } else {
          const { data } = res['data'];
          const dateRange = this.formDateRange(startDate, endDate);
          const {
            impressions,
            clicks,
            cpms,
            ctrs,
            totalClicks,
            totalImpressions,
            totalSpend,
          } = this.formGraphData(data, dateRange);

          const averageCtr =
            Number(
              (
                (clicks.reduce((a, b) => a + b, 0) /
                  impressions.reduce((a, b) => a + b, 0)) *
                100
              ).toFixed(2)
            ) || 0;
          this.setState({
            graphData: {
              impressions,
              clicks,
              cpms,
              ctrs,
            },
            totalClicks,
            totalImpressions,
            totalSpend: Number(totalSpend.toFixed(2)),
            averageCtr,
            dateRange,
            loading: false,
          });
        }
      })
      .catch(() => {
        this.setState({ error: true, loading: false });
      });
  };

  formDateRange = (startDate, endDate) => {
    const dateRange = [...Array(endDate.diff(startDate, 'day') + 1)].map((_, i) =>
      startDate
        .clone()
        .add(i, 'day')
        .format('DD/MM/YYYY')
    );
    return dateRange;
  };

  formGraphData = (data, dateRange) => {
    const dashboardDataRef = [...dateRange].reduce((a, _, i) => {
      a[dateRange[i]] = {
        impressions: 0,
        clicks: 0,
        cpm: 0,
      };
      return a;
    }, {});

    if (data) {
      for (const statData of data) {
        const { date, totalImpression, totalClick, totalDisplayCPM } = statData;
        let key = moment(date).format('DD/MM/YYYY');
        let v = dashboardDataRef[key];
        v.impressions += totalImpression;
        v.clicks += totalClick > totalImpression ? totalImpression : totalClick;
        v.cpm += totalDisplayCPM;
      }
    }

    const dashBoardData = Object.values(dashboardDataRef).reduce(
      (a, e) => {
        if (e) {
          const ti = e['impressions'];
          const tc = e['clicks'];
          const cpm = e['cpm'];
          const ctrs =
            tc === 0 || ti === 0 || tc > ti
              ? 0
              : +Math.round((tc / ti) * 100 * 100) / 100;
          a['impressions'] = a['impressions'].concat(ti);
          a['clicks'] = a['clicks'].concat(tc);
          a['cpms'] = a['cpms'].concat(cpm);
          a['ctrs'] = a['ctrs'].concat(ctrs);
          a['totalImpressions'] += ti;
          a['totalClicks'] += tc;
          a['totalSpend'] += cpm;
        }
        return a;
      },
      {
        impressions: [],
        clicks: [],
        cpms: [],
        ctrs: [],
        totalImpressions: 0,
        totalClicks: 0,
        totalSpend: 0,
      }
    );

    return dashBoardData;
  };

  advertiserOnSelectHandler = (idx) => {
    const { advertisers, setSelectedAdvertiserAction } = this.props;
    const selectedAdvertiser = advertisers[idx];
    setSelectedAdvertiserAction(selectedAdvertiser);
  };

  startDateOnChange = (startDate) => {
    startDate = moment(startDate).startOf('day');
    this.setState({ startDate: startDate }, () => {
      this.retrieveGraphData();
    });
  };

  endDateOnChangeHandler = (endDate) => {
    this.setState({ endDate: moment(endDate) }, () => {
      this.retrieveGraphData();
    });
  };

  summaryBoxOnClick = (type) => {
    const graphHeader =
      type === 'impressions'
        ? 'Impressions'
        : type === 'clicks'
        ? 'Clicks'
        : type === 'cpms'
        ? 'Ad Spend'
        : 'Click Through Rate';
    this.setState({ dataToShow: type, graphHeader });
  };

  render() {
    const {
      advertiserOptions,
      startDate,
      endDate,
      averageCtr,
      totalClicks,
      totalImpressions,
      totalSpend,
      dateRange,
      dataToShow,
      graphData,
      graphHeader,
      loading,
    } = this.state;
    const { selectedAdvertiser } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer>
          <Text
            fontColor="#4a4a4a"
            fontSize="1.5"
            fontWeight="bold"
            data-cy="accSummary"
          >
            Account Summary ({startDate.clone().format('DD/MM/YYYY')} -{' '}
            {endDate.clone().format('DD/MM/YYYY')})
          </Text>
          <HeaderFiltersContainer>
            <Dropdown
              dropdownID="ads-type-dropdown"
              options={advertiserOptions}
              title={selectedAdvertiser['name']}
              onSelect={this.advertiserOnSelectHandler}
              buttonTestId="advListBtn"
              menuTestId="advList"
            />
            <DatePicker
              minDate={startDate
                .clone()
                .startOf('year')
                .subtract(1, 'year').toDate()}
              maxDate={endDate.clone().subtract(1, 'day').toDate()}
              selected={startDate.toDate()}
              onChange={this.startDateOnChange}
              data-cy="startDatePicker"
            />
            <DatePicker
              minDate={startDate.clone().add(1, 'day').toDate()}
              maxDate={moment().toDate()}
              selected={endDate.toDate()}
              onChange={this.endDateOnChangeHandler}
              data-cy="endDatePicker"
            />
          </HeaderFiltersContainer>
        </HeaderContainer>
        {loading ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <FlexBox margin="30px auto" justifyContent="space-around">
              <SummaryBox
                title="impression"
                value={`${totalImpressions}`}
                onClick={() => this.summaryBoxOnClick('impressions')}
                testId="impBtn"
              />
              <SummaryBox
                title="clicks"
                value={`${totalClicks}`}
                onClick={() => this.summaryBoxOnClick('clicks')}
                testId="clkBtn"
              />
              <SummaryBox
                title="click through rate"
                value={`${averageCtr}%`}
                onClick={() => this.summaryBoxOnClick('ctrs')}
                testId="ctrBtn"
              />
              <SummaryBox
                title="ad spend (usd)"
                value={`$${totalSpend}`}
                onClick={() => this.summaryBoxOnClick('cpms')}
                testId="adSpdBtn"
              />
            </FlexBox>
            <FlexBox justifyContent="flex-start" margin="10px auto">
              <Text fontSize="1.25" margin="10%" fontColor="#4a4a4a">
                {graphHeader}
              </Text>
            </FlexBox>
            <Line
              data={{
                labels: dateRange,
                datasets: [
                  {
                    label: graphHeader,
                    data: graphData[dataToShow],
                    borderColor: 'rgba(111, 2, 181, 0.5)',
                    backgroundColor: 'transparent',
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                  xAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: 'UTC Timezone',
                      },
                    },
                  ],
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        min: 0,
                        callback: (value) => {
                          switch (dataToShow) {
                            case 'cpms':
                              return `$${value}`;
                            case 'ctrs':
                              return `${value}%`;
                            default:
                              return value;
                          }
                        },
                      },
                    },
                  ],
                },
                tooltips: {
                  callbacks: {
                    label: ({ value }) => {
                      switch (dataToShow) {
                        case 'cpms':
                          return `${graphHeader}: $${value}`;
                        case 'ctrs':
                          return `${graphHeader}: ${value}%`;
                        default:
                          return `${graphHeader}: ${value}`;
                      }
                    },
                  },
                },
              }}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

AdsDashboardPage.propTypes = {
  selectedAdvertiser: PropTypes.shape(),
  adminToken: PropTypes.string,
  advertisers: PropTypes.array,
  setSelectedAdvertiserAction: PropTypes.func,
};

AdsDashboardPage.defaultProps = {
  selectedAdvertiser: {},
  adminToken: '',
  advertisers: [],
  setSelectedAdvertiserAction: null,
};

const mapStateToProps = (state) => ({
  selectedAdvertiser: state.advertiser.selectedAdvertiser,
  adminToken: state.auth.token,
  advertisers: state.advertiser.advertisers,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedAdvertiserAction: (selectedAdvertiser) =>
    dispatch(setSelectedAdvertiser(selectedAdvertiser)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdsDashboardPage);
