import styled from 'styled-components';
import Text from './Text';

const PageTitle = styled(Text)`
  font-size: 2rem;
  font-weight: 600;
  text-decoration: underline;
`;

export default PageTitle;
