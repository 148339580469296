import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import FlexBox from '../../../../../core/components/FlexBox';

const Pagination = ({
  backOnClick,
  backDisabled,
  firstStep,
  lastStep,
  nextOnClick,
  nextDisabled,
}) => (
  <FlexBox
    margin="20px auto"
    width="60%"
    justifyContent={
      firstStep ? 'flex-end' : lastStep ? 'flex-start' : 'space-between'
    }
  >
    {firstStep ? null : (
      <Button
        variant="danger"
        onClick={backOnClick}
        disabled={backDisabled}
        id="payment-back-button"
        data-cy="backBtn"
      >
        Back
      </Button>
    )}
    {lastStep ? null : (
      <Button
        variant="info"
        onClick={nextOnClick}
        disabled={nextDisabled}
        data-cy="nextBtn"
      >
        Next
      </Button>
    )}
  </FlexBox>
);

Pagination.propTypes = {
  backOnClick: PropTypes.func,
  backDisabled: PropTypes.bool,
  firstStep: PropTypes.bool,
  lastStep: PropTypes.bool,
  nextOnClick: PropTypes.func,
  nextDisabled: PropTypes.bool,
};

Pagination.defaultProps = {
  backOnClick: null,
  backDisabled: true,
  firstStep: false,
  lastStep: false,
  nextOnClick: null,
  nextDisabled: true,
};

export default Pagination;
