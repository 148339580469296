import styled from 'styled-components';

const AdsTableFullscreenCreative = styled.img`
    height: 50;
    transition: all .2s ease-in-out;
    &:hover {
        transform: scale(5);
    }
`;

export default AdsTableFullscreenCreative;
