import styled from 'styled-components';
import FlexBox from '../../../../../core/components/FlexBox';

const AvailableBudgetContainer = styled(FlexBox)`
  justify-content: flex-start;
  margin: 15px auto;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

export default AvailableBudgetContainer;
