import React from 'react';
import PropTypes from 'prop-types';
import VerbiageBox from './VerbiageBox';
import Verbiage from './Verbiage';
import InputField from './InputField';
import FlexBox from '../../../../../core/components/FlexBox';
import ErrorMessage from '../../../../../core/components/Form/ErrorMessage';

const PaymentDetails = ({
  inputAmount,
  onChange,
  serviceFee,
  spending,
  validAmount,
  touched,
  disabled,
}) => (
  <FlexBox margin="25px auto">
    <VerbiageBox>
      <Verbiage data-cy="payAmtTitle">Payment Amount:</Verbiage>
      <FlexBox width="30%" justifyContent="flex-end">
        <Verbiage>US$&nbsp;</Verbiage>
        <InputField
          style={{ width: '60%' }}
          type="number"
          min="0"
          value={inputAmount === 0 ? '' : inputAmount}
          onChange={onChange}
          disabled={disabled}
          step="0.01"
          data-cy="payAmt"
        />
      </FlexBox>
    </VerbiageBox>
    {touched && !validAmount ? (
      <FlexBox>
        <ErrorMessage data-cy="payAmt-error">
          *Please input the amount equal or more than US$ 550
        </ErrorMessage>
      </FlexBox>
    ) : null}
    <VerbiageBox width="40%" justifyContent="space-b">
      <Verbiage data-cy="serviceFeeTitle">Service Fee:</Verbiage>
      <Verbiage data-cy="serviceFee">-US$ {serviceFee}</Verbiage>
    </VerbiageBox>
    <VerbiageBox>
      <Verbiage data-cy="totalAmtTitle">Spending:</Verbiage>
      <Verbiage data-cy="totalAmt">US$ {spending}</Verbiage>
    </VerbiageBox>
  </FlexBox>
);

PaymentDetails.propTypes = {
  inputAmount: PropTypes.number,
  onChange: PropTypes.func,
  serviceFee: PropTypes.number,
  spending: PropTypes.number,
  validAmount: PropTypes.bool,
  touched: PropTypes.bool,
  disabled: PropTypes.bool,
};

PaymentDetails.defaultProps = {
  inputAmount: 0,
  onChange: null,
  serviceFee: 0,
  spending: 0,
  validAmount: false,
  touched: false,
  disabled: false,
};

export default PaymentDetails;
