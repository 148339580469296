import styled from 'styled-components';

const AdsTableBannerCreative = styled.img`
    height: 50;
    transition: all .2s ease-in-out;
    &:hover {
        transform: scale(2);
    }
`;

export default AdsTableBannerCreative;
