import styled from 'styled-components';
import FlexBox from './FlexBox';

const PageContainer = styled(FlexBox)`
  padding: 3% 8% 1% 8%;
  flex-direction: column;
  min-height: calc(100vh - 100px);
  justify-content: flex-start;

  @media (max-width: 1000px) {
    padding: 3% 5% 10% 5%;
  }
`;

export default PageContainer;
