import styled from 'styled-components';
import FlexBox from '../../../../../core/components/FlexBox';

const PayPalLoadingContainer = styled(FlexBox)`
  width: 350px;
  height: 51.6667px;
  background-color: #f7c657;
  border-radius: 5px;
`;

export default PayPalLoadingContainer;
