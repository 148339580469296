import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import _ from 'lodash';
import FormContainer from '../../../../core/components/Form/FormContainer';
import FormField from '../../../../core/components/Form/FormField';
import FormButton from '../../../../core/components/Form/FormButton';
import ErrorMessage from '../../../../core/components/Form/ErrorMessage';

const LoginForm = ({ error, onSubmit, submissionProcessing }) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('*Must be a valid email address')
      .max(100, '*Email must be less than 100 characters')
      .required('*Email is required'),
    password: Yup.string()
      .min(8, '*Password must be more than 8 characters')
      .required('*Password is required'),
  });
  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <FormContainer>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formEmail">
              <FormField
                type="text"
                name="email"
                placeholder="Enter Email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                isInvalid={touched.email && errors.email}
                data-cy="formEmail"
              />
              <Form.Control.Feedback type="invalid" data-cy="formEmail-error">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formPassword">
              <FormField
                type="password"
                name="password"
                placeholder="Enter Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                isInvalid={touched.password && errors.password}
                data-cy="formPassword"
              />
              <Form.Control.Feedback type="invalid" data-cy="formPassword-error">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            <FormButton
              type="submit"
              disabled={
                isSubmitting ||
                !_.every(Object.values(values)) ||
                !_.isEmpty(errors) ||
                submissionProcessing
              }
              data-cy="loginBtn"
            >
              Login
            </FormButton>
          </Form>
          {error && <ErrorMessage data-cy="login-error">{error}</ErrorMessage>}
        </FormContainer>
      )}
    </Formik>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  submissionProcessing: PropTypes.bool,
};

LoginForm.defaultProps = {
  onSubmit: null,
  error: '',
  submissionProcessing: false,
};

const mapStateToProps = (state) => ({
  submissionProcessing: state.auth.submissionProcessing,
});

export default connect(mapStateToProps)(LoginForm);
