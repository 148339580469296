import React from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import FileDownload from 'js-file-download';
import moment from 'moment';
import axios from 'axios';
import PropTypes from 'prop-types';
import CreateButton from './components/CreateButton/CreateButton';
import ExportButton from './components/ExportButton/ExportButton';
import HeaderContainer from './components/Header/HeaderContainer';
import HeaderFiltersContainer from './components/Header/HeaderFiltersContainer';
import ErrorAlert from './components/ErrorAlert/ErrorAlert';
import Footer from './components/Pagination/Pagination';
import AdsTable from './components/Table/AdsTable';
import AvailableBudgetContainer from './components/AvailableBudget/AvailableBudgetContainer';
import FlexBox from '../../../core/components/FlexBox';
import Text from '../../../core/components/Text';
import Spinner from '../../../core/components/Spinner';
import Dropdown from '../../../core/components/Dropdown/Dropdown';
import { setSelectedAdvertiser } from '../../../redux/advertisers/advertiserSlice';
import { changeLayout } from '../../../redux/campaign/campaignSlice';

export class AdsCampaignPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      exportLoading: false,
      selectedAdTypeFilter: 0,
      adTypeFilters: ['All Ads', 'Active Ads', 'Inactive Ads'],
      layoutTypeFilters: ['Show Unique Advertiser', 'Show Paid Advertisers'],
      startDate: moment().subtract(3, 'months'),
      endDate: moment(),
      totalPages: 1,
      page: 1,
      ads: [],
      advertiserOptions: [],
      retrieveAdDetailsError: null,
    };
  }

  componentDidMount = () => {
    this.formAdvertiserDropdownOptions();
    this.retrieveAdsData();
  };

  componentDidUpdate = (prevProps) => {
    const { selectedAdvertiser, layoutIndex } = this.props;
    if (prevProps['selectedAdvertiser']['id'] !== selectedAdvertiser['id']) {
      this.retrieveAdsData();
    }

    if (prevProps['layoutIndex'] !== layoutIndex) {
      this.retrieveAdsData();
    }
  };

  formAdvertiserDropdownOptions = () => {
    const { advertisers } = this.props;
    const advertiserOptions = advertisers.map(({ name }) => name);
    this.setState({ advertiserOptions });
  };

  retrieveAdsData = () => {
    this.setState({ loading: true });
    const { startDate, endDate, selectedAdTypeFilter, page } = this.state;
    const { adminToken, selectedAdvertiser, layoutIndex } = this.props;
    const { id } = selectedAdvertiser;
    const active = selectedAdTypeFilter === 0 ? -1 : selectedAdTypeFilter;
    const fromDate = startDate
      .clone()
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    const toDate = endDate
      .clone()
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    const userId = layoutIndex === 1 ? 'paids' : id;
    const formData = new FormData();
    formData.append('fromDate', fromDate);
    formData.append('toDate', toDate);
    formData.append('status', active);
    formData.append('userId', userId);
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_DSP_API}/admin/search?page=${page}`,
      data: formData,
      headers: {
        Authorization: `Basic ${adminToken}`,
      },
    })
      .then(async (res) => {
        const { error } = res['data'];
        if (error) {
          throw new Error(error);
        } else {
          const { data, page } = res['data'];
          const { total } = page;
          const convertedData = data ? data : [];
          const dataSummary = convertedData.length
            ? await this.getPageDataSummary()
            : [];
          const adsData = convertedData.concat(dataSummary);
          this.setState({ ads: adsData, totalPages: total, loading: false });
        }
      })
      .catch(() => {
        this.setState({ retrieveAdsError: true, loading: false });
      });
  };

  getPageDataSummary = async () => {
    const { startDate, endDate, selectedAdTypeFilter } = this.state;
    const { adminToken, selectedAdvertiser, layoutIndex } = this.props;
    const { id } = selectedAdvertiser;
    const fromDate = startDate
      .clone()
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    const toDate = endDate
      .clone()
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    const active = selectedAdTypeFilter === 0 ? -1 : selectedAdTypeFilter;
    const userId = layoutIndex === 1 ? 'paid' : id;
    const obj = {
      content: 'Overall Total Number',
      totalImpression: '0',
      totalClick: '0',
      totalDisplayCPM: '0',
      totalInstall: '0',
      dailySpend: '0',
      cpm: '0',
    };
    try {
      const summary = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_DSP_API}/admin/stat/total`,
        params: {
          userId,
          status: active,
          fromDate: fromDate,
          toDate: toDate,
        },
        headers: {
          Authorization: `Basic ${adminToken}`,
        },
      });
      const { data, error } = summary['data'];
      if (error) {
        throw new Error(error);
      }
      const {
        totalImpression,
        totalClick,
        totalDisplayCPM,
        totalDailySpend,
        totalCPM,
        totalInstall,
      } = data;
      return {
        ...obj,
        totalImpression: totalImpression.toFixed(0),
        totalClick: totalClick.toFixed(0),
        totalDisplayCPM: totalDisplayCPM.toFixed(2),
        totalInstall: totalInstall.toFixed(0),
        dailySpend: totalDailySpend.toFixed(2),
        cpm: totalCPM.toFixed(2),
      };
    } catch {
      return obj;
    }
  };

  retrieveAdDetails = async (userID, adID) => {
    try {
      const { adminToken } = this.props;
      const ad = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_DSP_API}/admin/ad/${userID}/${adID}`,
        headers: {
          Authorization: `Basic ${adminToken}`,
        },
      });
      return ad;
    } catch (e) {
      throw new Error(e);
    }
  };

  adStatusOnChangeHandler = (userID, aid, status) => {
    this.dismissErrorAlert();
    const { adminToken } = this.props;
    const formData = new FormData();
    status = status === 1 ? 2 : 1;
    formData.append('status', status);
    axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_DSP_API}/admin/ad/${userID}/${aid}/status`,
      data: formData,
      headers: {
        Authorization: `Basic ${adminToken}`,
      },
    })
      .then((res) => {
        const { error } = res['data'];
        if (error) {
          throw new Error(error);
        } else {
          this.retrieveAdsData();
        }
      })
      .catch((error) => {
        toast.error('Updated Failed');
        console.error(error);

        this.retrieveAdsData();
      });
  };

  exportAdOnClickHandler = (format) => {
    this.setState({ exportLoading: true });
    this.dismissErrorAlert();
    const { startDate, endDate, selectedAdTypeFilter } = this.state;
    const { adminToken, selectedAdvertiser, layoutIndex } = this.props;
    const { id } = selectedAdvertiser;
    const active = selectedAdTypeFilter === 0 ? -1 : selectedAdTypeFilter;
    const fromDate = startDate
      .clone()
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    const toDate = endDate
      .clone()
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    const userId = layoutIndex === 1 ? 'paids' : id;
    axios({
      method: 'GET',
      responseType: 'blob',
      url: `${process.env.REACT_APP_DSP_API}/admin/ad/export`,
      headers: {
        Authorization: `Basic ${adminToken}`,
      },
      params: {
        userId,
        status: active,
        fromDate: fromDate,
        toDate: toDate,
        format,
      },
    })
      .then((res) => {
        let fileName = '';
        let contentDisposition = res.headers['content-disposition'];
        if (contentDisposition) {
          let index = contentDisposition.indexOf('filename=');
          fileName = contentDisposition
            .substring(index + 'filename='.length)
            .replace(/"/gi, '');
        } else {
          fileName = 'ads-export.xlsx';
        }
        FileDownload(res.data, fileName);
      })
      .catch((error) => {
        toast.error('Export Failed');
        console.error(error);
      })
      .finally(() => {
        this.setState({ exportLoading: false });
      });
  };

  editButtonOnClickHandler = (userID, adID) => {
    const { history } = this.props;
    this.dismissErrorAlert();
    this.retrieveAdDetails(userID, adID)
      .then((res) => {
        const { error } = res['data'];
        if (error) {
          throw new Error(error);
        } else {
          const { data } = res['data'];
          const filterData = this.filterComrefs(data);
          history.push({
            pathname: 'ad_edit',
            state: { ad: filterData },
          });
        }
      })
      .catch(() => {
        this.setState({
          retrieveAdDetailsError: 'Fail to edit ad details. Please Refresh!',
        });
      });
  };

  cloneButtonOnClickHandler = (userID, adID) => {
    const { history } = this.props;
    this.dismissErrorAlert();
    this.retrieveAdDetails(userID, adID)
      .then((res) => {
        const { error } = res['data'];
        if (error) {
          throw new Error(error);
        } else {
          const { data } = res['data'];
          const filterData = this.filterComrefs(data);
          history.push({
            pathname: 'ad_create',
            state: { ad: filterData },
          });
        }
      })
      .catch(() => {
        this.setState({
          retrieveAdDetailsError: 'Fail to clone ad details. Please Refresh!',
        });
      });
  };

  filterComrefs = (data) => {
    const { comrefs } = data;
    return { ...data, comrefs: comrefs ? comrefs.filter((c) => c.id) : null };
  };

  createAdOnClickHandler = () => {
    const { layoutIndex, history } = this.props;
    history.push({
      pathname: 'ad_create',
      state: { onAllUserMode: layoutIndex === 1 },
    });
  };

  campaignTypeOnSelectHandler = (idx) => {
    const convertedIdx = Number(idx);
    const { layoutIndex, changeLayoutAction } = this.props;
    if (convertedIdx !== layoutIndex) {
      changeLayoutAction(convertedIdx);
    }
  };

  typeFiltersOnSelectHandler = (idx) => {
    this.setState({ selectedAdTypeFilter: parseInt(idx) }, () => {
      this.dismissErrorAlert();
      this.retrieveAdsData();
    });
  };

  advertiserOnSelectHandler = (idx) => {
    this.setState({ page: 1, totalPages: 1 }, () => {
      this.advertiserOptionOnChange(idx);
    });
  };

  startDateOnChangeHandler = (startDate) => {
    this.setState({ startDate: moment(startDate), page: 1, totalPages: 1 }, () => {
      this.dismissErrorAlert();
      this.retrieveAdsData();
    });
  };

  endDateOnChangeHandler = (endDate) => {
    this.setState({ endDate: moment(endDate), page: 1, totalPages: 1 }, () => {
      this.dismissErrorAlert();
      this.retrieveAdsData();
    });
  };

  paginationButtonOnClickHandler = (page) => {
    this.setState({ page }, () => {
      this.dismissErrorAlert();
      this.retrieveAdsData();
    });
  };

  advertiserOptionOnChange = (idx) => {
    this.dismissErrorAlert();
    const { advertisers, setSelectedAdvertiserAction } = this.props;
    const selectedAdvertiser = advertisers[idx];
    setSelectedAdvertiserAction(selectedAdvertiser);
  };

  dismissErrorAlert = () => this.setState({ retrieveAdDetailsError: null });

  perDayExportOnClick = () => {
    this.exportAdOnClickHandler('daily');
  };

  overallExportOnClick = () => {
    this.exportAdOnClickHandler('overall');
  };

  render() {
    const {
      advertiserOptions,
      ads,
      startDate,
      endDate,
      adTypeFilters,
      selectedAdTypeFilter,
      layoutTypeFilters,
      totalPages,
      page,
      loading,
      exportLoading,
      retrieveAdDetailsError,
    } = this.state;
    const { selectedAdvertiser, layoutIndex, advertisers } = this.props;
    const { availableBudget } = selectedAdvertiser;
    return (
      <React.Fragment>
        <FlexBox justifyContent="flex-end" margin="0 0 2% 0">
          <Dropdown
            dropdownID="layout-type-dropdown"
            options={layoutTypeFilters}
            title={layoutTypeFilters[layoutIndex]}
            onSelect={this.campaignTypeOnSelectHandler}
            buttonTestId="showAdvListBtn"
            menuTestId="showAdvList"
          />
        </FlexBox>
        <HeaderContainer>
          <CreateButton onClick={this.createAdOnClickHandler} />
          <HeaderFiltersContainer>
            <Dropdown
              dropdownID="ads-type-dropdown"
              options={advertiserOptions}
              title={selectedAdvertiser['name']}
              onSelect={this.advertiserOnSelectHandler}
              disabled={layoutIndex === 1}
              buttonTestId="advListBtn"
              menuTestId="advList"
            />
            <Dropdown
              dropdownID="ads-type-dropdown"
              options={adTypeFilters}
              title={adTypeFilters[selectedAdTypeFilter]}
              onSelect={this.typeFiltersOnSelectHandler}
              buttonTestId="adTypeListBtn"
              menuTestId="adTypeList"
            />
            <DatePicker
              selected={startDate.toDate()}
              minDate={endDate.clone().subtract(2, 'years').toDate()}
              maxDate={endDate.clone().toDate()}
              onChange={this.startDateOnChangeHandler}
              data-cy="startDatePicker"
            />
            <DatePicker
              selected={endDate.toDate()}
              minDate={startDate.clone().toDate()}
              maxDate={moment().toDate()}
              onChange={this.endDateOnChangeHandler}
              data-cy="endDatePicker"
            />
            {ads.length > 0 && !loading ? (
              <ExportButton
                perDayExportOnClick={this.perDayExportOnClick}
                overallExportOnClick={this.overallExportOnClick}
                loading={exportLoading}
                disablePerDay={layoutIndex === 1}
              />
            ) : null}
          </HeaderFiltersContainer>
        </HeaderContainer>
        <AvailableBudgetContainer>
          <Text fontSize="2rem" fontWeight="bold" data-cy="advABud">
            Available Budget: US $
            {!layoutIndex
              ? Number(availableBudget).toFixed(2)
              : advertisers
                  .reduce(
                    (a, e) =>
                      a +
                      (e.name !== '222@kaiostech.com'
                        ? Number(e.availableBudget)
                        : 0),
                    0
                  )
                  .toFixed(2)}
          </Text>
        </AvailableBudgetContainer>
        {!ads.length || loading ? (
          <FlexBox margin="20px auto">
            {loading ? (
              <Spinner />
            ) : (
              <Text>
                No{' '}
                {selectedAdTypeFilter === 0
                  ? null
                  : adTypeFilters[selectedAdTypeFilter].split(' ')[0]}{' '}
                Advertisement Found
              </Text>
            )}
          </FlexBox>
        ) : (
          <React.Fragment>
            {retrieveAdDetailsError && (
              <ErrorAlert variant="danger">{retrieveAdDetailsError}</ErrorAlert>
            )}
            <FlexBox margin="2rem 0">
              <AdsTable
                ads={ads}
                onAllUserMode={layoutIndex === 1}
                adStatusOnChange={this.adStatusOnChangeHandler}
                onEditButtonClick={this.editButtonOnClickHandler}
                onCloneButtonClick={this.cloneButtonOnClickHandler}
              />
            </FlexBox>
            {totalPages > 1 ? (
              <Footer
                totalPages={totalPages}
                currentPage={page}
                onClick={this.paginationButtonOnClickHandler}
              />
            ) : null}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

AdsCampaignPage.propTypes = {
  selectedAdvertiser: PropTypes.shape(),
  adminToken: PropTypes.string,
  advertisers: PropTypes.array,
  history: PropTypes.shape({
    goBack: PropTypes.func,
    replace: PropTypes.func,
    push: PropTypes.func,
  }),
  setSelectedAdvertiserAction: PropTypes.func,
  layoutIndex: PropTypes.number,
  changeLayoutAction: PropTypes.func,
};

AdsCampaignPage.defaultProps = {
  selectedAdvertiser: null,
  adminToken: '',
  advertisers: [],
  history: null,
  setSelectedAdvertiserAction: null,
  layoutIndex: 0,
  changeLayoutAction: null,
};

const mapStateToProps = (state) => ({
  selectedAdvertiser: state.advertiser.selectedAdvertiser,
  adminToken: state.auth.token,
  advertisers: state.advertiser.advertisers,
  layoutIndex: state.campaign.layoutIndex,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedAdvertiserAction: (selectedAdvertiser) =>
    dispatch(setSelectedAdvertiser(selectedAdvertiser)),
  changeLayoutAction: (idx) => dispatch(changeLayout(idx)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdsCampaignPage));
