import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Navbar, Nav, Dropdown, NavItem } from 'react-bootstrap';
import { logout } from '../../../redux/auth/authSlice';

export const Header = ({
  availableBudget,
  email,
  loggedIn,
  isAdmin,
  logoutAction,
}) => (
  <Navbar bg="light" expand="lg">
    <Navbar.Brand as="h1">
      <img
        src="https://kaiads.com/static/KaiAds.png"
        height="28"
        alt="KaiAds"
        data-cy="kaiAdsLogo"
      />
    </Navbar.Brand>
    {loggedIn ? (
      <React.Fragment>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="mr-auto">
            {isAdmin ? (
              <React.Fragment>
                <Nav.Link as={Link} to="/admin/ads_campaigns" data-cy="campaigns">
                  Campaigns
                </Nav.Link>
                <Nav.Link as={Link} to="/admin/sponsored_search" data-cy="sponsored_search">
                  Sponsored Search/YMAL
                </Nav.Link>
                <Nav.Link as={Link} to="/admin/ads_dashboard" data-cy="dashboards">
                  Dashboard
                </Nav.Link>
                <Nav.Link as={Link} to="/admin/top_up" data-cy="topup">
                  Top Up
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/admin/advertisers_account_management"
                  data-cy="advAcc"
                >
                  Advertisers Account Management
                </Nav.Link>
              </React.Fragment>
            ) : null}
          </Nav>
          <Nav>
            <Dropdown as={NavItem}>
              <Dropdown.Toggle data-cy="navDropdown" variant="light">
                {isAdmin ? 'Admin' : email}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => logoutAction()} data-cy="logout">
                  Logout
                </Dropdown.Item>
                <Dropdown.Item data-cy="avaBudget" disabled>
                  {' '}
                  Available Budget: US ${availableBudget || '0.00'}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </React.Fragment>
    ) : null}
  </Navbar>
);

Header.propTypes = {
  email: PropTypes.string,
  availableBudget: PropTypes.string,
  isAdmin: PropTypes.bool,
  logoutAction: PropTypes.func,
  loggedIn: PropTypes.string,
};

Header.defaultProps = {
  email: '',
  availableBudget: '',
  isAdmin: false,
  logoutAction: null,
  loggedIn: null,
};

const mapDispatchToProps = (dispatch) => ({
  logoutAction: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(Header);
