import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import HomePage from './pages/home/HomePage';
import AdvertiserPage from './pages/advertiser/AdvertiserPage';
import AdminPage from './pages/admin/AdminPage';
import Header from './core/components/Header/Header';
import Footer from './core/components/Footer/Footer';
import { checkLoggedIn } from './redux/auth/authSlice';
import { ToastContainer } from 'react-toastify';

export const App = ({
  email,
  availableBudget,
  token,
  isAdmin,
  loading,
  checkLoggedInAction,
}) => {
  useEffect(() => {
    checkLoggedInAction();
    return () => false;
  }, [checkLoggedInAction]);
  return (
    <Router>
      <Header
        isAdmin={isAdmin}
        loggedIn={token}
        availableBudget={availableBudget}
        email={email}
      />
      {loading ? (
        <React.Fragment />
      ) : !token ? (
        <Switch>
          <Route exact path="" component={HomePage} />
        </Switch>
      ) : isAdmin ? (
        <Switch>
          <Route path="/admin" component={AdminPage} />
          <Redirect from="*" to={'/admin/ads_manager'} />
        </Switch>
      ) : (
        <Switch>
          <Route path="/advertiser" component={AdvertiserPage} />
          <Redirect from="*" to={'/advertiser/terms_of_usage'} />
        </Switch>
      )}
      <ToastContainer />
      <Footer />
    </Router>
  );
};

App.propTypes = {
  email: PropTypes.string,
  availableBudget: PropTypes.string,
  token: PropTypes.string,
  isAdmin: PropTypes.bool,
  checkLoggedInAction: PropTypes.func,
  loading: PropTypes.bool,
};

App.defaultProps = {
  email: '',
  availableBudget: '',
  token: '',
  isAdmin: false,
  checkLoggedInAction: null,
  loading: true,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  isAdmin: state.auth.isAdmin,
  email: state.auth.email,
  availableBudget: state.auth.availableBudget,
  loading: state.auth.loading,
});

const mapDispatchToProps = (dispatch) => ({
  checkLoggedInAction: () => dispatch(checkLoggedIn()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
