import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { updateObject } from '../../core/utility/updateObject';
import {
  setToken,
  removeToken,
  retrieveToken,
} from '../../core/utility/localStorage';

export const initialState = {
  availableBudget: null,
  infoCompleted: false,
  isPaymentMade: false,
  email: null,
  error: null,
  isAdmin: false,
  id: null,
  isLoggedOut: false,
  loading: true,
  submissionProcessing: false,
  paymentMethod: null,
  registrationStatusRetrieved: false,
  tncCompleted: false,
  token: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: {
      reducer: (state) => {
        return updateObject(state, { error: null });
      },
    },
    formSubmissionProcessing: {
      reducer: (state) => {
        return updateObject(state, { submissionProcessing: true });
      },
    },
    loginSuccess: {
      reducer: (state, action) => {
        const { token, email, isAdmin, availableBudget, id } = action['payload'];
        return updateObject(state, {
          token,
          isAdmin,
          email,
          availableBudget,
          id,
          isLoggedOut: false,
          submissionProcessing: false,
        });
      },
      prepare: (email, token, isAdmin, availableBudget, id) => ({
        payload: {
          email,
          token,
          isAdmin,
          availableBudget,
          id,
        },
      }),
    },
    loginError: {
      reducer: (state, action) => {
        const { error } = action['payload'];
        return updateObject(state, { error, submissionProcessing: false });
      },
      prepare: (error) => ({ payload: { error } }),
    },
    logout: (state) => {
      removeToken();
      return updateObject(state, {
        token: null,
        isAdmin: false,
        email: null,
        availableBudget: null,
        error: null,
        registrationStatusRetrieved: false,
        isLoggedOut: true,
        isPaymentMade: false,
        paymentMethod: null,
      });
    },
    updateRegistrationStatus: {
      reducer: (state, action) => {
        const { tncCompleted, infoCompleted } = action['payload'];
        return updateObject(state, {
          tncCompleted,
          infoCompleted,
          registrationStatusRetrieved: true,
        });
      },
      prepare: (tncCompleted = false, infoCompleted = false) => ({
        payload: { tncCompleted, infoCompleted },
      }),
    },
    resetRegistrationStatus: (state) => {
      return updateObject(state, {
        registrationStatusRetrieved: false,
        tncCompleted: false,
        infoCompleted: false,
      });
    },
    paymentCompleted: {
      reducer: (state, action) => {
        const { paymentMethod } = action['payload'];
        return updateObject(state, { isPaymentMade: true, paymentMethod });
      },
      prepare: (paymentMethod) => ({ payload: { paymentMethod } }),
    },
    checkLoggedInComplete: (state) => {
      return updateObject(state, { loading: false });
    },
  },
});

const getAuthResultSuccess = (data) => {
  const { id, type, email, token, availableBudget } = data;
  const authToken = btoa(`${id}:${token}`);
  const isAdmin = type > 0;
  return { email, authToken, isAdmin, availableBudget, id };
};

export const {
  checkLoggedInComplete,
  loginSuccess,
  loginError,
  paymentCompleted,
  logout,
  reset,
  resetRegistrationStatus,
  updateRegistrationStatus,
  formSubmissionProcessing,
} = authSlice.actions;

export default authSlice.reducer;

export const getRegistrationStatus = (token) => async (dispatch) => {
  try {
    dispatch(resetRegistrationStatus());
    const statusResult = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_DSP_API}/dev/onboard/status`,
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    const { data } = statusResult['data'];

    if (data) {
      const { tncAccepted, compInfoFilled } = data;
      dispatch(updateRegistrationStatus(tncAccepted, compInfoFilled));
    } else {
      const { error } = statusResult['data'];
      throw new Error(error);
    }
  } catch (err) {
    const errorMessage = String(err).split(': ')[1];
    console.error(errorMessage);
    dispatch(updateRegistrationStatus());
  }
};

export const login = (email, password) => async (dispatch) => {
  dispatch(reset());
  dispatch(formSubmissionProcessing());
  try {
    const bodyFormData = new FormData();
    bodyFormData.append('email', email);
    bodyFormData.append('password', password);
    const authResult = await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_DSP_API}/dev/user/login`,
      data: bodyFormData,
    });
    const { data } = authResult['data'];
    if (data) {
      const {
        email,
        authToken,
        isAdmin,
        availableBudget,
        id,
      } = getAuthResultSuccess(data);
      setToken(authToken);
      dispatch(loginSuccess(email, authToken, isAdmin, availableBudget, id));
    } else {
      const { error } = authResult['data'];
      throw new Error(error);
    }
  } catch (err) {
    const errorMessage = String(err).split(': ')[1];
    dispatch(loginError(errorMessage));
  }
};

export const signup = (email, password) => async (dispatch) => {
  dispatch(reset());
  dispatch(formSubmissionProcessing());
  try {
    const bodyFormData = new FormData();
    bodyFormData.append('email', email);
    bodyFormData.append('name', email);
    bodyFormData.append('password', password);
    const authResult = await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_DSP_API}/dev/user`,
      data: bodyFormData,
    });
    const { data } = authResult['data'];
    if (data) {
      const {
        email,
        authToken,
        isAdmin,
        availableBudget,
        id,
      } = getAuthResultSuccess(data);
      setToken(authToken);
      dispatch(loginSuccess(email, authToken, isAdmin, availableBudget, id));
    } else {
      const { error } = authResult['data'];
      throw new Error(error);
    }
  } catch (err) {
    const errorMessage = String(err).split(': ')[1];
    if (errorMessage.indexOf('pq') >= 0) {
      dispatch(
        loginError('User email is already registered. Please proceed to log in.')
      );
    } else {
      dispatch(loginError(errorMessage));
    }
  }
};

export const checkLoggedIn = () => async (dispatch) => {
  try {
    const token = retrieveToken();
    if (!token) {
      dispatch(checkLoggedInComplete());
      return false;
    }
    const authResult = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_DSP_API}/dev/user`,
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    const { data } = authResult['data'];
    if (data) {
      const {
        email,
        authToken,
        isAdmin,
        availableBudget,
        id,
      } = getAuthResultSuccess(data);
      dispatch(loginSuccess(email, authToken, isAdmin, availableBudget, id));
      dispatch(checkLoggedInComplete());
    } else {
      dispatch(checkLoggedInComplete());
      const { error } = authResult['data'];
      throw new Error(error);
    }
  } catch (err) {
    return false;
  }
};
