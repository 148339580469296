import React from 'react';
import { Link } from 'react-router-dom';
import Text from '../../../../core/components/Text';
import FlexBox from '../../../../core/components/FlexBox';

const RegisterTag = () => (
  <FlexBox margin="10px auto" data-cy="belowFormText">
    <Text fontSize="1">
      Do not have account?&nbsp;
      <Link to="/register" data-cy="signUpPageLink">
        Please click here to sign up
      </Link>
    </Text>
  </FlexBox>
);

export default RegisterTag;
