import { createSlice } from '@reduxjs/toolkit';
import { updateObject } from '../../core/utility/updateObject';

export const initialState = {
  layoutIndex: 0,
};

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    changeLayout: (state, action) => {
      return updateObject(state, { layoutIndex: action.payload });
    },
  },
});

export const { changeLayout } = campaignSlice.actions;

export default campaignSlice.reducer;
