import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const FormButton = styled(Button)`
  width: 100%;
  background-color: ${({ bcolor }) => bcolor || '#209cee'};
  border-color: transparent;
  color: #fff;
  font-size: 1.5rem;
`;

export default FormButton;
