import React from 'react';
import PropTypes from 'prop-types';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';
import FlexBox from './FlexBox';

const Spinner = ({ variant, size }) => (
  <FlexBox flexDirection="column">
    <BootstrapSpinner animation="border" variant={variant} size={size} />
  </FlexBox>
);

Spinner.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
};

Spinner.defaultProps = {
  variant: 'secondary',
  size: 'lg',
};

export default Spinner;
