import React from 'react';
import PropTypes from 'prop-types';
import ContactInputContainer from './ContactInputContainer';
import ContactTextField from './ContactTextField';
import Text from '../Text';
import FlexBox from '../FlexBox';
import Star from '../Star';

const ContactNumberInput = ({
  countryCodeOnChange,
  areaCodeOnChange,
  numberOnChange,
  countryCode,
  areaCode,
  number,
  disabled,
  countryCodeTestId,
  areaCodeTestId,
  telTestId,
}) => (
  <ContactInputContainer>
    <FlexBox width="20%" justifyContent="flex-start">
      <Text fontSize="1.25">
        <Star />
        Contact Number
      </Text>
    </FlexBox>
    <ContactTextField
      disabled={disabled}
      width="15%"
      type="text"
      placeholder="Country Code"
      onChange={countryCodeOnChange}
      value={countryCode}
      data-cy={countryCodeTestId}
    />
    <ContactTextField
      disabled={disabled}
      width="15%"
      type="text"
      placeholder="Area Code"
      onChange={areaCodeOnChange}
      value={areaCode}
      data-cy={areaCodeTestId}
    />
    <ContactTextField
      disabled={disabled}
      width="25%"
      type="text"
      placeholder="Telephone Number"
      onChange={numberOnChange}
      value={number}
      data-cy={telTestId}
    />
  </ContactInputContainer>
);

ContactNumberInput.propTypes = {
  countryCode: PropTypes.string,
  areaCode: PropTypes.string,
  number: PropTypes.string,
  countryCodeOnChange: PropTypes.func,
  areaCodeOnChange: PropTypes.func,
  numberOnChange: PropTypes.func,
  disabled: PropTypes.bool,
  countryCodeTestId: PropTypes.string,
  areaCodeTestId: PropTypes.string,
  telTestId: PropTypes.string,
};

ContactNumberInput.defaultProps = {
  countryCode: '',
  areaCode: '',
  number: '',
  countryCodeOnChange: null,
  areaCodeOnChange: null,
  numberOnChange: null,
  disabled: false,
  countryCodeTestId: '',
  areaCodeTestId: '',
  telTestId: '',
};

export default ContactNumberInput;
