export const ADVERTISER_AUTH_TOKEN = 'ADVERTISER_AUTH_TOKEN';

export const retrieveToken = () => {
  return localStorage.getItem(ADVERTISER_AUTH_TOKEN);
};

export const removeToken = () => {
  localStorage.removeItem(ADVERTISER_AUTH_TOKEN);
};

export const setToken = (token) => {
  localStorage.setItem(ADVERTISER_AUTH_TOKEN, token);
};
