import styled from 'styled-components';
import FlexBox from '../../../../../core/components/FlexBox';

const HeaderContainer = styled(FlexBox)`
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-direction: column;
    height: 10%;
  }
`;

export default HeaderContainer;
