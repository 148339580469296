import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const AdFormLabelWithSwitch = styled(Form.Label)`
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 100%
`;

export default AdFormLabelWithSwitch;
