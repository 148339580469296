import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Verbiage from './components/Verbiage';
import LoginForm from './components/LoginForm';
import RegisterMessage from './components/RegisterTag';
import FlexBox from '../../../core/components/FlexBox';
import { login, reset } from '../../../redux/auth/authSlice';

export const LoginPage = ({ error, loginAction, resetAction }) => {
  useEffect(() => {
    return () => {
      resetAction();
    };
  }, [resetAction]);

  const formOnSubmitHandler = (value) => {
    const { email, password } = value;
    loginAction(email, password);
  };

  return (
    <FlexBox padding="5% 0" flexDirection="column">
      <Verbiage
        title="Welcome!"
        subTitle="You are now using Kai Advertisement service"
        testId="welcomeMsg"
      />
      <Verbiage
        title="Advertiser Dashboard"
        subTitle="Please login to proceed"
        color="#7a7a7a"
        testId="aboveFormText"
      />
      <LoginForm onSubmit={formOnSubmitHandler} error={error} />
      <RegisterMessage />
    </FlexBox>
  );
};

LoginPage.propTypes = {
  error: PropTypes.string,
  loginAction: PropTypes.func,
  resetAction: PropTypes.func,
};

LoginPage.defaultProps = {
  error: '',
  loginAction: null,
  resetAction: null,
};

const mapStateToProps = (state) => ({
  error: state.auth.error,
});

const mapDispatchToProps = (dispatch) => ({
  loginAction: (email, password) => dispatch(login(email, password)),
  resetAction: () => dispatch(reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
