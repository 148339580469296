import React from 'react';
import PropTypes from 'prop-types';
import SummaryBoxContainer from './SummaryBoxContainer';
import SummaryTitle from './SummaryTitle';
import SummaryValue from './SummaryValue';

const SummaryBox = ({ title, value, onClick, testId }) => (
  <SummaryBoxContainer flexDirection="column" onClick={onClick} data-cy={testId}>
    <SummaryTitle fontColor="#4a4a4a" letterSpacing="1px">
      {title.toUpperCase()}
    </SummaryTitle>
    <SummaryValue>{value}</SummaryValue>
  </SummaryBoxContainer>
);

SummaryBox.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  testId: PropTypes.string,
};

SummaryBox.defaultProps = {
  value: '',
  title: '',
  onClick: null,
  testId: '',
};

export default SummaryBox;
