import styled from 'styled-components';
import FlexBox from '../../../../../core/components/FlexBox';

const HeaderFiltersContainer = styled(FlexBox)`
  justify-content: flex-end;
  width: 50%;

  @media (max-width: 1200px) {
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }
`;

export default HeaderFiltersContainer;
