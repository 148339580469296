import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import Switch from 'react-switch';
import { FaEdit, FaRegCopy } from 'react-icons/fa';
import AdsTableButton from './AdsTableButton';
import AdsTableFullscreenCreative from './AdsTableFullscreenCreative';
import AdsTableBannerCreative from './AdsTableBannerCreative';

const AdsTable = ({
  ads,
  adStatusOnChange,
  onCloneButtonClick,
  onEditButtonClick,
  onAllUserMode,
}) => (
  <Table>
    <thead>
      <tr>
        <th>Status</th>
        {onAllUserMode ? <th>Advertiser</th> : null}
        <th>Ad ID</th>
        <th>uid</th>
        <th>Ad name</th>
        <th>Creative</th>
        <th>OS Ver. Group</th>
        <th>Impressions</th>
        <th>Clicks</th>
        <th>CTR</th>
        <th>Spent</th>
        <th>Daily Budget</th>
        <th>CPM</th>
        <th>Installs</th>
        <th>CPI</th>
      </tr>
    </thead>
    <tbody>
      {ads.map((ad, i, arr) => {
        const {
          id,
          userID,
          name,
          content,
          targetOSVersionGroup,
          width,
          height,
          status,
          totalClick,
          totalDisplayCPM,
          totalImpression,
          dailySpend,
          cpm,
          userEmail,
          totalInstall,
        } = ad;
        const [nC, nI] = [Number(totalClick), Number(totalImpression)];
        const [nSpend, nInstalls] = [Number(totalDisplayCPM), Number(totalInstall)];
        const displayClicks = nC > nI ? nI : nC;
        const displayImpressions = nI;
        const ctr = +((nC / nI) * 100);
        const cpi = nInstalls === 0 ? 0 : +(nSpend / nInstalls);
        return (
          <tr key={id || 100}>
            <td>
              {status ? (
                <Switch
                  checked={status === 1}
                  onChange={() => adStatusOnChange(userID, id, status)}
                  onColor="#00d1b2"
                  onHandleColor="#00d1b2"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  className="react-switch"
                  id="material-switch"
                  data-cy="adSwitch"
                />
              ) : null}
            </td>
            {onAllUserMode ? <td>{userEmail}</td> : null}
            <td data-cy={i === arr.length - 1 ? 'adid' : null}>{id}</td>
            <td>{userID}</td>
            <td>
              {name ? (
                <React.Fragment>
                  {name}&nbsp;&nbsp;
                  <AdsTableButton
                    onClick={() => onEditButtonClick(userID, id)}
                    data-cy="adEditBtn"
                    margin="0 0 0 2%"
                  >
                    <FaEdit />
                  </AdsTableButton>
                  <AdsTableButton
                    onClick={() => onCloneButtonClick(userID, id)}
                    data-cy="adCloneBtn"
                  >
                    <FaRegCopy />
                  </AdsTableButton>
                </React.Fragment>
              ) : null}
            </td>
            <td>
              {content !== 'Overall Total Number' ? (
                <React.Fragment>
                  {(height >= width) ? (
                    <AdsTableFullscreenCreative
                      src={content}
                      style={{height:50}}
                      alt={name}
                      id={`img-${id}`}
                    />
                  ) : (
                    <AdsTableBannerCreative
                      src={content}
                      style={{height:50}}
                      alt={name}
                      id={`img-${id}`}
                    />
                  )}
                  <p>
                    {width} x {height}
                  </p>
                </React.Fragment>
              ) : (
                <h5>{content}</h5>
              )}
            </td>
            <td data-cy={i === arr.length - 1 ? 'oTargetOSVersionGroup' : null}>
              {targetOSVersionGroup === '' ? 'N/A (Web)' : targetOSVersionGroup}
            </td>
            <td data-cy={i === arr.length - 1 ? 'oImp' : null}>
              {displayImpressions}
            </td>
            <td data-cy={i === arr.length - 1 ? 'oClk' : null}>{displayClicks}</td>
            <td data-cy={i === arr.length - 1 ? 'oCTR' : null}>
              {ctr > 100 || ctr <= 0 || !isFinite(ctr) ? 0 : ctr.toFixed(2)}%
            </td>
            <td data-cy={i === arr.length - 1 ? 'oSpt' : null}>
              ${totalDisplayCPM}
            </td>
            <td data-cy={i === arr.length - 1 ? 'oDB' : null}>${dailySpend}</td>
            <td data-cy={i === arr.length - 1 ? 'oCPM' : null}>
              {content !== 'Overall Total Number' ? (
                <React.Fragment>${cpm}</React.Fragment>
              ) : (
                <React.Fragment>
                  $
                  {totalDisplayCPM <= 0 || totalImpression <= 0
                    ? 0
                    : ((totalDisplayCPM / totalImpression) * 1000).toFixed(2)}
                </React.Fragment>
              )}
            </td>
            <td data-cy={i === arr.length - 1 ? 'oInstalls' : null}>
              {totalInstall}
            </td>
            <td data-cy={i === arr.length - 1 ? 'oCPI' : null}>
              {content !== 'Overall Total Number' ? (
                <React.Fragment>${cpi.toFixed(4)}</React.Fragment>
              ) : (
                <React.Fragment>-</React.Fragment>
              )}
            </td>
          </tr>
        );
      })}
    </tbody>
  </Table>
);


AdsTable.propTypes = {
  ads: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.number,
      id: PropTypes.string,
      userID: PropTypes.string,
      name: PropTypes.string,
      content: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      totalClick: PropTypes.string,
      totalDisplayCPM: PropTypes.string,
      totalImpression: PropTypes.string,
      dailySpend: PropTypes.string,
      cpm: PropTypes.string,
      totalInstall: PropTypes.string,
    })
  ),
  adStatusOnChange: PropTypes.func,
  onCloneButtonClick: PropTypes.func,
  onEditButtonClick: PropTypes.func,
  onAllUserMode: PropTypes.bool,
};

AdsTable.defaultProps = {
  ads: [],
  adStatusOnChange: null,
  onCloneButtonClick: () => {},
  onEditButtonClick: () => {},
  onAllUserMode: false,
};

export default AdsTable;
