import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LoginPage from './login/LoginPage';
import RegisterPage from './register/RegisterPage';
import PageContainer from '../../core/components/PageContainer';

export const HomePage = ({ isLoggedOut }) => (
  <PageContainer>
    <Route exact path="/login" component={LoginPage} />
    <Route exact path="/register" component={RegisterPage} />
    <Redirect from="*" to={isLoggedOut ? '/login' : '/register'} />
  </PageContainer>
);

HomePage.propTypes = {
  isLoggedOut: PropTypes.bool,
};

HomePage.defaultProps = {
  isLoggedOut: false,
};

const mapStateToProps = (state) => ({
  isLoggedOut: state.auth.isLoggedOut,
});

export default connect(mapStateToProps)(HomePage);
